import { useAuth } from 'components/auth';
import { useGetUserLastApplication } from 'queries/generated/GetUserLastApplication';
import { UserTypeName } from 'types';

export const useGetUserLastApplicationQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetUserLastApplication({
    variables: { id: user.id },
  });

  const lastApplication =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.partnerLastApplication
      : data?.user.companyLastApplication;

  return { lastApplication, loading, error };
};
