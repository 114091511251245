import { useAuth } from 'components/auth';
import { ApolloLink, from } from '@apollo/client';
import { getOnErrorLink } from '../links/getOnErrorLink';
import { getTokenLifetimeLink } from '../links/getTokenLifetimeLink';
import { getWithTokenLink } from '../links/getWithTokenLink';
import { httpLink } from '../links/httpLink';

export const useCombinedLink = (): ApolloLink => {
  const auth = useAuth();

  const tokenLifetimeLink = getTokenLifetimeLink(
    auth.accessToken.current,
    auth.refreshToken
  );
  const withTokenLink = getWithTokenLink(auth.accessToken.current);
  const onErrorLink = getOnErrorLink(auth.logOut);

  return from([tokenLifetimeLink, withTokenLink, onErrorLink, httpLink]);
};
