import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateApplicationToAddPartnersVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateApplicationToAddPartnersInput;
}>;

export type CreateApplicationToAddPartners = {
  __typename: 'Mutation';
  createApplicationToAddPartners: {
    __typename: 'CreateApplicationToAddPartnersPayload';
    applicationToAddPartnersItems: Array<{
      __typename: 'ApplicationToAddPartnersItem';
      id: string;
      note?: string | null;
      username: string;
    }>;
  };
};

export const CreateApplicationToAddPartnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateApplicationToAddPartners' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateApplicationToAddPartnersInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApplicationToAddPartners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'applicationToAddPartnersItems',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateApplicationToAddPartnersMutationFn = Apollo.MutationFunction<
  CreateApplicationToAddPartners,
  CreateApplicationToAddPartnersVariables
>;

/**
 * __useCreateApplicationToAddPartners__
 *
 * To run a mutation, you first call `useCreateApplicationToAddPartners` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationToAddPartners` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationToAddPartners, { data, loading, error }] = useCreateApplicationToAddPartners({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationToAddPartners(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateApplicationToAddPartners,
    CreateApplicationToAddPartnersVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateApplicationToAddPartners,
    CreateApplicationToAddPartnersVariables
  >(CreateApplicationToAddPartnersDocument, options);
}
export type CreateApplicationToAddPartnersHookResult = ReturnType<
  typeof useCreateApplicationToAddPartners
>;
export type CreateApplicationToAddPartnersMutationResult =
  Apollo.MutationResult<CreateApplicationToAddPartners>;
export type CreateApplicationToAddPartnersMutationOptions =
  Apollo.BaseMutationOptions<
    CreateApplicationToAddPartners,
    CreateApplicationToAddPartnersVariables
  >;
