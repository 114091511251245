import {
  useModalContext,
  closeModal as closeModalAction,
  updateConfirmClose,
} from 'ui/Modal';
import { useConfirmModalContext } from 'ui/ConfirmModal';
import { useCallback } from 'react';
import { ApplicationToAddPartnersForm } from './components/ApplicationToAddPartnersForm';

export const ApplicationToAddPartnersModal = () => {
  const { dispatch } = useModalContext();
  const { showConfirmation } = useConfirmModalContext();

  const closeModal = useCallback(() => {
    dispatch(closeModalAction());
  }, [dispatch]);

  const handleCompleted = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleDirtyChange = useCallback(
    (dirty: boolean) => {
      dispatch(
        updateConfirmClose({
          confirmClose: dirty ? showConfirmation : null,
        })
      );
    },
    [dispatch, showConfirmation]
  );

  return (
    <ApplicationToAddPartnersForm
      onCompleted={handleCompleted}
      onDirtyChange={handleDirtyChange}
    />
  );
};
