import { IconSize } from 'ui/Icon';
import { LabelWithIconSize } from './const';
import styles from './LabelWithIcon.module.scss';

export const mapSizeToClassName: Record<LabelWithIconSize, string> = {
  [LabelWithIconSize.Large]: styles.large,
  [LabelWithIconSize.Small]: styles.small,
};

export const mapSizeToIconSize: Record<LabelWithIconSize, IconSize> = {
  [LabelWithIconSize.Large]: IconSize.Large,
  [LabelWithIconSize.Small]: IconSize.Small,
};
