import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { RevShareReferralLinkStatisticItemData } from './RevShareReferralLinkStatisticItemData';
import { RevShareReferralLinkStatisticInfoData } from './RevShareReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../queries/fragments/generated/PageInfoData';
export type RevShareReferralLinkStatisticConnectionData = {
  __typename: 'RevShareReferralLinkStatisticConnection';
  edges: Array<{
    __typename: 'RevShareReferralLinkStatisticEdge';
    cursor: string;
    node: {
      __typename: 'RevShareReferralLinkStatisticItem';
      id: string;
      playerName?: string | null;
      statisticInfo: {
        __typename: 'RevShareReferralLinkStatisticInfo';
        numberOfRedeposits: number;
        wasFD: boolean;
        adminFee: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        averageDeposit: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        depositsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        depositsFee: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        redepositsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        withdrawalsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        withdrawalsFee: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
      };
    };
  }>;
  pageInfo: {
    __typename: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};

export const RevShareReferralLinkStatisticConnectionData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RevShareReferralLinkStatisticConnectionData',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'RevShareReferralLinkStatisticConnection',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RevShareReferralLinkStatisticItemData',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PageInfoData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
