import { useAuth } from 'components/auth';
import { useGetNextRewardInfo } from '../queries/generated/GetNextRewardInfo';

export const useGetNextRewardInfoQuery = () => {
  const { user } = useAuth();

  const { data, error, loading, refetch } = useGetNextRewardInfo({
    variables: {
      userId: user.id,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    nextRewardInfo: data?.user.nextRewardInfo,
    error,
    loading,
    refetch,
  };
};
