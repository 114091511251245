import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'ui/Tabs';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { ReferralLinkMediaTable } from './components/ReferralLinkMediaTable';
import { PromoCodeMediaTable } from './components/PromoCodeMediaTable';
import styles from './MediaDataBlock.module.scss';

export const MediaDataBlock: FC = () => {
  const { t } = useTranslation('media');
  const translationUserType = useTranslationUserType();

  const tabs = [
    {
      title: t(`${translationUserType}.action.tabMenu.links`),
      content: <ReferralLinkMediaTable />,
    },
    {
      title: t(`${translationUserType}.action.tabMenu.promoCodes`),
      content: <PromoCodeMediaTable />,
    },
  ];

  return <Tabs tabs={tabs} tabsControlsClass={styles.tabs} />;
};
