import { PostbackMacros } from 'types/generated/gql';
import { ListItem } from 'ui/List';
import { TranslationUserType } from 'types';
import { mapMacroToValue, mapMacroToDescription } from './mapHelpers';

export const getListItemByMacros = (
  macros: Array<PostbackMacros>,
  onClick: (value?: string) => void,
  translationUserType: TranslationUserType
): Array<ListItem> =>
  macros.map((item) => {
    const value = mapMacroToValue[item];

    return {
      label: value,
      value,
      labelSub: mapMacroToDescription(item, translationUserType),
      onClick,
    };
  });
