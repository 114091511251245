import { useCallback } from 'react';
import { useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { WithdrawalMethodTypeName } from 'types';
import {
  GetWithdrawalMethod,
  useGetWithdrawalMethodLazyQuery,
} from '../queries/generated/GetWithdrawalMethod';
import { useGameAccountModal } from './useGameAccountModal';

export const useOpenWithdrawalMethodModal = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [fetchWithdrawalMethod, { loading }] =
    useGetWithdrawalMethodLazyQuery();
  const { openGameAccountModal } = useGameAccountModal();

  const handleCompleted = useCallback(
    (data: GetWithdrawalMethod) => {
      if (
        data.user?.withdrawalMethod?.__typename ===
        WithdrawalMethodTypeName.GameAccountWithdrawalMethod
      ) {
        openGameAccountModal({
          status: data.user.withdrawalMethod.status,
          accountNumber: data.user.withdrawalMethod.gameAccountNumber,
        });
      }
    },
    [openGameAccountModal]
  );

  const handleWithdrawalMethodClick = useCallback(
    async (withdrawalMethodId: string) => {
      await fetchWithdrawalMethod({
        variables: { userId: user.id, withdrawalMethodId },
        onCompleted: handleCompleted,
        onError: () => {
          toast.error(t('errors.commonError'));
        },
      });
    },
    [fetchWithdrawalMethod, handleCompleted, t, user.id]
  );

  return { handleWithdrawalMethodClick, loading };
};
