import { FC } from 'react';
import { Menu } from 'ui/Menu';
import logoSrc from 'assets/images/logo.svg';
import { useGetMenuData } from 'components/AppMenu/hooks/useGetMenuData';
import { t } from 'i18n';

export const AppMenu: FC = () => {
  const { topMenuData, bottomMenuData } = useGetMenuData();

  return (
    <Menu
      topMenuData={topMenuData}
      bottomMenuData={bottomMenuData}
      topImgSrc={logoSrc}
      topImgAlt={t('logoAlt')}
    />
  );
};
