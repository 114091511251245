import { UserTypeName } from 'types';
import { useActivatePartnerMutation } from './useActivatePartnerMutation';
import { useActivateCompanyMutation } from './useActivateCompanyMutation';

export const useActivateUser = (userTypeName: UserTypeName) => {
  const useActivateUserByUserType =
    userTypeName === UserTypeName.Partner
      ? useActivatePartnerMutation
      : useActivateCompanyMutation;

  return useActivateUserByUserType();
};
