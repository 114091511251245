import { CSSProperties } from 'react';

interface CalculateStyleParams {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}

export const calculateStyle = ({
  width = '100%',
  height = '1em',
}: CalculateStyleParams) => ({
  width: typeof width === 'number' ? `${width}px` : width,
  height: typeof height === 'number' ? `${height}px` : height,
});
