import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { getCodeFromGraphQLError } from 'utils/getCodeFromGraphQLError';
import { ErrorContent } from 'ui/Error/components/ErrorContent/ErrorContent';
import styles from './Error.module.scss';

interface ErrorProps {
  error?: ApolloError | string | null;
  className?: string;
}

const renderGraphQLErrors = (errors: ApolloError) =>
  errors.graphQLErrors.map((graphQLError) => (
    <div
      key={graphQLError.message}
      className={styles.errorWrapper}
      data-testid="error-wrapper"
    >
      <ErrorContent
        header={getCodeFromGraphQLError(graphQLError)}
        text={graphQLError.message}
      />
    </div>
  ));

const getErrorMessage = (error: ErrorProps['error']) => {
  if (error instanceof ApolloError && error.graphQLErrors.length) {
    return renderGraphQLErrors(error);
  }

  if (error instanceof ApolloError && error.message) {
    return <ErrorContent header={error.message} />;
  }

  if (typeof error === 'string') {
    return <ErrorContent header={error} />;
  }

  return null;
};

export const Error: FC<ErrorProps> = ({ error, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(styles.wrapper, className)}>
      {getErrorMessage(error || t('errors.commonError'))}
    </div>
  );
};
