import { ApolloLink, fromPromise } from '@apollo/client';
import { Token } from 'components/auth';
import { SECONDS_BEFORE_EXPIRATION } from 'components/auth/const';

export const getTokenLifetimeLink = (
  tokenData: Token,
  refreshToken: () => Promise<void>
): ApolloLink => {
  const tokenLifetimeMiddleware = new ApolloLink((operation, forward) => {
    if (tokenData.token) {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const remainingTime = tokenData.expiredAt - currentTime;

      if (remainingTime <= SECONDS_BEFORE_EXPIRATION) {
        return fromPromise(refreshToken()).flatMap(() => forward(operation));
      }
    }

    return forward(operation);
  });

  return tokenLifetimeMiddleware;
};
