import { checkIsAuthError, useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { useNavigateToSuccessPage } from './useNavigateToSuccessPage';

export const useCheckTwoFactor = () => {
  const { t } = useTranslation(['common', 'auth']);
  const { navigateToSuccessPage } = useNavigateToSuccessPage();

  const auth = useAuth();

  const checkTwoFactor = async (code: string) => {
    try {
      await auth.checkTwoFactor(code);
      navigateToSuccessPage();
    } catch (e) {
      let errorText: string;

      if (checkIsAuthError(e)) {
        errorText =
          e.status === 401
            ? t('auth:login.input.2FA.validation.invalidCode')
            : e.detail;
      } else {
        errorText = t('errors.commonError');
      }

      toast.error({
        header: 'Error',
        text: errorText,
      });
    }
  };

  return {
    checkTwoFactor,
  };
};
