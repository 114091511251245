import { AppRoute, AppRouteValues } from 'const';
import { EntityTypeToRegister } from './const';

export const mapChosenValueToRoute: Record<
  EntityTypeToRegister,
  AppRouteValues
> = {
  [EntityTypeToRegister.Partner]: AppRoute.Lang.RegistrationPartner,
  [EntityTypeToRegister.Company]: AppRoute.Lang.RegistrationCompany,
};
