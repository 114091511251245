import { useContext } from 'react';
import { LanguageContextValue } from '../types';
import { LanguageContext } from '../LanguageContext';
import { LanguageContextProvider } from '../LanguageContextProvider';

export const useLanguage = (): LanguageContextValue => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error(
      `${useLanguage.name} must be used within an ${LanguageContextProvider.name} wrapper`
    );
  }

  return context;
};
