import { FC } from 'react';
import classNames from 'classnames';
import styles from './SelectAndInput.module.scss';
import { Select } from '../Select/Select';
import { SelectOption } from '../Select/type';
import { Input } from '../Input/Input';

interface SelectAndInputProps {
  selectProps: {
    label: string;
    name: string;
    options: Array<SelectOption>;
  };
  inputProps: {
    label: string;
    name: string;
    disabled?: boolean;
    maxLength?: number;
  };
  isError?: boolean;
}

export const SelectAndInput: FC<SelectAndInputProps> = ({
  selectProps,
  inputProps,
  isError,
}) => (
  <div
    className={classNames(
      inputProps.disabled && styles.inputDisabled,
      styles.mainContainer
    )}
    data-testid="select-and-input-main-container"
  >
    <div
      className={classNames(isError && styles.error, styles.selectContainer)}
      data-testid="select-and-input-select-container"
    >
      <Select
        hasMinWidth={false}
        wrapperClassName={styles.select}
        {...selectProps}
      />
    </div>
    <div
      className={classNames(isError && styles.error, styles.inputContainer)}
      data-testid="select-and-input-input-container"
    >
      <Input
        wrapperClassName={styles.input}
        formValidationProps={{ errorTooltipPlacement: 'right' }}
        {...inputProps}
      />
    </div>
  </div>
);
