import classNames from 'classnames';
import { ListItem } from './types';
import { ListSize } from './const';
import { mapSizeToClassName } from './helpers';
import styles from './List.module.scss';

interface ListProps<TValue extends string> {
  items: Array<ListItem<TValue>>;
  highlightOnHover?: boolean;
  size?: ListSize;
  listContainerClassName?: string;
  resetLabelWidth?: boolean;
}

export const List = <TValue extends string>({
  items,
  listContainerClassName,
  size = ListSize.Large,
  highlightOnHover,
  resetLabelWidth,
}: ListProps<TValue>) => (
  <ul className={classNames(styles.listContainer, listContainerClassName)}>
    {items.map((item) => {
      const { onClick, value, labelSub, leftElement, labelRight, label } = item;

      return (
        <li className={styles.listItem} key={label}>
          <button
            type="button"
            className={classNames(
              styles.button,
              highlightOnHover && styles.highlightOnHover,
              mapSizeToClassName[size]
            )}
            onClick={() => {
              onClick?.(value);
            }}
          >
            {leftElement}
            {labelSub ? (
              <div
                data-testid="list-label-container"
                className={styles.withLabelContainer}
              >
                <span className={styles.label}>{label}</span>
                <span className={styles.labelSub}>{labelSub}</span>
              </div>
            ) : (
              <span
                className={classNames(
                  styles.label,
                  resetLabelWidth && styles.withResetWidth
                )}
              >
                {label}
              </span>
            )}

            {labelRight && (
              <span className={styles.labelRight}>{labelRight}</span>
            )}
          </button>
        </li>
      );
    })}
  </ul>
);
