import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Loader } from 'ui/Loader';
import { useAuthWithRefreshToken } from './hooks/useAuthWithRefreshToken';
import { routes } from './const';

const router = createBrowserRouter(routes);

export const AppRouter: FC = () => {
  const { isLoading } = useAuthWithRefreshToken();

  if (isLoading) {
    return <Loader />;
  }

  return <RouterProvider router={router} />;
};
