import { useMatches } from 'react-router-dom';
import { BreadcrumbsItem } from 'ui/Breadcrumbs';
import { checkIsHasCrumb } from '../helpers';

export const useBreadcrumbs = () => {
  const matches = useMatches();

  const crumbs: Array<BreadcrumbsItem> = matches
    .filter(checkIsHasCrumb)
    .map((match) => ({
      label: match.handle.crumb?.(match.data) ?? '',
      path: match.pathname,
    }));

  return {
    crumbs,
  };
};
