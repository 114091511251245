import { useContext } from 'react';
import { EditingIdsContextValue } from '../types';
import { EditingIdsContext } from '../EditingIdsContext';
import { EditingIdsContextProvider } from '../EditingIdsContextProvider';

export const useEditingIdsContext = (): EditingIdsContextValue => {
  const context = useContext(EditingIdsContext);

  if (!context) {
    throw new Error(
      `${useEditingIdsContext.name} must be used within an ${EditingIdsContextProvider.name} wrapper`
    );
  }

  return context;
};
