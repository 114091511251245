import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { TwoFactorGeneration } from '../TwoFactorGeneration';
import { TwoFactorSendForm } from '../TwoFactorSendForm';
import styles from './TwoFactorGenerationBlock.module.scss';

export const TwoFactorGenerationBlock: FC = () => {
  const { t } = useTranslation('auth');
  const translationUserType = useTranslationUserType();

  return (
    <>
      <h1 className={SPEC_HEADER_LARGE}>
        {t(`signup.${translationUserType}.content.2FAConnection.title`)}
      </h1>
      <div className={styles.textBlock}>
        <p>
          {t(`signup.${translationUserType}.content.2FAConnection.subtitle`)}
        </p>
      </div>
      <TwoFactorGeneration />
      <TwoFactorSendForm
        disabledButtonTooltip={t(
          `signup.${translationUserType}.action.2FAConnection.button.hint`
        )}
        inputLabel={t(
          `signup.${translationUserType}.input.2FAConnection.label.code`
        )}
        buttonText={t(
          `signup.${translationUserType}.action.2FAConnection.button.connect`
        )}
      />
    </>
  );
};
