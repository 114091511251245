import { useGetMediaCampaignsForMediaQuery } from './useGetMediaCampaignsForMediaQuery';
import { useGetTariffForMediaItemCreationQuery } from './useGetTariffForMediaItemCreationQuery';

export const useGetDataForCreateMediaForm = () => {
  const {
    tariffs,
    loading: tariffLoading,
    error: tariffError,
  } = useGetTariffForMediaItemCreationQuery();

  const {
    mediaCampaigns,
    loading: mediaCampaignLoading,
    error: mediaCampaignError,
  } = useGetMediaCampaignsForMediaQuery();

  const loading = tariffLoading || mediaCampaignLoading;
  const error = tariffError || mediaCampaignError;

  return { tariffs, mediaCampaigns, loading, error };
};
