import { Language } from 'types/generated/gql';
import { t } from 'i18n';

export const mapLanguageToDisplayName = (lang: Language): string => {
  const map: Record<Language, string> = {
    [Language.Ru]: t('common:input.label.languageRu'),
    [Language.En]: t('common:input.label.languageEn'),
  };

  return map[lang];
};
