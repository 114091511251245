import { ButtonSize } from '../const';
import { IconSize } from '../../Icon';
import { LinkStyledProp } from '../types';

export const getIconSize = (
  buttonSize: ButtonSize,
  isLinkStyled?: LinkStyledProp
) => {
  switch (buttonSize) {
    case ButtonSize.Medium:
      return isLinkStyled ? IconSize.Medium : IconSize.Small;
    case ButtonSize.Small:
      return isLinkStyled ? IconSize.Medium : IconSize.Small;
    default:
      return IconSize.Large;
  }
};
