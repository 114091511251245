import { MODAL_CONTAINER_ID } from 'ui/ModalContainer';

// TODO: need to implement for postbacks https://eyeconweb.atlassian.net/browse/AMS-775
export const scrollModalToBottom = () => {
  const modalElement = document.getElementById(MODAL_CONTAINER_ID);

  if (!modalElement) {
    return;
  }

  setTimeout(() => {
    modalElement.scrollTo({
      top: modalElement.scrollHeight,
      behavior: 'smooth',
    });
  }, 0);
};
