import { FC } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'components/auth';
import { toast } from 'ui/toast';
import { CurrencyAccount } from 'types/generated/gql';
import { FieldName } from './const';
import { WithdrawToGameAccountFormContent } from './components/WithdrawToGameAccountFormContent';
import { WithdrawToGameAccountFormValues } from './types';
import { createValidateWithdrawToGameAccountFormFn } from './validation';
import { useWithdrawToGameAccountFromCurrentCurrencyAccountMutation } from './hooks/useWithdrawToGameAccountFromCurrentCurrencyAccountMutation';

interface WithdrawToGameAccountFormProps {
  onDirtyChange: (dirty: boolean) => void;
  onWithdrawalCreated: () => void;
  accountNumber: string;
  currentCurrencyAccount: Pick<CurrencyAccount, 'amount' | 'currency'>;
}

export const WithdrawToGameAccountForm: FC<WithdrawToGameAccountFormProps> = ({
  onDirtyChange,
  onWithdrawalCreated,
  accountNumber,
  currentCurrencyAccount,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation('common');

  const initialValues: WithdrawToGameAccountFormValues = {
    [FieldName.AccountNumber]: accountNumber,
    [FieldName.Amount]: undefined,
  };

  const { withdrawToGameAccount, loading } =
    useWithdrawToGameAccountFromCurrentCurrencyAccountMutation({
      onWithdrawalCreated,
    });

  const handleSubmit = (values: WithdrawToGameAccountFormValues) => {
    const amountNumber = Number(values.amount);

    if (Number.isNaN(amountNumber) || amountNumber === 0) {
      toast.error(t('errors.commonError'));

      return;
    }

    withdrawToGameAccount({
      variables: {
        input: {
          id: user.id,
          amount: amountNumber,
        },
      },
    });
  };

  return (
    <Formik
      validate={createValidateWithdrawToGameAccountFormFn({
        currency: currentCurrencyAccount.currency,
        currentAmount: currentCurrencyAccount.amount || 0,
      })}
      validateOnBlur
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <WithdrawToGameAccountFormContent
        currency={currentCurrencyAccount.currency}
        onDirtyChange={onDirtyChange}
        loading={loading}
      />
    </Formik>
  );
};
