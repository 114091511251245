import { AuthStatuses, useAuth } from 'components/auth';
import { useGetPreferredLanguage } from 'queries/generated/GetPreferredLanguage';

export const useGetPreferredLanguageQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetPreferredLanguage({
    variables: {
      id: user.id,
    },
    fetchPolicy: 'cache-first',
    skip: user.authStatus === AuthStatuses.Unauthorized,
  });

  return { preferredLanguage: data?.user.preferredLanguage, loading, error };
};
