import { Country, Currency } from 'types';
import { Language } from 'types/generated/gql';
import { checkIsCountryCode } from 'utils/countries/checkIsCountryCode';

const ROOT_FLAG_UNICODE_INDEX = 127397;

export const getFlagEmojiByCountryCode = (
  countryCode: Country | Language
): string => {
  const codePoints = countryCode
    .split('')
    .map((char) => ROOT_FLAG_UNICODE_INDEX + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};

export const getCountryCodeByCurrencyCode = (
  currencyCode: Currency
): Country | Language | null => {
  const countryCode = currencyCode.slice(0, 2).toUpperCase();

  return checkIsCountryCode(countryCode) ? countryCode : null;
};
