import * as SchemaTypes from '../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { TariffForMediaBasicData } from './TariffForMediaBasicData';
export type TariffForMediaData_CpaTariff = {
  __typename: 'CpaTariff';
  geolocation: Array<CommonType.Country>;
  createdAt: string;
  id: string;
  name: string;
  baseline: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  reward: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
};

export type TariffForMediaData_RevShareTariff = {
  __typename: 'RevShareTariff';
  rewardPercent: string;
  createdAt: string;
  id: string;
  name: string;
};

export type TariffForMediaData =
  | TariffForMediaData_CpaTariff
  | TariffForMediaData_RevShareTariff;

export const TariffForMediaData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TariffForMediaData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TariffInterface' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TariffForMediaBasicData' },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RevShareTariff' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rewardPercent' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CpaTariff' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseline' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'geolocation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reward' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
