import { FC } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import {
  FormBlockMarginSize,
  FormBlockWrapper,
  Input,
  InputPassword,
  SelectAndInput,
  Textarea,
} from 'ui/formItems';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/Button';
import { useContactTypeOptions } from 'hooks/useContactTypeOptions';
import { FieldName, mapFiledNameToMaxLength } from './const';
import { RegistrationValues } from './types';
import { CurrencySelect } from '../../../../components/CurrencySelect';
import { validatePartnerRegistration } from './validation';
import { useRegisterPartnerAndLogIn } from './hooks/useRegisterPartnerAndLogIn';

const defaultInitialValues: RegistrationValues = {
  [FieldName.ContactType]: undefined,
  [FieldName.ContactText]: '',
  [FieldName.CurrencySelect]: undefined,
  [FieldName.Name]: '',
  [FieldName.TrafficType]: undefined,
  [FieldName.Email]: '',
  [FieldName.CreatePassword]: '',
  [FieldName.ConfirmPassword]: '',
};

interface RegistrationPartnerFormProps {
  initialValues?: RegistrationValues;
}

export const RegistrationPartnerForm: FC<RegistrationPartnerFormProps> = ({
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation('auth');
  const { isLoading, registerAndLogin } = useRegisterPartnerAndLogIn();

  const contactTypeOptions = useContactTypeOptions();

  /**
   * await is required here because as a result of executing registerAndLogin,
   * errors might be set in the email and name fields of the form, and the logic for displaying the Tooltip
   * on fields with errors works correctly only if we wait for the result of the request execution in handleSubmit.
   */
  const handleSubmit = async (
    values: RegistrationValues,
    actions: FormikHelpers<RegistrationValues>
  ) => {
    await registerAndLogin(values, actions);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validatePartnerRegistration}
    >
      {({ values, errors }) => (
        <Form data-testid="registration-partner-form" noValidate>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Large}>
            <SelectAndInput
              selectProps={{
                name: FieldName.ContactType,
                label: t(
                  'signup.partner.input.registration.label.contactSelect'
                ),
                options: contactTypeOptions,
              }}
              inputProps={{
                name: FieldName.ContactText,
                label: t('signup.partner.input.registration.label.contact'),
                disabled: !values[FieldName.ContactType],
                maxLength: mapFiledNameToMaxLength[FieldName.ContactText],
              }}
              isError={Boolean(errors.contactText)}
            />
            <Input
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.Name}
              label={t('signup.partner.input.registration.label.userName')}
              autoComplete="user-name"
              acceptedCharsRegExp={/[а-яa-zёË0-9]/i}
              maxLength={mapFiledNameToMaxLength[FieldName.Name]}
            />
            <Textarea
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.TrafficType}
              label={t('signup.partner.input.registration.label.trafficSource')}
              maxLength={mapFiledNameToMaxLength[FieldName.TrafficType]}
            />
            <CurrencySelect name={FieldName.CurrencySelect} />
            <Input
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              type="email"
              autoComplete="email"
              name={FieldName.Email}
              label={t('signup.partner.input.registration.label.emailCustom')}
              maxLength={mapFiledNameToMaxLength[FieldName.Email]}
            />
            <InputPassword
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.CreatePassword}
              label={t(
                'signup.partner.input.registration.label.createPassword'
              )}
              autoComplete="new-password"
            />
            <InputPassword
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.ConfirmPassword}
              label={t(
                'signup.partner.input.registration.label.confirmPassword'
              )}
              autoComplete="new-password"
            />
          </FormBlockWrapper>
          <Button isLoading={isLoading} type="submit" isFullWidth>
            {t('signup.partner.action.registration.button.sendApplication')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
