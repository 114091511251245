import { FC } from 'react';
import { Formik } from 'formik';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { CreateCompanyMediaFormValues } from './types';
import { validateCreateCompanyMediaForm } from './validation';
import { CreateMediaFormContent } from '../CreateMediaFormContent';
import { useGetDataForCreateMediaForm } from '../../hooks/useGetDataForCreateMediaForm';
import { useCreateCompanyReferralLinkMediaItemMutation } from './hooks/useCreateCompanyReferralLinkMediaItemMutation';

const initialValues: CreateCompanyMediaFormValues = {
  mediaItemName: '',
  mediaCampaignId: '',
  tariffId: '',
  ownerId: undefined,
};

export interface CreateCompanyMediaFormProps {
  onDirtyChange: (dirty: boolean) => void;
  onCreated: () => void;
}

export const CreateCompanyMediaForm: FC<CreateCompanyMediaFormProps> = ({
  onDirtyChange,
  onCreated,
}) => {
  const {
    tariffs,
    mediaCampaigns,
    loading: dataRequestLoading,
    error,
  } = useGetDataForCreateMediaForm();

  const { createReferralLinkMediaItem, loading: createMutationLoading } =
    useCreateCompanyReferralLinkMediaItemMutation({ onCompleted: onCreated });

  const handleSubmit = (values: CreateCompanyMediaFormValues) => {
    createReferralLinkMediaItem(values);
  };

  if (dataRequestLoading) {
    return <Loader />;
  }

  if (error || !tariffs || !mediaCampaigns) {
    return <Error error={error} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateCreateCompanyMediaForm}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <CreateMediaFormContent
        onDirtyChange={onDirtyChange}
        mediaCampaigns={mediaCampaigns}
        tariffs={tariffs}
        loading={createMutationLoading}
        isCompany
      />
    </Formik>
  );
};
