import { FC } from 'react';
import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { useCreateApplicationToAddPartnersMutation } from './hooks/useCreateApplicationToAddPartnersMutation';
import { PartnerCardList } from './components/PartnerCardList';
import { ApplicationToAddPartnersFormValues } from './types';
import { FieldName } from './const';

const initialValues: ApplicationToAddPartnersFormValues = {
  [FieldName.CompanyPartners]: [
    {
      [FieldName.Username]: '',
      [FieldName.Note]: null,
      [FieldName.Id]: 1,
    },
  ],
};

export interface ApplicationToAddPartnersFormProps {
  onCompleted: () => void;
  onDirtyChange: (dirty: boolean) => void;
}

export const ApplicationToAddPartnersForm: FC<
  ApplicationToAddPartnersFormProps
> = ({ onDirtyChange, onCompleted }) => {
  const { createApplicationToAddPartners, loading } =
    useCreateApplicationToAddPartnersMutation({
      onCompleted,
    });

  const handleSubmit = (values: ApplicationToAddPartnersFormValues) => {
    const submitValues = values[FieldName.CompanyPartners].map(
      ({ username, note }) => ({
        username,
        note: note || null,
      })
    );

    createApplicationToAddPartners({ companyPartners: submitValues });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {() => (
        <Form data-testid="add-partners-form" noValidate>
          <FieldArray
            name={FieldName.CompanyPartners}
            render={(arrayHelpers: FieldArrayRenderProps) => (
              <PartnerCardList
                isSubmitting={loading}
                onDirtyChange={onDirtyChange}
                push={arrayHelpers.push}
                remove={arrayHelpers.remove}
              />
            )}
          />
        </Form>
      )}
    </Formik>
  );
};
