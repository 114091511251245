import { useMemo } from 'react';
import { UserTypeName } from 'types';
import { useAuth } from 'components/auth';
import { useGetCompanyPartnersForMedia } from '../queries/generated/GetCompanyPartnersForMedia';
import { COMPANY_PARTNERS_PER_PAGE } from '../const';

export const useGetCompanyPartnersForMediaQuery = (search?: string) => {
  const { user } = useAuth();

  const { data, loading, fetchMore, error, refetch } =
    useGetCompanyPartnersForMedia({
      variables: {
        userId: user.id,
        first: COMPANY_PARTNERS_PER_PAGE,
        search,
      },
      notifyOnNetworkStatusChange: true,
    });

  const companyPartnersData =
    data?.user.__typename === UserTypeName.Company
      ? data.user.companyPartners
      : undefined;

  const companyPartners = useMemo(
    () => companyPartnersData?.edges?.map((edge) => edge.node),
    [companyPartnersData]
  );

  return {
    companyPartners,
    pageInfo: companyPartnersData?.pageInfo,
    fetchMore,
    loading,
    error,
    refetch,
  };
};

export type UseGetCompanyPartnersForMediaQueryResult = ReturnType<
  typeof useGetCompanyPartnersForMediaQuery
>;
