import { TranslationUserType, UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from './queryHooks/useGetUserTypeNameQuery';

export const useTranslationUserType = (): TranslationUserType => {
  const { userTypeName, loading, error } = useGetUserTypeNameQuery();

  if (loading || error || !userTypeName) {
    return TranslationUserType.Partner;
  }

  const mapUserTypeNameToTranslationUserType: Record<
    UserTypeName,
    TranslationUserType
  > = {
    [UserTypeName.Partner]: TranslationUserType.Partner,
    [UserTypeName.Company]: TranslationUserType.Company,
  };

  return mapUserTypeNameToTranslationUserType[userTypeName];
};
