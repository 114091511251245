import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';
import { IndentBetweenRowsSize } from 'ui/formItems/components/FormBlockWrapper/const';
import {
  FormBlockMarginSize,
  FormBlockWrapper,
  Input,
  Select,
} from 'ui/formItems';
import { Tooltip } from 'ui/Tooltip';
import { Button } from 'ui/Button';
import { MediaCampaign } from 'types/generated/gql';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { FieldName } from '../../const';
import { TariffForMediaBasicData } from '../../../../queries/fragments/generated/TariffForMediaBasicData';
import { getMediaCampaignsOptions } from './helpers';
import { CompanyPartnersSelect } from '../CompanyPartnersSelect';
import { MEDIA_ITEM_NAME_MAX_LENGTH } from './const';

interface CreateMediaFormContentProps {
  onDirtyChange: (dirty: boolean) => void;
  tariffs: Array<TariffForMediaBasicData>;
  mediaCampaigns: Array<MediaCampaign>;
  loading: boolean;
  isCompany?: boolean;
}

export const CreateMediaFormContent: FC<CreateMediaFormContentProps> = ({
  tariffs,
  mediaCampaigns,
  loading,
  onDirtyChange,
  isCompany,
}) => {
  const { t } = useTranslation(['common', 'media']);
  const translationUserType = useTranslationUserType();
  const { dirty, isValid } = useFormikContext();

  const mediaCampaignOptions = getMediaCampaignsOptions(mediaCampaigns);
  const isButtonDisable = !isValid || !dirty;

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  return (
    <Form data-testid="create-media-form" noValidate>
      <FormBlockWrapper
        marginBottomSize={FormBlockMarginSize.Medium}
        indentBetweenRowSize={IndentBetweenRowsSize.Large}
      >
        <Input
          name={FieldName.MediaItemName}
          label={t(
            `media:${translationUserType}.content.drawer.createMedia.input.label.title`
          )}
          maxLength={MEDIA_ITEM_NAME_MAX_LENGTH}
        />
        {isCompany && <CompanyPartnersSelect />}
        <Select
          name={FieldName.MediaCampaignId}
          label={t(
            `media:${translationUserType}.content.drawer.createMedia.input.label.mediaCampaign`
          )}
          options={mediaCampaignOptions}
        />
        <Select
          label={t(
            `media:${translationUserType}.content.drawer.createMedia.input.label.tariff`
          )}
          formValidationProps={{ errorTooltipPlacement: 'left' }}
          name={FieldName.TariffId}
          options={tariffs.map((tariff) => ({
            label: tariff.name,
            value: tariff.id,
          }))}
        />
      </FormBlockWrapper>

      <Tooltip
        placement="right"
        tooltipContent={isButtonDisable ? t('errors.fillAllFields') : undefined}
      >
        <Button type="submit" isLoading={loading} disabled={isButtonDisable}>
          {t(
            `media:${translationUserType}.content.drawer.createMedia.action.button.create`
          )}
        </Button>
      </Tooltip>
    </Form>
  );
};
