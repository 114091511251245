import { t } from 'i18n';

/**
 * Password Regular Expression Description:
 * - `(?=.*[0-9])`: Positive lookahead for a digit [0-9]
 * - `(?=.*[a-z])`: Positive lookahead for one lowercase character [a-z]
 * - `(?=.*[A-Z])`: Positive lookahead for one uppercase character [A-Z]
 * - `.`: Matches anything
 * - `{9,20}`: Length at least 9 characters and maximum of 20 characters
 */
const passwordRegexp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{9,20}$/;

export const getPasswordError = (password?: string): string | undefined => {
  if (!password) {
    return undefined;
  }

  return !passwordRegexp.test(password) ? t('errors.passwordError') : undefined;
};
