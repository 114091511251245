import { NonNullableDateRange } from 'types';
import { DateRangePageParam } from '../const';

export const extractDateRangeFromSearchParams = (
  params: URLSearchParams
): NonNullableDateRange | undefined => {
  const start = params.get(DateRangePageParam.Start);
  const end = params.get(DateRangePageParam.End);

  if (!start || !end) {
    return undefined;
  }

  const startDate = new Date(start);
  const endDate = new Date(end);

  if (Number.isNaN(+startDate) || Number.isNaN(+endDate)) {
    return undefined;
  }

  return [startDate, endDate];
};
