import { AUTH_URI } from 'const';
import { TokenBodyResponse, TwoFactorBodyResponse } from './types';

const post = async (url: string, body?: string, token?: string) => {
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Link ${token}` : '',
    },
    body,
  });

  let json;

  try {
    json = await response.json();
  } catch (e) {
    return response.ok;
  }

  if (response.ok) {
    return json;
  }

  throw json;
};

export const getToken = (
  identifier: string,
  password: string
): Promise<TokenBodyResponse> =>
  post(`${AUTH_URI}/token`, JSON.stringify({ identifier, password }));

export const getRefreshToken = (): Promise<TokenBodyResponse> =>
  post(`${AUTH_URI}/token/refresh`);

export const generateTwoFactor = (): Promise<TwoFactorBodyResponse> =>
  post(`${AUTH_URI}/2fa/generate`);

export const checkTwoFactor = (authCode: string): Promise<TokenBodyResponse> =>
  post(`${AUTH_URI}/2fa/check`, JSON.stringify({ authCode }));

export const logOut = (): Promise<boolean> => post(`${AUTH_URI}/logout`);

export const passwordSet = (
  password: string,
  token: string
): Promise<boolean> =>
  post(`${AUTH_URI}/password/set`, JSON.stringify({ password }), token);
