import { FC } from 'react';
import classnames from 'classnames';
import { ModalContainer } from 'ui/ModalContainer';
import { Error } from 'ui/Error';
import { ReactComponent as IconClose } from 'assets/icons/line/Close.svg';
import { ReactComponent as IconArrowLeft } from 'assets/icons/line/AltArrowLeft.svg';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { useModalContext } from './hooks/useModalContext';
import { useModalMethods } from './hooks/useModalMethods';
import styles from './Modal.module.scss';

export const Modal: FC = () => {
  const { state } = useModalContext();
  const currentModal = state.modals.at(-1);
  const { isClosing, closeModal, goBack, shouldRenderGoBack } = useModalMethods(
    { currentModal }
  );

  return (
    <ModalContainer
      isOpen={Boolean(currentModal && !isClosing)}
      onRequestClose={closeModal}
      className={{
        base: styles.modalContent,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
    >
      <div className={styles.modalHeader}>
        <div
          className={classnames(
            styles.container,
            shouldRenderGoBack && styles.clickable
          )}
        >
          {shouldRenderGoBack && (
            <Button
              onClick={goBack}
              className={styles.backButton}
              size={ButtonSize.Medium}
              iconConfig={{ iconComponent: IconArrowLeft }}
              theme={ButtonTheme.Grey}
              data-testid="go-back-modal-button"
            />
          )}
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{currentModal?.title}</span>
            {currentModal?.subTitle && (
              <span className={styles.subTitle}>{currentModal.subTitle}</span>
            )}
          </div>
        </div>
        <Button
          iconConfig={{ iconComponent: IconClose }}
          disabled={currentModal?.isCloseDisabled}
          size={ButtonSize.Medium}
          onClick={closeModal}
          theme={ButtonTheme.Grey}
          data-testid="close-modal-button"
        />
      </div>
      <div className={styles.content}>
        {currentModal || !isClosing ? currentModal?.content : <Error />}
      </div>
    </ModalContainer>
  );
};
