import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { PartnerRevShareStatisticInfoData } from '../fragments/generated/PartnerRevShareStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerRevShareStatisticTotalVariables = SchemaTypes.Exact<{
  end: SchemaTypes.Scalars['Date']['input'];
  start: SchemaTypes.Scalars['Date']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetPartnerRevShareStatisticTotal = {
  __typename: 'Query';
  user:
    | { __typename: 'Company'; id: string }
    | {
        __typename: 'Partner';
        id: string;
        revShareStatistic: {
          __typename: 'PartnerRevShareStatistic';
          total: {
            __typename: 'PartnerRevShareStatisticInfo';
            conversionHostsToRegPercent: number;
            conversionRegToDepPercent: number;
            firstDeposits: number;
            hits: number;
            hosts: number;
            numberOfRedeposits: number;
            registrations: number;
            adminFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            averageDeposit: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            redepositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            reward: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
          };
        };
      };
};

export const GetPartnerRevShareStatisticTotalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnerRevShareStatisticTotal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revShareStatistic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PartnerRevShareStatisticInfoData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PartnerRevShareStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPartnerRevShareStatisticTotal__
 *
 * To run a query within a React component, call `useGetPartnerRevShareStatisticTotal` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerRevShareStatisticTotal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerRevShareStatisticTotal({
 *   variables: {
 *      end: // value for 'end'
 *      start: // value for 'start'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPartnerRevShareStatisticTotal(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPartnerRevShareStatisticTotal,
    GetPartnerRevShareStatisticTotalVariables
  > &
    (
      | { variables: GetPartnerRevShareStatisticTotalVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPartnerRevShareStatisticTotal,
    GetPartnerRevShareStatisticTotalVariables
  >(GetPartnerRevShareStatisticTotalDocument, options);
}
export function useGetPartnerRevShareStatisticTotalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPartnerRevShareStatisticTotal,
    GetPartnerRevShareStatisticTotalVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPartnerRevShareStatisticTotal,
    GetPartnerRevShareStatisticTotalVariables
  >(GetPartnerRevShareStatisticTotalDocument, options);
}
export function useGetPartnerRevShareStatisticTotalSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPartnerRevShareStatisticTotal,
    GetPartnerRevShareStatisticTotalVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetPartnerRevShareStatisticTotal,
    GetPartnerRevShareStatisticTotalVariables
  >(GetPartnerRevShareStatisticTotalDocument, options);
}
export type GetPartnerRevShareStatisticTotalHookResult = ReturnType<
  typeof useGetPartnerRevShareStatisticTotal
>;
export type GetPartnerRevShareStatisticTotalLazyQueryHookResult = ReturnType<
  typeof useGetPartnerRevShareStatisticTotalLazyQuery
>;
export type GetPartnerRevShareStatisticTotalSuspenseQueryHookResult =
  ReturnType<typeof useGetPartnerRevShareStatisticTotalSuspenseQuery>;
export type GetPartnerRevShareStatisticTotalQueryResult = Apollo.QueryResult<
  GetPartnerRevShareStatisticTotal,
  GetPartnerRevShareStatisticTotalVariables
>;
