import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'ui/Table';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { UserTypeName } from 'types';
import { useLoadMore } from 'hooks/useLoadMore';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { usePromoCodeMediaItemsColumns } from './hooks/usePromoCodeMediaItemsColumns';
import { useGetPromoCodeMediaItemsQuery } from './hooks/useGetPromoCodeMediaItemsQuery';

export const PromoCodeMediaTable: FC = () => {
  const { t } = useTranslation('media');

  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  const {
    promoCodeMediaItems,
    fetchMore,
    pageInfo,
    loading,
    error: getMediaItemsError,
  } = useGetPromoCodeMediaItemsQuery();

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const error = getUserTypeError || getMediaItemsError;

  const columns = usePromoCodeMediaItemsColumns({
    isCompany: userTypeName === UserTypeName.Company,
  });

  if ((!promoCodeMediaItems && loading) || getUserTypeLoading) {
    return <Loader />;
  }

  if (error || promoCodeMediaItems === undefined) {
    return <Error error={error} />;
  }

  return (
    <Table
      columns={columns}
      data={promoCodeMediaItems}
      emptyText={t('placeholder.promoCodes.noData')}
      isLoading={loading}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
    />
  );
};
