import { FC, useState } from 'react';
import { PasswordChangeSuccessful } from '../PasswordChangeSuccessful';
import { PasswordChangeFormWrapper } from '../PasswordChangeFormWrapper';

export const PasswordChangeBlock: FC = () => {
  const [hasPasswordChanged, setPasswordChanged] = useState(false);

  return hasPasswordChanged ? (
    <PasswordChangeSuccessful />
  ) : (
    <PasswordChangeFormWrapper setPasswordChanged={setPasswordChanged} />
  );
};
