import { FC } from 'react';
import { Loader } from 'ui/Loader';
import { Navigate, Outlet } from 'react-router';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { AppRoute } from 'const';
import { useIsUserStatusActive } from '../../hooks/useIsUserStatusActive';

export const NotActiveUserWrapper: FC = () => {
  const { isUserStatusActive, loading } = useIsUserStatusActive();
  const { createLocalizedPath } = useCreateLocalizedPath();

  if (loading) {
    return <Loader />;
  }

  if (isUserStatusActive) {
    return <Navigate to={createLocalizedPath(AppRoute.Lang.Main)} replace />;
  }

  return <Outlet />;
};
