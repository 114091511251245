import { FC } from 'react';
import { Navigate } from 'react-router';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { AppRoute } from 'const';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';

export const Statistic: FC = () => {
  const { createLocalizedPath } = useCreateLocalizedPath();

  return (
    <Navigate
      to={createLocalizedPath(
        composeRoutes([
          AppRoute.Lang.Statistic.root,
          AppRoute.Lang.Statistic.RevShare.root,
        ])
      )}
    />
  );
};
