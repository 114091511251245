import { FC } from 'react';
import { Formik } from 'formik';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { CreatePartnerMediaFormValues } from './types';
import { validateCreatePartnerMediaForm } from './validation';
import { useCreatePartnerReferralLinkMediaItemMutation } from './hooks/useCreatePartnerReferralLinkMediaItemMutation';
import { CreateMediaFormContent } from '../CreateMediaFormContent';
import { useGetDataForCreateMediaForm } from '../../hooks/useGetDataForCreateMediaForm';

const initialValues: CreatePartnerMediaFormValues = {
  mediaItemName: '',
  mediaCampaignId: '',
  tariffId: '',
};

export interface CreatePartnerMediaFormProps {
  onDirtyChange: (dirty: boolean) => void;
  onCreated: () => void;
}

export const CreatePartnerMediaForm: FC<CreatePartnerMediaFormProps> = ({
  onDirtyChange,
  onCreated,
}) => {
  const {
    tariffs,
    mediaCampaigns,
    loading: dataRequestLoading,
    error,
  } = useGetDataForCreateMediaForm();

  const { createReferralLinkMediaItem, loading: createMutationLoading } =
    useCreatePartnerReferralLinkMediaItemMutation({ onCompleted: onCreated });

  const handleSubmit = (values: CreatePartnerMediaFormValues) => {
    createReferralLinkMediaItem(values);
  };

  if (dataRequestLoading) {
    return <Loader />;
  }

  if (error || !tariffs || !mediaCampaigns) {
    return <Error error={error} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateCreatePartnerMediaForm}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <CreateMediaFormContent
        onDirtyChange={onDirtyChange}
        mediaCampaigns={mediaCampaigns}
        tariffs={tariffs}
        loading={createMutationLoading}
      />
    </Formik>
  );
};
