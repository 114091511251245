import { Dispatch, FC, SetStateAction } from 'react';
import { PasswordResetSuccessful } from '../PasswordResetSuccessful';
import { PasswordResetFormWrapper } from '../PasswordResetFormWrapper';

interface PasswordResetBlockProps {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
}

export const PasswordResetBlock: FC<PasswordResetBlockProps> = ({
  email,
  setEmail,
}) =>
  email ? (
    <PasswordResetSuccessful email={email} />
  ) : (
    <PasswordResetFormWrapper setEmail={setEmail} />
  );
