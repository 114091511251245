import { ReactComponent as VavadaIcon } from 'assets/icons/withdrawalMethods/Vavada.svg';
import { IconComponent, WithdrawalMethodTypeName } from 'types';
import { WithdrawalMethodSubTitle } from 'pages/Payments/components/TopPanel/components/WithdrawRewardCard/components/WithdrawRewardModal/const';
import { t } from '../i18n';

export const mapWithdrawalMethodsToIcon: Record<
  WithdrawalMethodTypeName,
  IconComponent
> = {
  [WithdrawalMethodTypeName.GameAccountWithdrawalMethod]: VavadaIcon,
};

export const mapWithdrawalMethodsToSubTitle: Record<
  WithdrawalMethodTypeName,
  WithdrawalMethodSubTitle
> = {
  [WithdrawalMethodTypeName.GameAccountWithdrawalMethod]:
    WithdrawalMethodSubTitle.GameAccountNumber,
};

export const mapWithdrawalMethodsToTitle = (
  method: WithdrawalMethodTypeName
): string => {
  const map: Record<WithdrawalMethodTypeName, string> = {
    [WithdrawalMethodTypeName.GameAccountWithdrawalMethod]: t(
      'payments:partnerAndCompany.content.drawer.selectMethod.methods.vavada.title'
    ),
  };

  return map[method];
};
