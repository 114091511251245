export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonTheme {
  Blue = 'blue',
  BlueLight = 'blueLight',
  BlueGhost = 'blueGhost',
  BlueGrey = 'blueGrey',
  Red = 'red',
  RedLight = 'redLight',
  RedGhost = 'redGhost',
  RedGrey = 'redGrey',
  Grey = 'grey',
}

export enum ButtonTextColor {
  White = 'white',
  Blue = 'blue',
  Red = 'red',
  BlueGrey = 'blueGrey',
  RedGrey = 'redGrey',
}

export enum ButtonAlign {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}
