import { useTranslation } from 'react-i18next';
import { Table } from 'ui/Table';
import { useLoadMore } from 'hooks/useLoadMore';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { useGetWithdrawalInvoicesQuery } from './hooks/useGetWithdrawalInvoicesQuery';
import { useWithdrawalInvoicesColumns } from './hooks/useWithdrawalInvoicesColumns';

export const WithdrawalInvoicesTable = () => {
  const { t } = useTranslation('payments');

  const columns = useWithdrawalInvoicesColumns();

  const { withdrawalInvoices, fetchMore, pageInfo, loading, error } =
    useGetWithdrawalInvoicesQuery();

  const loadMore = useLoadMore(fetchMore, pageInfo);

  if (!withdrawalInvoices && loading) {
    return <Loader />;
  }

  if (error || !withdrawalInvoices) {
    return <Error error={error} />;
  }

  return (
    <Table
      columns={columns}
      data={withdrawalInvoices}
      emptyText={t('partnerAndCompany.content.placeholder.withdrawal.noData')}
      isLoading={loading}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
    />
  );
};
