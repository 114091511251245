import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CompanyCpaStatisticItemData } from '../fragments/generated/CompanyCpaStatisticItemData';
import { CompanyCpaStatisticInfoData } from '../fragments/generated/CompanyCpaStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../../queries/fragments/generated/PageInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyCpaStatisticItemsVariables = SchemaTypes.Exact<{
  after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  end: SchemaTypes.Scalars['Date']['input'];
  first?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
  start: SchemaTypes.Scalars['Date']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetCompanyCpaStatisticItems = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        cpaStatistic: {
          __typename: 'CompanyCpaStatistic';
          statisticItems: {
            __typename: 'CompanyCpaStatisticItemsConnection';
            edges: Array<{
              __typename: 'CompanyCpaStatisticItemsConnectionEdge';
              cursor: string;
              node: {
                __typename: 'CompanyCpaStatisticItem';
                id: string;
                link: string;
                name: string;
                owner:
                  | { __typename: 'Company'; id: string; name: string }
                  | {
                      __typename: 'CompanyPartner';
                      id: string;
                      username: string;
                    };
                statisticInfo: {
                  __typename: 'CompanyCpaStatisticInfo';
                  approved: number;
                  conversionHostsToRegPercent: number;
                  conversionRegToDepPercent: number;
                  firstDeposits: number;
                  hits: number;
                  hold: number;
                  hosts: number;
                  numberOfRedeposits: number;
                  paid: number;
                  qualified: number;
                  registrations: number;
                  rejected: number;
                  averageDeposit: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  depositsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  redepositsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  reward: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  withdrawalsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                };
              };
            }>;
            pageInfo: {
              __typename: 'PageInfo';
              endCursor?: string | null;
              hasNextPage: boolean;
              hasPreviousPage: boolean;
              startCursor: string;
            };
          };
        };
      }
    | { __typename: 'Partner'; id: string };
};

export const GetCompanyCpaStatisticItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyCpaStatisticItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cpaStatistic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statisticItems' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'after' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'after' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cursor',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'CompanyCpaStatisticItemData',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'PageInfoData',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CompanyCpaStatisticItemData.definitions,
    ...CompanyCpaStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
    ...PageInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyCpaStatisticItems__
 *
 * To run a query within a React component, call `useGetCompanyCpaStatisticItems` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCpaStatisticItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCpaStatisticItems({
 *   variables: {
 *      after: // value for 'after'
 *      end: // value for 'end'
 *      first: // value for 'first'
 *      start: // value for 'start'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCompanyCpaStatisticItems(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompanyCpaStatisticItems,
    GetCompanyCpaStatisticItemsVariables
  > &
    (
      | { variables: GetCompanyCpaStatisticItemsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCompanyCpaStatisticItems,
    GetCompanyCpaStatisticItemsVariables
  >(GetCompanyCpaStatisticItemsDocument, options);
}
export function useGetCompanyCpaStatisticItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyCpaStatisticItems,
    GetCompanyCpaStatisticItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCompanyCpaStatisticItems,
    GetCompanyCpaStatisticItemsVariables
  >(GetCompanyCpaStatisticItemsDocument, options);
}
export function useGetCompanyCpaStatisticItemsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetCompanyCpaStatisticItems,
    GetCompanyCpaStatisticItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetCompanyCpaStatisticItems,
    GetCompanyCpaStatisticItemsVariables
  >(GetCompanyCpaStatisticItemsDocument, options);
}
export type GetCompanyCpaStatisticItemsHookResult = ReturnType<
  typeof useGetCompanyCpaStatisticItems
>;
export type GetCompanyCpaStatisticItemsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCpaStatisticItemsLazyQuery
>;
export type GetCompanyCpaStatisticItemsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyCpaStatisticItemsSuspenseQuery
>;
export type GetCompanyCpaStatisticItemsQueryResult = Apollo.QueryResult<
  GetCompanyCpaStatisticItems,
  GetCompanyCpaStatisticItemsVariables
>;
