import { forwardRef, HTMLProps } from 'react';
import {
  FloatingPortal,
  useMergeRefs,
  useTransitionStyles,
  FloatingArrow,
} from '@floating-ui/react';
import classNames from 'classnames';
import { TooltipTheme } from '../../const';
import styles from './TooltipContent.module.scss';
import { useTooltipState } from '../../hooks/useTooltipState';

const ANIMATION_DURATION = 250;

interface TooltipContentProps extends HTMLProps<HTMLDivElement> {
  tooltipTheme?: TooltipTheme;
  arrowOffset?: string | number | null;
}

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  (
    { className, tooltipTheme = TooltipTheme.Dark, arrowOffset, ...props },
    propRef
  ) => {
    const state = useTooltipState();
    const ref = useMergeRefs([state.refs.setFloating, propRef]);

    const { isMounted, styles: transitionStyles } = useTransitionStyles(
      state.context,
      {
        duration: ANIMATION_DURATION,
        initial: {
          opacity: 0,
        },
      }
    );

    if (!isMounted) return null;

    return (
      <FloatingPortal>
        <div
          className={classNames(
            styles.tooltip,
            className,
            tooltipTheme === TooltipTheme.Dark ? styles.dark : styles.light
          )}
          ref={ref}
          style={{
            ...state.floatingStyles,
            ...props.style,
            ...transitionStyles,
          }}
          {...state.getFloatingProps(props)}
        >
          {props.children}
          <FloatingArrow
            ref={state.arrowRef}
            tipRadius={3}
            height={8}
            width={16}
            context={state.context}
            staticOffset={arrowOffset}
            className={styles.tooltipArrow}
          />
        </div>
      </FloatingPortal>
    );
  }
);
