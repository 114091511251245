import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonTheme } from 'ui/Button';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import styles from './ResetTwoFactor.module.scss';

export const ResetTwoFactor: FC = () => {
  const { t } = useTranslation('profile');
  const translationUserType = useTranslationUserType();

  return (
    <div>
      <div className={styles.text}>
        {t(`${translationUserType}.content.drawer.reset2fa.header.subtitle`)}
      </div>
      <Button theme={ButtonTheme.Grey}>
        {t(
          `${translationUserType}.content.drawer.reset2fa.action.button.primary`
        )}
      </Button>
    </div>
  );
};
