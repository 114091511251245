import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { ReferralLinkMediaItemData } from '../fragments/generated/ReferralLinkMediaItemData';
import { ReferralLinkMediaItemBasicData } from '../fragments/generated/ReferralLinkMediaItemBasicData';
import { TariffForMediaBasicData } from '../fragments/generated/TariffForMediaBasicData';
import { TariffForMediaData } from '../fragments/generated/TariffForMediaData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetMediaItemVariables = SchemaTypes.Exact<{
  mediaItemId: SchemaTypes.Scalars['ID']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetMediaItem = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        mediaItem:
          | { __typename: 'PromoCodeMediaItem' }
          | {
              __typename: 'ReferralLinkMediaItem';
              createdAt: string;
              link: string;
              id: string;
              name: string;
              tariff:
                | {
                    __typename: 'CpaTariff';
                    geolocation: Array<CommonType.Country>;
                    createdAt: string;
                    id: string;
                    name: string;
                    baseline: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    reward: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                  }
                | {
                    __typename: 'RevShareTariff';
                    rewardPercent: string;
                    createdAt: string;
                    id: string;
                    name: string;
                  };
              postbackInfo: {
                __typename: 'PostbackInfo';
                commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
              };
              owner:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanyPartner'; id: string; username: string }
                | { __typename: 'Partner'; id: string; username: string };
            };
      }
    | {
        __typename: 'Partner';
        id: string;
        mediaItem:
          | { __typename: 'PromoCodeMediaItem' }
          | {
              __typename: 'ReferralLinkMediaItem';
              createdAt: string;
              link: string;
              id: string;
              name: string;
              tariff:
                | {
                    __typename: 'CpaTariff';
                    geolocation: Array<CommonType.Country>;
                    createdAt: string;
                    id: string;
                    name: string;
                    baseline: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    reward: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                  }
                | {
                    __typename: 'RevShareTariff';
                    rewardPercent: string;
                    createdAt: string;
                    id: string;
                    name: string;
                  };
              postbackInfo: {
                __typename: 'PostbackInfo';
                commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
              };
              owner:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanyPartner'; id: string; username: string }
                | { __typename: 'Partner'; id: string; username: string };
            };
      };
};

export const GetMediaItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMediaItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mediaItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mediaItemId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'ReferralLinkMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ReferralLinkMediaItemData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mediaItemId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'ReferralLinkMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ReferralLinkMediaItemData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReferralLinkMediaItemData.definitions,
    ...ReferralLinkMediaItemBasicData.definitions,
    ...TariffForMediaBasicData.definitions,
    ...TariffForMediaData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetMediaItem__
 *
 * To run a query within a React component, call `useGetMediaItem` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaItem` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaItem({
 *   variables: {
 *      mediaItemId: // value for 'mediaItemId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMediaItem(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetMediaItem,
    GetMediaItemVariables
  > &
    ({ variables: GetMediaItemVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetMediaItem, GetMediaItemVariables>(
    GetMediaItemDocument,
    options
  );
}
export function useGetMediaItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMediaItem,
    GetMediaItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetMediaItem, GetMediaItemVariables>(
    GetMediaItemDocument,
    options
  );
}
export function useGetMediaItemSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetMediaItem,
    GetMediaItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetMediaItem, GetMediaItemVariables>(
    GetMediaItemDocument,
    options
  );
}
export type GetMediaItemHookResult = ReturnType<typeof useGetMediaItem>;
export type GetMediaItemLazyQueryHookResult = ReturnType<
  typeof useGetMediaItemLazyQuery
>;
export type GetMediaItemSuspenseQueryHookResult = ReturnType<
  typeof useGetMediaItemSuspenseQuery
>;
export type GetMediaItemQueryResult = Apollo.QueryResult<
  GetMediaItem,
  GetMediaItemVariables
>;
