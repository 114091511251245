import { useEffect } from 'react';
import { FieldHelperProps } from 'formik';
import { SelectOption } from '../type';

interface UseClearValueIfOptionsChangedParams {
  options?: Array<SelectOption>;
  selectedOption?: SelectOption;
  formikHelpers: FieldHelperProps<string | null>;
  formikValue: string | null;
}

/**
 * The following situations can happen:
 * 1. We render a select with certain options.
 * 2. We choose a value in the select.
 * 3. The select re-renders, but the options in it change for some reason.
 * Now, the option selected in point 2 is no longer in the list of options.
 *
 * In this case, we need to clear formik field option.  The hook is needed for this.
 */
export const useClearValueIfOptionsChanged = ({
  options,
  selectedOption,
  formikHelpers,
  formikValue,
}: UseClearValueIfOptionsChangedParams) => {
  useEffect(() => {
    const newOptionsValues = options?.map((option) => option.value);

    const newOptionsHasPickedValue =
      selectedOption && newOptionsValues?.includes(selectedOption.value);

    if (!newOptionsHasPickedValue && formikValue) {
      formikHelpers.setValue('');
    }
  }, [formikHelpers, formikValue, options, selectedOption]);
};
