import * as SchemaTypes from '../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { ReferralLinkMediaItemData } from './ReferralLinkMediaItemData';
import { ReferralLinkMediaItemBasicData } from './ReferralLinkMediaItemBasicData';
import { TariffForMediaBasicData } from './TariffForMediaBasicData';
import { TariffForMediaData } from './TariffForMediaData';
import { PageInfoData } from '../../../../../queries/fragments/generated/PageInfoData';
export type ReferralLinkMediaItemConnectionData = {
  __typename: 'ReferralLinkMediaItemConnection';
  edges: Array<{
    __typename: 'ReferralLinkMediaItemConnectionEdge';
    cursor: string;
    node: {
      __typename: 'ReferralLinkMediaItem';
      createdAt: string;
      link: string;
      id: string;
      name: string;
      tariff:
        | {
            __typename: 'CpaTariff';
            geolocation: Array<CommonType.Country>;
            createdAt: string;
            id: string;
            name: string;
            baseline: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            reward: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
          }
        | {
            __typename: 'RevShareTariff';
            rewardPercent: string;
            createdAt: string;
            id: string;
            name: string;
          };
      postbackInfo: {
        __typename: 'PostbackInfo';
        commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
      };
      owner:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanyPartner'; id: string; username: string }
        | { __typename: 'Partner'; id: string; username: string };
    };
  }>;
  pageInfo: {
    __typename: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};

export const ReferralLinkMediaItemConnectionData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralLinkMediaItemConnectionData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReferralLinkMediaItemConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ReferralLinkMediaItemData',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PageInfoData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
