import { t } from 'i18n';
import { StatusLabelSize, StatusLabelType } from 'ui/StatusLabel';
import {
  GameAccountStatusLabelSize,
  GameAccountStatusLabelType,
} from './const';

export const mapWithdrawalStatusToStatusLabelType: Record<
  GameAccountStatusLabelType,
  StatusLabelType
> = {
  [GameAccountStatusLabelType.Pending]: StatusLabelType.Pending,
  [GameAccountStatusLabelType.Deleted]: StatusLabelType.Warning,
};

export const mapWithdrawalStatusToText = (
  status: GameAccountStatusLabelType
): string => {
  const map: Record<GameAccountStatusLabelType, string> = {
    [GameAccountStatusLabelType.Pending]: t(
      'payments:partnerAndCompany.content.drawer.selectMethod.methods.vavada.status1'
    ),
    [GameAccountStatusLabelType.Deleted]: t(
      'payments:partnerAndCompany.content.drawer.selectMethod.methods.vavada.status2'
    ),
  };

  return map[status];
};

export const mapSizeToStatusLabelSize: Record<
  GameAccountStatusLabelSize,
  StatusLabelSize
> = {
  [GameAccountStatusLabelSize.Large]: StatusLabelSize.Large,
  [GameAccountStatusLabelSize.Small]: StatusLabelSize.Small,
};
