import { AppRouteValues } from 'const';
import { StringWithAutocomplete } from 'utils/tsUtils/stringWithAutocomplete';

type NonRootRoute = Exclude<AppRouteValues, '/' | ''>;

export const composeRoutes = <
  T extends [NonRootRoute, ...Array<StringWithAutocomplete<NonRootRoute>>],
>(
  routes: T,
  options?: {
    searchParams: URLSearchParams;
  }
): string => {
  if (!routes.length) {
    throw new Error('You must pass routes inside composeRouter');
  }

  const route = routes.join('/');

  if (route.match(/[?&]/)) {
    throw new Error(
      'Route string cannot include symbols ? and &. Use options.searchParams instead'
    );
  }

  const { searchParams } = options || {};
  const searchParamsString = searchParams?.toString();

  return searchParamsString && searchParamsString !== '?'
    ? `${route}?${searchParams}`
    : route;
};
