import { useCallback, useState } from 'react';
import { goBackModal, closeModal as closeModalAction } from 'ui/Modal';
import { useModalContext } from './useModalContext';
import { Modal } from '../store/types';

const ANIMATION_DURATION = 300;

interface UseModalMethodsParams {
  currentModal?: Modal;
}

export const useModalMethods = ({ currentModal }: UseModalMethodsParams) => {
  const { state, dispatch } = useModalContext();
  const [isClosing, setIsClosing] = useState(false);

  const shouldRenderGoBack = state.modals.length > 1;

  const closeModal = useCallback(async () => {
    if (!currentModal || currentModal.isCloseDisabled) {
      return;
    }

    const isCloseConfirmed = currentModal.confirmClose
      ? await currentModal.confirmClose()
      : true;

    if (isCloseConfirmed && !isClosing) {
      setIsClosing(true);

      setTimeout(() => {
        dispatch(closeModalAction());
        setIsClosing(false);
      }, ANIMATION_DURATION);
    }
  }, [currentModal, isClosing, dispatch]);

  const goBack = useCallback(async () => {
    if (!currentModal || !shouldRenderGoBack) {
      return;
    }

    const isCloseConfirmed = currentModal.confirmClose
      ? await currentModal.confirmClose()
      : true;

    if (isCloseConfirmed) {
      dispatch(goBackModal());
    }
  }, [shouldRenderGoBack, currentModal, dispatch]);

  return {
    closeModal,
    goBack,
    isClosing,
    shouldRenderGoBack,
  };
};
