import { ApolloError } from '@apollo/client';
import { ErrorCode } from 'const';

export const checkIsApolloErrorHasErrorCode = (
  apolloError: ApolloError | undefined,
  errorCode: ErrorCode
) =>
  Boolean(
    apolloError &&
      apolloError.graphQLErrors.find(
        (err) => err.extensions?.code === errorCode
      )
  );
