import { ErrorCode } from 'const';
import { GraphQLFormattedError } from 'graphql';
import { isValueInArray } from './checkIsEnumValue';

export const getCodeFromGraphQLError = (
  graphQLError: GraphQLFormattedError
): ErrorCode => {
  const code = graphQLError.extensions?.code;

  return isValueInArray(Object.values(ErrorCode), code)
    ? code
    : ErrorCode.Unknown;
};
