import { t } from 'i18n';
import { CreationState } from '../../../../types';

interface GotTooltipTextParams {
  creationState?: CreationState;
  isValid: boolean;
  isDirty: boolean;
  isLoading: boolean;
}

export const getTooltipText = ({
  creationState,
  isValid,
  isDirty,
  isLoading,
}: GotTooltipTextParams): string | undefined => {
  if (creationState?.isCreationInProcess && !isLoading) {
    return t('postbackEventLoadingTooltip');
  }

  if (creationState && !(isValid && isDirty)) {
    return t('errors.fillAllFields');
  }

  return undefined;
};
