import { Country } from 'types';
import { Language } from 'types/generated/gql';

const countryCodes = new Set(Object.values(Country));

export const checkIsCountryCode = (
  value?: unknown
): value is Country | Language => {
  if (!value || typeof value !== 'string') {
    return false;
  }

  return countryCodes.has(value as Country);
};
