import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { useGetMediaCampaignsForMedia } from '../queries/generated/GetMediaCampaignsForMedia';

const COUNT_PER_PAGE = 100;

export const useGetMediaCampaignsForMediaQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetMediaCampaignsForMedia({
    variables: {
      userId: user.id,
      first: COUNT_PER_PAGE,
    },
  });

  const mediaCampaigns = useMemo(
    () => data?.user.mediaCampaigns.edges?.map((edge) => edge.node),
    [data?.user.mediaCampaigns.edges]
  );

  return {
    mediaCampaigns,
    loading,
    error,
  };
};
