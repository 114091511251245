import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { NonNullableDateRange } from 'types';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { Table } from 'ui/Table';
import { useLoadMore } from 'hooks/useLoadMore';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { useGetCpaStatisticDetailedQuery } from './hooks/useGetCpaStatisticDetailedQuery';
import { useCpaStatisticDetailedColumns } from './hooks/useCpaStatisticDetailedColumns';
import { extractDetailedStatisticItems } from './helpers';
import { CpaReferralLinkStatisticItemData } from './query/fragments/generated/CpaReferralLinkStatisticItemData';
import { useGenerateCpaStatisticDetailedSummaryRow } from './hooks/useGenerateCpaStatisticDetailedSummaryRow';

interface CpaStatisticDetailedProps {
  cpaMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const CpaStatisticDetailed: FC<CpaStatisticDetailedProps> = ({
  cpaMediaItemId,
  nonNullableDateRange,
}) => {
  const { t } = useTranslation('statistic');

  const translationUserType = useTranslationUserType();

  const {
    cpaStatisticDetailed,
    pageInfo,
    fetchMore,
    loading: itemsQueryLoading,
    error: itemsQueryError,
    isSliced,
  } = useGetCpaStatisticDetailedQuery({ cpaMediaItemId, nonNullableDateRange });

  const {
    summaryRowData,
    loading: totalQueryLoading,
    error: totalQueryError,
  } = useGenerateCpaStatisticDetailedSummaryRow({
    cpaMediaItemId,
    nonNullableDateRange,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const columns = useCpaStatisticDetailedColumns({
    link: cpaStatisticDetailed?.link,
  });

  const loading = itemsQueryLoading || totalQueryLoading;
  const error = itemsQueryError || totalQueryError;

  const tableData: Array<CpaReferralLinkStatisticItemData> | undefined =
    extractDetailedStatisticItems(
      cpaStatisticDetailed?.cpaStatistic.statisticItems
    );

  if ((!tableData || !summaryRowData) && loading) {
    return <Loader />;
  }

  if (error || !tableData || !summaryRowData?.length) {
    return <Error error={error} />;
  }

  return (
    <Table
      columns={columns}
      data={tableData}
      summaryRow={summaryRowData}
      emptyText={t(`${translationUserType}.content.placeholder.noData`)}
      isLoading={loading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
    />
  );
};
