import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Icon } from 'ui/Icon';
import { IconComponent } from 'types';
import styles from './MultilineCellWithIcon.module.scss';

interface MultilineCellWithIconProps {
  title: string;
  icon?: IconComponent;
  subTitle?: ReactNode;
  isAlignRight?: boolean;
}

export const MultilineCellWithIcon: FC<MultilineCellWithIconProps> = ({
  title,
  icon,
  subTitle,
  isAlignRight = false,
}) => (
  <div
    className={classnames(
      styles.container,
      !icon && isAlignRight && styles.rightAlign
    )}
    data-testid="multi-line-cell-container"
  >
    {icon && (
      <div className={styles.iconContainer}>
        <Icon iconComponent={icon} />
      </div>
    )}

    <div className={styles.textContainer}>
      <span className={styles.title}>{title}</span>
      {subTitle && (
        <span className={styles.subTitle}>{subTitle && subTitle}</span>
      )}
    </div>
  </div>
);
