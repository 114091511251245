import { useEffect, useState } from 'react';

/**
 * Custom hook to determine if a sticky element is pinned.
 *
 * An element is considered pinned if it is sticking but part of it
 * is not visible - this is the only way to determine if it's pinned.
 *
 * For the hook to work, you need to add a negative position value in the styles,
 * so that part of the pinned element "exceeds" the container. For example, `bottom: -1px` or `top: -1px`.
 *
 * See `ActionButtons.tsx` for an example.
 *
 * @param element The HTML element to observe.
 * @returns True if the element is pinned, false otherwise.
 */

export const useIsStickyElemPinned = (elem: HTMLDivElement | null) => {
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    if (!elem) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      ([e]) => {
        setIsPinned(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    observer.observe(elem);

    return () => observer.unobserve(elem);
  }, [elem]);

  return isPinned;
};
