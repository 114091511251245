import { FC } from 'react';
import { Country } from 'types';
import { getCountryNameByCode } from 'utils/countries/getCountryNameByCode';
import { FlagIcon } from 'ui/FlagIcon';
import { useLanguage } from 'contexts/LanguageContext';
import styles from './CountryWithFlag.module.scss';

interface CountryWithFlagProps {
  country: Country;
}

export const CountryWithFlag: FC<CountryWithFlagProps> = ({ country }) => {
  const { currentLanguage } = useLanguage();

  return (
    <div className={styles.container}>
      <FlagIcon code={country} />
      {getCountryNameByCode({
        countryCode: country,
        language: currentLanguage,
      })}
    </div>
  );
};
