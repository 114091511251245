import { useAuth } from 'components/auth';
import { useGetUserStatus } from 'queries/generated/GetUserStatus';
import { UserTypeName } from 'types';

export const useGetUserStatusQuery = () => {
  const { user } = useAuth();

  const { data, loading } = useGetUserStatus({
    variables: {
      id: user.id,
    },
  });

  const status =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.partnerStatus
      : data?.user.companyStatus;

  return { status, loading };
};
