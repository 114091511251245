import { useCallback } from 'react';
import { PageInfo } from 'types/generated/gql';

interface FetchMoreOptions {
  variables: {
    after?: string | null;
  };
}

interface FetchMore {
  (options: FetchMoreOptions): void;
}

/**
 * Custom hook to handle fetching more data using Apollo's fetchMore.
 *
 * @param fetchMore - fetchMore function from Apollo query result.
 * @param pageInfo - pageInfo from Apollo query result.
 * @param isSliced - indicating whether the data is currently being sliced, which prevents fetching more data.
 *
 * For more details about slice, see: `frontend/src/utils/customApolloQuery/hooks/usePaginationData.ts`
 *
 * @returns A callback function that fetches more data
 */
export const useLoadMore = (fetchMore: FetchMore, pageInfo?: PageInfo) =>
  useCallback(() => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    });
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);
