import { ModalAction } from '../const';
import { Modal } from '../types';

type Payload = Pick<Modal, 'content' | 'title' | 'subTitle'>;

export interface OpenModalAction {
  type: ModalAction.Open;
  payload: Payload;
}

export const openModal = (payload: Payload): OpenModalAction => ({
  type: ModalAction.Open,
  payload,
});
