import { useAuth } from 'components/auth';
import { NonNullableDateRange, UserTypeName } from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useGetCompanyRevShareStatisticTotal } from '../queries/generated/GetCompanyRevShareStatisticTotal';

const { DateScalar } = DateFormat;

export const useGetCompanyRevShareStatisticTotalQuery = ([
  start,
  end,
]: NonNullableDateRange) => {
  const { user } = useAuth();

  const { data, loading, error } = useGetCompanyRevShareStatisticTotal({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      userId: user.id,
    },
    cacheFirstOnPop: true,
  });

  const totalStatistic =
    data?.user.__typename === UserTypeName.Company
      ? data.user.revShareStatistic.total
      : undefined;

  return { totalStatistic, loading, error };
};
