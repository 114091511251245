import { FC, ReactNode } from 'react';
import styles from './VerticalLayout.module.scss';

interface VerticalLayoutProps {
  topBlock?: ReactNode;
  centralBlock: ReactNode;
  bottomBlock?: ReactNode;
}

export const VerticalLayout: FC<VerticalLayoutProps> = ({
  topBlock,
  centralBlock,
  bottomBlock,
}) => (
  <div className={styles.container}>
    <div className={styles.topBlock}>{topBlock}</div>
    <div className={styles.centralBlock}>{centralBlock}</div>
    {bottomBlock && <div className={styles.bottomBlock}>{bottomBlock}</div>}
  </div>
);
