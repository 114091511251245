// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: Array<any>) => any>(
  func: T,
  wait: number
): T => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return ((...args: Parameters<T>): void => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func(...args), wait);
  }) as T;
};
