export enum StatusLabelSize {
  Large = 'large',
  Small = 'small',
}

export enum StatusLabelType {
  Pending = 'pending',
  InProcess = 'inProcess',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}
