import { FC } from 'react';
import { UserTypeName } from 'types';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { useRouteChangeConfirmationModal } from 'hooks/useRouteChangeConfirmationModal';
import { useUserIdentities } from './hooks/useUserIdentities';
import { CompanyDataBlockContent } from './components/CompanyDataBlockContent';
import { ProfileDataBlockContent } from './components/PartnerDataBlockContent';
import styles from './ProfileDataBlock.module.scss';

export const ProfileDataBlock: FC = () => {
  const { userIdentities, error, loading } = useUserIdentities();
  const { setShouldShowConfirmationModal } = useRouteChangeConfirmationModal();

  if (error || (!loading && !userIdentities)) {
    return <Error error={error} />;
  }

  if (loading) {
    return <Loader shouldExcludeMenuWidth />;
  }

  if (!userIdentities) {
    return null;
  }

  return (
    <div className={styles.profile}>
      {userIdentities.__typename === UserTypeName.Partner ? (
        <ProfileDataBlockContent
          partnerIdentities={userIdentities}
          setShouldShowConfirmationModal={setShouldShowConfirmationModal}
        />
      ) : (
        <CompanyDataBlockContent
          companyIdentities={userIdentities}
          setShouldShowConfirmationModal={setShouldShowConfirmationModal}
        />
      )}
    </div>
  );
};
