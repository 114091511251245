import * as CommonType from '../common';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * Country-code is uppercase ISO 3166 two-letter code (alpha-2)
   * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes)
   * Example: AQ
   */
  CountryCode: { input: CommonType.Country; output: CommonType.Country };
  /**
   * One of:
   * - USD
   * - RUB
   * - EUR
   * - UAH
   * - KZT
   * - BRL
   * - TRY
   * - MXN
   * - PLN
   * - HUF
   * - BGN
   * - SEK
   * - JPY
   * - KRW
   * - AUD
   * - NOK
   * - RON
   * - BYN
   * - CAD
   * - UZS
   * Example: USD
   */
  Currency: { input: CommonType.Currency; output: CommonType.Currency };
  /**
   * Any string
   * Example: 9f06ce7cdca9ed9c074d33c4c9
   */
  Cursor: { input: string; output: string };
  /**
   * YYYY-MM-DD
   * Example: 2021-02-04
   */
  Date: { input: string; output: string };
  /**
   * YYYY-MM-DDTHH:mm:ssZ
   * Example: 2021-02-04T12:17:51Z
   */
  DateTime: { input: string; output: string };
  /**
   * Integer value from 0 to 100
   * Example: 77
   */
  Percent: { input: string; output: string };
};

export type ActivateCompanyInput = {
  id: Scalars['ID']['input'];
};

export type ActivateCompanyPayload = {
  __typename?: 'ActivateCompanyPayload';
  company: Company;
};

export type ActivatePartnerInput = {
  id: Scalars['ID']['input'];
};

export type ActivatePartnerPayload = {
  __typename?: 'ActivatePartnerPayload';
  partner: Partner;
};

export type ApplicationToAddPartnersItem = {
  __typename?: 'ApplicationToAddPartnersItem';
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type ApplicationToAddPartnersItemInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type ChangePreferredLanguageInput = {
  id: Scalars['ID']['input'];
  language: Language;
};

export type ChangePreferredLanguagePayload = {
  __typename?: 'ChangePreferredLanguagePayload';
  language: Language;
};

export enum CommonPostbackStatus {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  NotConnected = 'NOT_CONNECTED',
  PartiallyDisconnected = 'PARTIALLY_DISCONNECTED',
}

export type Company = UserInterface & {
  __typename?: 'Company';
  availableCurrencyAccounts: Array<Maybe<CurrencyAccount>>;
  companyPartners: CompanyPartnerConnection;
  contact: Contact;
  cpaStatistic: CompanyCpaStatistic;
  currentCurrencyAccount: CurrencyAccount;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastApplication: CompanyApplication;
  mediaCampaigns: MediaCampaignConnection;
  mediaItem: MediaItemInterface;
  name: Scalars['String']['output'];
  nextRewardInfo: NextRewardInfo;
  passwordUpdatedAt: Scalars['DateTime']['output'];
  preferredLanguage: Language;
  promoCodeMediaItems: PromoCodeMediaItemConnection;
  referralLinkMediaItems: ReferralLinkMediaItemConnection;
  revShareStatistic: CompanyRevShareStatistic;
  rewardHistory: RewardConnection;
  status: CompanyStatus;
  tariff: TariffInterface;
  tariffs: Array<TariffInterface>;
  tariffsForMediaItemCreation: Array<TariffInterface>;
  website?: Maybe<Scalars['String']['output']>;
  withdrawalInvoices: WithdrawalInvoicesConnection;
  withdrawalMethod: WithdrawalMethodInterface;
  withdrawalMethods: Array<WithdrawalMethodInterface>;
};

export type CompanyCompanyPartnersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMediaCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyMediaItemArgs = {
  id: Scalars['ID']['input'];
};

export type CompanyPromoCodeMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyReferralLinkMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyRewardHistoryArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyTariffArgs = {
  id: Scalars['ID']['input'];
};

export type CompanyWithdrawalInvoicesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyWithdrawalMethodArgs = {
  id: Scalars['ID']['input'];
};

export type CompanyApplication = {
  __typename?: 'CompanyApplication';
  contact: Contact;
  declineReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  status: CompanyApplicationStatus;
  trafficType?: Maybe<Scalars['String']['output']>;
};

export enum CompanyApplicationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type CompanyContactInput = {
  type: ContactType;
  value: Scalars['String']['input'];
};

export type CompanyCpaStatistic = {
  __typename?: 'CompanyCpaStatistic';
  statisticItems: CompanyCpaStatisticItemsConnection;
  total: CompanyCpaStatisticInfo;
};

export type CompanyCpaStatisticStatisticItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  end: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Date']['input'];
};

export type CompanyCpaStatisticTotalArgs = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type CompanyCpaStatisticInfo = {
  __typename?: 'CompanyCpaStatisticInfo';
  approved: Scalars['Int']['output'];
  averageDeposit: MoneyAmount;
  conversionHostsToRegPercent: Scalars['Float']['output'];
  conversionRegToDepPercent: Scalars['Float']['output'];
  depositsAll: MoneyAmount;
  firstDeposits: Scalars['Int']['output'];
  hits: Scalars['Int']['output'];
  hold: Scalars['Int']['output'];
  hosts: Scalars['Int']['output'];
  numberOfRedeposits: Scalars['Int']['output'];
  paid: Scalars['Int']['output'];
  qualified: Scalars['Int']['output'];
  redepositsAll: MoneyAmount;
  registrations: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
  reward: MoneyAmount;
  withdrawalsAll: MoneyAmount;
};

export type CompanyCpaStatisticItem = {
  __typename?: 'CompanyCpaStatisticItem';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: CompanyMediaItemOwner;
  statisticInfo: CompanyCpaStatisticInfo;
};

export type CompanyCpaStatisticItemsConnection = {
  __typename?: 'CompanyCpaStatisticItemsConnection';
  edges: Array<CompanyCpaStatisticItemsConnectionEdge>;
  pageInfo: PageInfo;
};

export type CompanyCpaStatisticItemsConnectionEdge = {
  __typename?: 'CompanyCpaStatisticItemsConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: CompanyCpaStatisticItem;
};

export type CompanyMediaItemOwner = Company | CompanyPartner;

export type CompanyPartner = {
  __typename?: 'CompanyPartner';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mediaItemsCount: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type CompanyPartnerConnection = {
  __typename?: 'CompanyPartnerConnection';
  edges: Array<CompanyPartnerConnectionEdge>;
  pageInfo: PageInfo;
};

export type CompanyPartnerConnectionEdge = {
  __typename?: 'CompanyPartnerConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: CompanyPartner;
};

export type CompanyRevShareStatistic = {
  __typename?: 'CompanyRevShareStatistic';
  statisticItems: CompanyRevShareStatisticItemsConnection;
  total: CompanyRevShareStatisticInfo;
};

export type CompanyRevShareStatisticStatisticItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  end: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Date']['input'];
};

export type CompanyRevShareStatisticTotalArgs = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type CompanyRevShareStatisticInfo = {
  __typename?: 'CompanyRevShareStatisticInfo';
  adminFee: MoneyAmount;
  averageDeposit: MoneyAmount;
  conversionHostsToRegPercent: Scalars['Float']['output'];
  conversionRegToDepPercent: Scalars['Float']['output'];
  depositsAll: MoneyAmount;
  depositsFee: MoneyAmount;
  firstDeposits: Scalars['Int']['output'];
  hits: Scalars['Int']['output'];
  hosts: Scalars['Int']['output'];
  numberOfRedeposits: Scalars['Int']['output'];
  redepositsAll: MoneyAmount;
  registrations: Scalars['Int']['output'];
  reward: MoneyAmount;
  withdrawalsAll: MoneyAmount;
  withdrawalsFee: MoneyAmount;
};

export type CompanyRevShareStatisticItem = {
  __typename?: 'CompanyRevShareStatisticItem';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: CompanyMediaItemOwner;
  statisticInfo: CompanyRevShareStatisticInfo;
};

export type CompanyRevShareStatisticItemsConnection = {
  __typename?: 'CompanyRevShareStatisticItemsConnection';
  edges: Array<CompanyRevShareStatisticItemsConnectionEdge>;
  pageInfo: PageInfo;
};

export type CompanyRevShareStatisticItemsConnectionEdge = {
  __typename?: 'CompanyRevShareStatisticItemsConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: CompanyRevShareStatisticItem;
};

export enum CompanyStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export type Contact = {
  __typename?: 'Contact';
  type: ContactType;
  value: Scalars['String']['output'];
};

export enum ContactType {
  Other = 'OTHER',
  Skype = 'SKYPE',
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP',
}

export type CpaReferralLinkStatistic = {
  __typename?: 'CpaReferralLinkStatistic';
  statisticItems: CpaReferralLinkStatisticConnection;
  total: CpaReferralLinkStatisticInfo;
};

export type CpaReferralLinkStatisticStatisticItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  end: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Date']['input'];
};

export type CpaReferralLinkStatisticTotalArgs = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type CpaReferralLinkStatisticConnection = {
  __typename?: 'CpaReferralLinkStatisticConnection';
  edges: Array<CpaReferralLinkStatisticEdge>;
  pageInfo: PageInfo;
};

export type CpaReferralLinkStatisticEdge = {
  __typename?: 'CpaReferralLinkStatisticEdge';
  cursor: Scalars['Cursor']['output'];
  node: CpaReferralLinkStatisticItem;
};

export type CpaReferralLinkStatisticInfo = {
  __typename?: 'CpaReferralLinkStatisticInfo';
  averageDeposit: MoneyAmount;
  depositsAll: MoneyAmount;
  numberOfRedeposits: Scalars['Int']['output'];
  redepositsAll: MoneyAmount;
  wasFD: Scalars['Boolean']['output'];
  withdrawalsAll: MoneyAmount;
};

export type CpaReferralLinkStatisticItem = {
  __typename?: 'CpaReferralLinkStatisticItem';
  id: Scalars['ID']['output'];
  playerName?: Maybe<Scalars['String']['output']>;
  referralStatus: ReferralStatus;
  statisticInfo: CpaReferralLinkStatisticInfo;
};

export type CpaTariff = TariffInterface & {
  __typename?: 'CpaTariff';
  baseline: MoneyAmount;
  createdAt: Scalars['DateTime']['output'];
  geolocation: Array<Scalars['CountryCode']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reward: MoneyAmount;
};

export type CreateApplicationToAddPartnersInput = {
  applicationToAddPartnersItems: Array<ApplicationToAddPartnersItemInput>;
  companyId: Scalars['ID']['input'];
};

export type CreateApplicationToAddPartnersPayload = {
  __typename?: 'CreateApplicationToAddPartnersPayload';
  applicationToAddPartnersItems: Array<ApplicationToAddPartnersItem>;
};

export type CreateCompanyReferralLinkMediaItemInput = {
  companyId: Scalars['ID']['input'];
  companyPartnerId?: InputMaybe<Scalars['ID']['input']>;
  mediaCampaignId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  tariffId: Scalars['ID']['input'];
};

export type CreateCompanyReferralLinkMediaItemPayload = {
  __typename?: 'CreateCompanyReferralLinkMediaItemPayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type CreateGameAccountLinkInput = {
  accountNumber: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type CreateGameAccountLinkPayload = {
  __typename?: 'CreateGameAccountLinkPayload';
  id: Scalars['ID']['output'];
};

export type CreatePartnerReferralLinkMediaItemInput = {
  mediaCampaignId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  partnerId: Scalars['ID']['input'];
  tariffId: Scalars['ID']['input'];
};

export type CreatePartnerReferralLinkMediaItemPayload = {
  __typename?: 'CreatePartnerReferralLinkMediaItemPayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type CreatePostbackInput = {
  event: PostbackEvent;
  mediaItemId: Scalars['ID']['input'];
  method: PostbackMethod;
  url: Scalars['String']['input'];
};

export type CreatePostbackPayload = {
  __typename?: 'CreatePostbackPayload';
  postback: Postback;
};

export type CurrencyAccount = {
  __typename?: 'CurrencyAccount';
  amount?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['Currency']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use currentCurrencyAccount */
  isCurrent: Scalars['Boolean']['output'];
};

export type DateRange = {
  __typename?: 'DateRange';
  end: Scalars['Date']['output'];
  start: Scalars['Date']['output'];
};

export type DeletePostbackInput = {
  id: Scalars['ID']['input'];
};

export type DeletePostbackPayload = {
  __typename?: 'DeletePostbackPayload';
  id: Scalars['ID']['output'];
};

export type GameAccountWithdrawalInvoice = WithdrawalInvoiceInterface & {
  __typename?: 'GameAccountWithdrawalInvoice';
  amount: MoneyAmount;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  method: GameAccountWithdrawalMethod;
  paidAmount?: Maybe<MoneyAmount>;
  status: GameAccountWithdrawalInvoiceStatus;
};

export enum GameAccountWithdrawalInvoiceStatus {
  Error = 'ERROR',
  Executed = 'EXECUTED',
  InProgress = 'IN_PROGRESS',
}

export type GameAccountWithdrawalMethod = WithdrawalMethodInterface & {
  __typename?: 'GameAccountWithdrawalMethod';
  gameAccountNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: GameAccountWithdrawalMethodStatus;
};

export enum GameAccountWithdrawalMethodStatus {
  Confirmed = 'CONFIRMED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
}

export enum Language {
  En = 'EN',
  Ru = 'RU',
}

export type MediaCampaign = {
  __typename?: 'MediaCampaign';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type MediaCampaignConnection = {
  __typename?: 'MediaCampaignConnection';
  edges: Array<MediaCampaignEdge>;
  pageInfo: PageInfo;
};

export type MediaCampaignEdge = {
  __typename?: 'MediaCampaignEdge';
  cursor: Scalars['Cursor']['output'];
  node: MediaCampaign;
};

export type MediaItemInterface = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type MediaItemOwner = Company | CompanyPartner | Partner;

export type MoneyAmount = {
  __typename?: 'MoneyAmount';
  currency: Scalars['Currency']['output'];
  value: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCompany: ActivateCompanyPayload;
  activatePartner: ActivatePartnerPayload;
  changePreferredLanguage: ChangePreferredLanguagePayload;
  createApplicationToAddPartners: CreateApplicationToAddPartnersPayload;
  createCompanyReferralLinkMediaItem: CreateCompanyReferralLinkMediaItemPayload;
  createGameAccountLink: CreateGameAccountLinkPayload;
  createPartnerReferralLinkMediaItem: CreatePartnerReferralLinkMediaItemPayload;
  createPostback: CreatePostbackPayload;
  deletePostback: DeletePostbackPayload;
  reapplyCompanyApplication: ReapplyCompanyApplicationPayload;
  reapplyPartnerApplication: ReapplyPartnerApplicationPayload;
  registerCompany: RegisterCompanyPayload;
  registerPartner: RegisterPartnerPayload;
  resetUserPassword: ResetUserPasswordPayload;
  switchCompanyCurrentCurrencyAccount: SwitchCompanyCurrentCurrencyAccountPayload;
  switchCompanyNextRewardCurrency: SwitchCompanyNextRewardCurrencyPayload;
  switchPartnerCurrentCurrencyAccount: SwitchPartnerCurrentCurrencyAccountPayload;
  switchPartnerNextRewardCurrency: SwitchPartnerNextRewardCurrencyPayload;
  updateCompanyIdentities: UpdateCompanyIdentitiesPayload;
  updatePartnerIdentities: UpdatePartnerIdentitiesPayload;
  updatePostback: UpdatePostbackPayload;
  withdrawToGameAccountFromCurrentCurrencyAccount: WithdrawToGameAccountFromCurrentCurrencyAccountPayload;
};

export type MutationActivateCompanyArgs = {
  input: ActivateCompanyInput;
};

export type MutationActivatePartnerArgs = {
  input: ActivatePartnerInput;
};

export type MutationChangePreferredLanguageArgs = {
  input: ChangePreferredLanguageInput;
};

export type MutationCreateApplicationToAddPartnersArgs = {
  input: CreateApplicationToAddPartnersInput;
};

export type MutationCreateCompanyReferralLinkMediaItemArgs = {
  input: CreateCompanyReferralLinkMediaItemInput;
};

export type MutationCreateGameAccountLinkArgs = {
  input: CreateGameAccountLinkInput;
};

export type MutationCreatePartnerReferralLinkMediaItemArgs = {
  input: CreatePartnerReferralLinkMediaItemInput;
};

export type MutationCreatePostbackArgs = {
  input: CreatePostbackInput;
};

export type MutationDeletePostbackArgs = {
  input: DeletePostbackInput;
};

export type MutationReapplyCompanyApplicationArgs = {
  input: ReapplyCompanyApplicationInput;
};

export type MutationReapplyPartnerApplicationArgs = {
  input: ReapplyPartnerApplicationInput;
};

export type MutationRegisterCompanyArgs = {
  input: RegisterCompanyInput;
};

export type MutationRegisterPartnerArgs = {
  input: RegisterPartnerInput;
};

export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type MutationSwitchCompanyCurrentCurrencyAccountArgs = {
  input: SwitchCompanyCurrentCurrencyAccountInput;
};

export type MutationSwitchCompanyNextRewardCurrencyArgs = {
  input: SwitchCompanyNextRewardCurrencyInput;
};

export type MutationSwitchPartnerCurrentCurrencyAccountArgs = {
  input: SwitchPartnerCurrentCurrencyAccountInput;
};

export type MutationSwitchPartnerNextRewardCurrencyArgs = {
  input: SwitchPartnerNextRewardCurrencyInput;
};

export type MutationUpdateCompanyIdentitiesArgs = {
  input: UpdateCompanyIdentitiesInput;
};

export type MutationUpdatePartnerIdentitiesArgs = {
  input: UpdatePartnerIdentitiesInput;
};

export type MutationUpdatePostbackArgs = {
  input: UpdatePostbackInput;
};

export type MutationWithdrawToGameAccountFromCurrentCurrencyAccountArgs = {
  input: WithdrawToGameAccountFromCurrentCurrencyAccountInput;
};

export type NextRewardInfo = {
  __typename?: 'NextRewardInfo';
  currency: Scalars['Currency']['output'];
  period: DateRange;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['Cursor']['output'];
};

export type Partner = UserInterface & {
  __typename?: 'Partner';
  availableCurrencyAccounts: Array<Maybe<CurrencyAccount>>;
  birthday?: Maybe<Scalars['Date']['output']>;
  contact: Contact;
  cpaStatistic: PartnerCpaStatistic;
  currentCurrencyAccount: CurrencyAccount;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastApplication: PartnerApplication;
  lastName?: Maybe<Scalars['String']['output']>;
  mediaCampaigns: MediaCampaignConnection;
  mediaItem: MediaItemInterface;
  nextRewardInfo: NextRewardInfo;
  passwordUpdatedAt: Scalars['DateTime']['output'];
  preferredLanguage: Language;
  promoCodeMediaItems: PromoCodeMediaItemConnection;
  referralLinkMediaItems: ReferralLinkMediaItemConnection;
  revShareStatistic: PartnerRevShareStatistic;
  rewardHistory: RewardConnection;
  status: PartnerStatus;
  tariff: TariffInterface;
  tariffs: Array<TariffInterface>;
  tariffsForMediaItemCreation: Array<TariffInterface>;
  username: Scalars['String']['output'];
  withdrawalInvoices: WithdrawalInvoicesConnection;
  withdrawalMethod: WithdrawalMethodInterface;
  withdrawalMethods: Array<WithdrawalMethodInterface>;
};

export type PartnerMediaCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerMediaItemArgs = {
  id: Scalars['ID']['input'];
};

export type PartnerPromoCodeMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerReferralLinkMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerRewardHistoryArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerTariffArgs = {
  id: Scalars['ID']['input'];
};

export type PartnerWithdrawalInvoicesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerWithdrawalMethodArgs = {
  id: Scalars['ID']['input'];
};

export type PartnerApplication = {
  __typename?: 'PartnerApplication';
  contact: Contact;
  declineReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  status: PartnerApplicationStatus;
  trafficType?: Maybe<Scalars['String']['output']>;
};

export enum PartnerApplicationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type PartnerContactInput = {
  type: ContactType;
  value: Scalars['String']['input'];
};

export type PartnerCpaStatistic = {
  __typename?: 'PartnerCpaStatistic';
  statisticItems: PartnerCpaStatisticItemsConnection;
  total: PartnerCpaStatisticInfo;
};

export type PartnerCpaStatisticStatisticItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  end: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Date']['input'];
};

export type PartnerCpaStatisticTotalArgs = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type PartnerCpaStatisticInfo = {
  __typename?: 'PartnerCpaStatisticInfo';
  approved: Scalars['Int']['output'];
  averageDeposit: MoneyAmount;
  conversionHostsToRegPercent: Scalars['Float']['output'];
  conversionRegToDepPercent: Scalars['Float']['output'];
  depositsAll: MoneyAmount;
  firstDeposits: Scalars['Int']['output'];
  hits: Scalars['Int']['output'];
  hold: Scalars['Int']['output'];
  hosts: Scalars['Int']['output'];
  numberOfRedeposits: Scalars['Int']['output'];
  paid: Scalars['Int']['output'];
  qualified: Scalars['Int']['output'];
  redepositsAll: MoneyAmount;
  registrations: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
  reward: MoneyAmount;
  withdrawalsAll: MoneyAmount;
};

export type PartnerCpaStatisticItem = {
  __typename?: 'PartnerCpaStatisticItem';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  statisticInfo: PartnerCpaStatisticInfo;
};

export type PartnerCpaStatisticItemsConnection = {
  __typename?: 'PartnerCpaStatisticItemsConnection';
  edges: Array<PartnerCpaStatisticItemsConnectionEdge>;
  pageInfo: PageInfo;
};

export type PartnerCpaStatisticItemsConnectionEdge = {
  __typename?: 'PartnerCpaStatisticItemsConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: PartnerCpaStatisticItem;
};

export type PartnerRevShareStatistic = {
  __typename?: 'PartnerRevShareStatistic';
  statisticItems: PartnerRevShareStatisticItemsConnection;
  total: PartnerRevShareStatisticInfo;
};

export type PartnerRevShareStatisticStatisticItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  end: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Date']['input'];
};

export type PartnerRevShareStatisticTotalArgs = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type PartnerRevShareStatisticInfo = {
  __typename?: 'PartnerRevShareStatisticInfo';
  adminFee: MoneyAmount;
  averageDeposit: MoneyAmount;
  conversionHostsToRegPercent: Scalars['Float']['output'];
  conversionRegToDepPercent: Scalars['Float']['output'];
  depositsAll: MoneyAmount;
  depositsFee: MoneyAmount;
  firstDeposits: Scalars['Int']['output'];
  hits: Scalars['Int']['output'];
  hosts: Scalars['Int']['output'];
  numberOfRedeposits: Scalars['Int']['output'];
  redepositsAll: MoneyAmount;
  registrations: Scalars['Int']['output'];
  reward: MoneyAmount;
  withdrawalsAll: MoneyAmount;
  withdrawalsFee: MoneyAmount;
};

export type PartnerRevShareStatisticItem = {
  __typename?: 'PartnerRevShareStatisticItem';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  statisticInfo: PartnerRevShareStatisticInfo;
};

export type PartnerRevShareStatisticItemsConnection = {
  __typename?: 'PartnerRevShareStatisticItemsConnection';
  edges: Array<PartnerRevShareStatisticItemsConnectionEdge>;
  pageInfo: PageInfo;
};

export type PartnerRevShareStatisticItemsConnectionEdge = {
  __typename?: 'PartnerRevShareStatisticItemsConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: PartnerRevShareStatisticItem;
};

export enum PartnerStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export enum PaymentModel {
  Cpa = 'CPA',
  RevShare = 'REV_SHARE',
}

export type Postback = {
  __typename?: 'Postback';
  event: PostbackEvent;
  id: Scalars['ID']['output'];
  isConnected: Scalars['Boolean']['output'];
  method: PostbackMethod;
  url: Scalars['String']['output'];
};

export enum PostbackEvent {
  FirstDeposit = 'FIRST_DEPOSIT',
  QualificationCheck = 'QUALIFICATION_CHECK',
  Registration = 'REGISTRATION',
  Reward = 'REWARD',
}

export type PostbackEvents = {
  __typename?: 'PostbackEvents';
  availableEvents: Array<PostbackEvent>;
  totalEventsCount: Scalars['Int']['output'];
};

export type PostbackInfo = {
  __typename?: 'PostbackInfo';
  commonPostbackStatus: CommonPostbackStatus;
  events: PostbackEvents;
  postbacks?: Maybe<Array<Postback>>;
};

export enum PostbackMacros {
  ClickId = 'CLICK_ID',
  Currency = 'CURRENCY',
  Reward = 'REWARD',
  Tid = 'TID',
}

export enum PostbackMethod {
  Get = 'GET',
  Post = 'POST',
}

export type PromoCodeMediaItem = MediaItemInterface & {
  __typename?: 'PromoCodeMediaItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  owner: MediaItemOwner;
  referralLink: Scalars['String']['output'];
  tariffName: Scalars['String']['output'];
  token: Scalars['String']['output'];
  type: PromoCodeMediaItemType;
};

export type PromoCodeMediaItemConnection = {
  __typename?: 'PromoCodeMediaItemConnection';
  edges: Array<PromoCodeMediaItemConnectionEdge>;
  pageInfo: PageInfo;
};

export type PromoCodeMediaItemConnectionEdge = {
  __typename?: 'PromoCodeMediaItemConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: PromoCodeMediaItem;
};

export enum PromoCodeMediaItemType {
  AllPlayers = 'ALL_PLAYERS',
  MyPlayers = 'MY_PLAYERS',
  NewPlayers = 'NEW_PLAYERS',
}

export type Query = {
  __typename?: 'Query';
  availableCurrencies: Array<Scalars['Currency']['output']>;
  currentRewardPeriod: DateRange;
  postbackMacrosByPostbackEvent: Array<PostbackMacros>;
  previousRewardPeriod: DateRange;
  user: UserInterface;
};

export type QueryPostbackMacrosByPostbackEventArgs = {
  postbackEvent: PostbackEvent;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type ReapplyCompanyApplicationInput = {
  companyId: Scalars['ID']['input'];
  contact: CompanyContactInput;
  trafficType?: InputMaybe<Scalars['String']['input']>;
};

export type ReapplyCompanyApplicationPayload = {
  __typename?: 'ReapplyCompanyApplicationPayload';
  application: CompanyApplication;
};

export type ReapplyPartnerApplicationInput = {
  contact: PartnerContactInput;
  partnerId: Scalars['ID']['input'];
  trafficType?: InputMaybe<Scalars['String']['input']>;
};

export type ReapplyPartnerApplicationPayload = {
  __typename?: 'ReapplyPartnerApplicationPayload';
  application: PartnerApplication;
};

export type ReferralLinkMediaItem = MediaItemInterface & {
  __typename?: 'ReferralLinkMediaItem';
  cpaStatistic: CpaReferralLinkStatistic;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: MediaItemOwner;
  postbackInfo: PostbackInfo;
  revShareStatistic: RevShareReferralLinkStatistic;
  tariff: TariffInterface;
};

export type ReferralLinkMediaItemConnection = {
  __typename?: 'ReferralLinkMediaItemConnection';
  edges: Array<ReferralLinkMediaItemConnectionEdge>;
  pageInfo: PageInfo;
};

export type ReferralLinkMediaItemConnectionEdge = {
  __typename?: 'ReferralLinkMediaItemConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: ReferralLinkMediaItem;
};

export enum ReferralStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  CpaPaid = 'CPA_PAID',
  CpaUnpaid = 'CPA_UNPAID',
  Hold = 'HOLD',
  Qualified = 'QUALIFIED',
  Rejected = 'REJECTED',
}

export type RegisterCompanyInput = {
  companyName: Scalars['String']['input'];
  contact: CompanyContactInput;
  currency: Scalars['Currency']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  trafficType?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterCompanyPayload = {
  __typename?: 'RegisterCompanyPayload';
  company: Company;
};

export type RegisterPartnerInput = {
  contact: PartnerContactInput;
  currency: Scalars['Currency']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  trafficType?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type RegisterPartnerPayload = {
  __typename?: 'RegisterPartnerPayload';
  partner: Partner;
};

export type ResetUserPasswordInput = {
  currentEmail: Scalars['String']['input'];
  setPasswordPath: Scalars['String']['input'];
};

export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload';
  isSuccessful: Scalars['Boolean']['output'];
};

export type RevShareReferralLinkStatistic = {
  __typename?: 'RevShareReferralLinkStatistic';
  statisticItems: RevShareReferralLinkStatisticConnection;
  total: RevShareReferralLinkStatisticInfo;
};

export type RevShareReferralLinkStatisticStatisticItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  end: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Date']['input'];
};

export type RevShareReferralLinkStatisticTotalArgs = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type RevShareReferralLinkStatisticConnection = {
  __typename?: 'RevShareReferralLinkStatisticConnection';
  edges: Array<RevShareReferralLinkStatisticEdge>;
  pageInfo: PageInfo;
};

export type RevShareReferralLinkStatisticEdge = {
  __typename?: 'RevShareReferralLinkStatisticEdge';
  cursor: Scalars['Cursor']['output'];
  node: RevShareReferralLinkStatisticItem;
};

export type RevShareReferralLinkStatisticInfo = {
  __typename?: 'RevShareReferralLinkStatisticInfo';
  adminFee: MoneyAmount;
  averageDeposit: MoneyAmount;
  depositsAll: MoneyAmount;
  depositsFee: MoneyAmount;
  numberOfRedeposits: Scalars['Int']['output'];
  redepositsAll: MoneyAmount;
  wasFD: Scalars['Boolean']['output'];
  withdrawalsAll: MoneyAmount;
  withdrawalsFee: MoneyAmount;
};

export type RevShareReferralLinkStatisticItem = {
  __typename?: 'RevShareReferralLinkStatisticItem';
  id: Scalars['ID']['output'];
  playerName?: Maybe<Scalars['String']['output']>;
  statisticInfo: RevShareReferralLinkStatisticInfo;
};

export type RevShareTariff = TariffInterface & {
  __typename?: 'RevShareTariff';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rewardPercent: Scalars['Percent']['output'];
};

export type Reward = {
  __typename?: 'Reward';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  paid: MoneyAmount;
  paymentModel: PaymentModel;
};

export type RewardConnection = {
  __typename?: 'RewardConnection';
  edges: Array<RewardConnectionEdge>;
  pageInfo: PageInfo;
};

export type RewardConnectionEdge = {
  __typename?: 'RewardConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: Reward;
};

export type SwitchCompanyCurrentCurrencyAccountInput = {
  companyId: Scalars['ID']['input'];
  currency: Scalars['Currency']['input'];
};

export type SwitchCompanyCurrentCurrencyAccountPayload = {
  __typename?: 'SwitchCompanyCurrentCurrencyAccountPayload';
  company: Company;
};

export type SwitchCompanyNextRewardCurrencyInput = {
  companyId: Scalars['ID']['input'];
  currency: Scalars['Currency']['input'];
};

export type SwitchCompanyNextRewardCurrencyPayload = {
  __typename?: 'SwitchCompanyNextRewardCurrencyPayload';
  company: Company;
};

export type SwitchPartnerCurrentCurrencyAccountInput = {
  currency: Scalars['Currency']['input'];
  partnerId: Scalars['ID']['input'];
};

export type SwitchPartnerCurrentCurrencyAccountPayload = {
  __typename?: 'SwitchPartnerCurrentCurrencyAccountPayload';
  partner: Partner;
};

export type SwitchPartnerNextRewardCurrencyInput = {
  currency: Scalars['Currency']['input'];
  partnerId: Scalars['ID']['input'];
};

export type SwitchPartnerNextRewardCurrencyPayload = {
  __typename?: 'SwitchPartnerNextRewardCurrencyPayload';
  partner: Partner;
};

export type TariffInterface = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UpdateCompanyIdentitiesInput = {
  contact: CompanyContactInput;
  id: Scalars['ID']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyIdentitiesPayload = {
  __typename?: 'UpdateCompanyIdentitiesPayload';
  company: Company;
};

export type UpdatePartnerIdentitiesInput = {
  birthday?: InputMaybe<Scalars['Date']['input']>;
  contact: PartnerContactInput;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerIdentitiesPayload = {
  __typename?: 'UpdatePartnerIdentitiesPayload';
  partner: Partner;
};

export type UpdatePostbackInput = {
  method: PostbackMethod;
  postbackId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type UpdatePostbackPayload = {
  __typename?: 'UpdatePostbackPayload';
  postback: Postback;
};

export type UserInterface = {
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  preferredLanguage: Language;
};

export type WithdrawToGameAccountFromCurrentCurrencyAccountInput = {
  amount: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export type WithdrawToGameAccountFromCurrentCurrencyAccountPayload = {
  __typename?: 'WithdrawToGameAccountFromCurrentCurrencyAccountPayload';
  invoice: GameAccountWithdrawalInvoice;
};

export type WithdrawalInvoiceInterface = {
  amount: MoneyAmount;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  method: WithdrawalMethodInterface;
  paidAmount?: Maybe<MoneyAmount>;
};

export type WithdrawalInvoicesConnection = {
  __typename?: 'WithdrawalInvoicesConnection';
  edges: Array<WithdrawalInvoicesConnectionEdge>;
  pageInfo: PageInfo;
};

export type WithdrawalInvoicesConnectionEdge = {
  __typename?: 'WithdrawalInvoicesConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: WithdrawalInvoiceInterface;
};

export type WithdrawalMethodInterface = {
  id: Scalars['ID']['output'];
};

export const ListAllOperations = {
  Query: {
    GetCompanyPartners: 'GetCompanyPartners',
    GetCompanyPartnersForMedia: 'GetCompanyPartnersForMedia',
    GetMediaCampaignsForMedia: 'GetMediaCampaignsForMedia',
    GetTariffsForMediaItemCreation: 'GetTariffsForMediaItemCreation',
    GetMacrosByEvent: 'GetMacrosByEvent',
    GetPostbackInfo: 'GetPostbackInfo',
    GetPromoCodeMediaItems: 'GetPromoCodeMediaItems',
    GetMediaItem: 'GetMediaItem',
    GetReferralLinkMediaItems: 'GetReferralLinkMediaItems',
    GetRewardHistory: 'GetRewardHistory',
    GetWithdrawalInvoices: 'GetWithdrawalInvoices',
    GetAvailableCurrencyAccounts: 'GetAvailableCurrencyAccounts',
    GetNextRewardInfo: 'GetNextRewardInfo',
    GetWithdrawalMethod: 'GetWithdrawalMethod',
    GetWithdrawalMethods: 'GetWithdrawalMethods',
    GetUserIdentities: 'GetUserIdentities',
    GetCpaStatisticDetailed: 'GetCpaStatisticDetailed',
    GetCpaStatisticDetailedTotal: 'GetCpaStatisticDetailedTotal',
    GetRevShareStatisticDetailed: 'GetRevShareStatisticDetailed',
    GetRevShareStatisticDetailedTotal: 'GetRevShareStatisticDetailedTotal',
    GetStatisticDetailedItemName: 'GetStatisticDetailedItemName',
    GetCompanyCpaStatisticItems: 'GetCompanyCpaStatisticItems',
    GetCompanyCpaStatisticTotal: 'GetCompanyCpaStatisticTotal',
    GetPartnerCpaStatisticItems: 'GetPartnerCpaStatisticItems',
    GetPartnerCpaStatisticTotal: 'GetPartnerCpaStatisticTotal',
    GetCompanyRevShareStatisticItems: 'GetCompanyRevShareStatisticItems',
    GetCompanyRevShareStatisticTotal: 'GetCompanyRevShareStatisticTotal',
    GetPartnerRevShareStatisticItems: 'GetPartnerRevShareStatisticItems',
    GetPartnerRevShareStatisticTotal: 'GetPartnerRevShareStatisticTotal',
    GetBasicTariffs: 'GetBasicTariffs',
    GetTariff: 'GetTariff',
    GetAvailableCurrencies: 'GetAvailableCurrencies',
    GetCompanyName: 'GetCompanyName',
    GetCurrentCurrencyAccount: 'GetCurrentCurrencyAccount',
    GetCurrentRewardPeriod: 'GetCurrentRewardPeriod',
    GetPreferredLanguage: 'GetPreferredLanguage',
    GetUserLastApplication: 'GetUserLastApplication',
    GetUserStatus: 'GetUserStatus',
    GetUserTypeName: 'GetUserTypeName',
  },
  Mutation: {
    ActivateCompany: 'ActivateCompany',
    ActivatePartner: 'ActivatePartner',
    CreateApplicationToAddPartners: 'CreateApplicationToAddPartners',
    CreateCompanyReferralLinkMediaItem: 'CreateCompanyReferralLinkMediaItem',
    CreatePartnerReferralLinkMediaItem: 'CreatePartnerReferralLinkMediaItem',
    CreatePostback: 'CreatePostback',
    DeletePostback: 'DeletePostback',
    UpdatePostback: 'UpdatePostback',
    ResetUserPassword: 'ResetUserPassword',
    SwitchCompanyCurrentCurrencyAccount: 'SwitchCompanyCurrentCurrencyAccount',
    SwitchPartnerCurrentCurrencyAccount: 'SwitchPartnerCurrentCurrencyAccount',
    SwitchCompanyNextRewardCurrency: 'SwitchCompanyNextRewardCurrency',
    SwitchPartnerNextRewardCurrency: 'SwitchPartnerNextRewardCurrency',
    WithdrawToGameAccountFromCurrentCurrencyAccount:
      'WithdrawToGameAccountFromCurrentCurrencyAccount',
    CreateGameAccountLink: 'CreateGameAccountLink',
    UpdateCompanyIdentities: 'UpdateCompanyIdentities',
    UpdatePartnerIdentities: 'UpdatePartnerIdentities',
    ReapplyCompanyApplication: 'ReapplyCompanyApplication',
    ReapplyPartnerApplication: 'ReapplyPartnerApplication',
    RegisterCompany: 'RegisterCompany',
    RegisterPartner: 'RegisterPartner',
  },
  Fragment: {
    CompanyPartnersData: 'CompanyPartnersData',
    MediaCampaignConnectionData: 'MediaCampaignConnectionData',
    PostbackInfoData: 'PostbackInfoData',
    PromoCodeMediaItemData: 'PromoCodeMediaItemData',
    PromoCodeMediaItemsConnectionData: 'PromoCodeMediaItemsConnectionData',
    ReferralLinkMediaItemBasicData: 'ReferralLinkMediaItemBasicData',
    ReferralLinkMediaItemConnectionData: 'ReferralLinkMediaItemConnectionData',
    ReferralLinkMediaItemData: 'ReferralLinkMediaItemData',
    TariffForMediaBasicData: 'TariffForMediaBasicData',
    TariffForMediaData: 'TariffForMediaData',
    RewardHistoryConnectionData: 'RewardHistoryConnectionData',
    RewardHistoryItemData: 'RewardHistoryItemData',
    WithdrawalInvoiceData: 'WithdrawalInvoiceData',
    WithdrawalInvoicesConnectionData: 'WithdrawalInvoicesConnectionData',
    AvailableCurrencyAccountsData: 'AvailableCurrencyAccountsData',
    WithdrawalMethodsData: 'WithdrawalMethodsData',
    CpaReferralLinkStatisticConnectionData:
      'CpaReferralLinkStatisticConnectionData',
    CpaReferralLinkStatisticData: 'CpaReferralLinkStatisticData',
    CpaReferralLinkStatisticInfoData: 'CpaReferralLinkStatisticInfoData',
    CpaReferralLinkStatisticItemData: 'CpaReferralLinkStatisticItemData',
    CpaReferralLinkStatisticTotalData: 'CpaReferralLinkStatisticTotalData',
    RevShareReferralLinkStatisticConnectionData:
      'RevShareReferralLinkStatisticConnectionData',
    RevShareReferralLinkStatisticData: 'RevShareReferralLinkStatisticData',
    RevShareReferralLinkStatisticInfoData:
      'RevShareReferralLinkStatisticInfoData',
    RevShareReferralLinkStatisticItemData:
      'RevShareReferralLinkStatisticItemData',
    RevShareReferralLinkStatisticTotalData:
      'RevShareReferralLinkStatisticTotalData',
    CompanyCpaStatisticInfoData: 'CompanyCpaStatisticInfoData',
    CompanyCpaStatisticItemData: 'CompanyCpaStatisticItemData',
    PartnerCpaStatisticInfoData: 'PartnerCpaStatisticInfoData',
    PartnerCpaStatisticItemData: 'PartnerCpaStatisticItemData',
    CompanyRevShareStatisticInfoData: 'CompanyRevShareStatisticInfoData',
    CompanyRevShareStatisticItemData: 'CompanyRevShareStatisticItemData',
    PartnerRevShareStatisticInfoData: 'PartnerRevShareStatisticInfoData',
    PartnerRevShareStatisticItemData: 'PartnerRevShareStatisticItemData',
    TariffBasicData: 'TariffBasicData',
    TariffData: 'TariffData',
    MoneyAmountData: 'MoneyAmountData',
    PageInfoData: 'PageInfoData',
  },
};
