import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export const getOnErrorLink = (logOut: () => Promise<boolean>): ApolloLink => {
  const logOutLink = onError(({ networkError }): void => {
    if (networkError) {
      // todo: add error toast
      // eslint-disable-next-line no-console
      console.error(networkError);
    }

    if (
      networkError?.name === 'ServerError' &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      logOut();
    }
  });

  return logOutLink;
};
