import {
  components,
  GroupBase,
  MenuListProps as ReactSelectMenuListProps,
} from 'react-select';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { SelectOption } from '../../type';
import { LoadingItem } from './components/LoadingItem';
import { LoadingError } from './components/LoadingError';
import styles from './MenuList.module.scss';

type MenuListProps = ReactSelectMenuListProps<
  SelectOption,
  false,
  GroupBase<SelectOption>
>;

export const MenuList = ({ children, ...rest }: MenuListProps) => {
  const {
    isLoading,
    isLoadingError,
    loadingErrorCallback,
    customOnMenuScrollToBottom,
  } = rest.selectProps;

  const onScrollToBottomTrigger = useInfiniteScroll(customOnMenuScrollToBottom);

  return (
    <components.MenuList<SelectOption, false, GroupBase<SelectOption>>
      className={styles.menuList}
      {...rest}
    >
      {children && !isLoadingError && <div>{children}</div>}
      {customOnMenuScrollToBottom && onScrollToBottomTrigger}

      {isLoading && <LoadingItem />}
      {!isLoading && isLoadingError && (
        <LoadingError loadingErrorCallback={loadingErrorCallback} />
      )}
    </components.MenuList>
  );
};
