import { useAuth } from 'components/auth';
import { MediaItemTypeName } from 'types';
import { useGetMediaItem } from '../../../queries/generated/GetMediaItem';

interface UseGetMediaItemQuery {
  mediaItemId: string;
}

export const useGetReferralLinkMediaItemQuery = ({
  mediaItemId,
}: UseGetMediaItemQuery) => {
  const { user } = useAuth();
  const { data, loading, error } = useGetMediaItem({
    variables: {
      userId: user.id,
      mediaItemId,
    },
  });

  const mediaItem =
    data?.user.mediaItem.__typename === MediaItemTypeName.ReferralLinkMediaItem
      ? data.user.mediaItem
      : undefined;

  return {
    mediaItem,
    loading,
    error,
  };
};
