import { t } from 'i18n';
import { PromoCodeMediaItemType } from 'types/generated/gql';
import { TranslationUserType } from 'types';

export const mapPromoCodeTypeToText = (
  promoCodeType: PromoCodeMediaItemType,
  translationUserType: TranslationUserType
): string => {
  const map: Record<PromoCodeMediaItemType, string> = {
    [PromoCodeMediaItemType.AllPlayers]: t(
      `media:${translationUserType}.content.drawer.details.promoCode.about.value.promoTypes.allPlayers`
    ),
    [PromoCodeMediaItemType.MyPlayers]: t(
      `media:${translationUserType}.content.drawer.details.promoCode.about.value.promoTypes.myPlayers`
    ),
    [PromoCodeMediaItemType.NewPlayers]: t(
      `media:${translationUserType}.content.drawer.details.promoCode.about.value.promoTypes.newPlayers`
    ),
  };

  return map[promoCodeType];
};
