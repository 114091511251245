import { createContext } from 'react';
import { ConfirmPopupContextValue } from '../types';

const defaultContextValue: ConfirmPopupContextValue = {
  isOpen: false,
  closeModal: () => undefined,
  showConfirmation: () => Promise.reject(),
  handleConfirm: () => undefined,
  handleCancel: () => undefined,
};

export const ConfirmModalContext =
  createContext<ConfirmPopupContextValue>(defaultContextValue);
