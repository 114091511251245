import { useCallback } from 'react';
import { ListAllOperations } from 'types/generated/gql';
import { toast } from 'ui/toast';
import { useCreatePostback } from '../../../queries/generated/CreatePostback';
import { PostbackValues } from '../../../types';

interface UseCreatePostbackMutationParams {
  mediaItemId: string;
  onSendRequest?: () => void;
  onCompleted?: () => void;
  onError?: () => void;
}

export const useCreatePostbackMutation = ({
  mediaItemId,
  onSendRequest,
  onCompleted,
  onError,
}: UseCreatePostbackMutationParams) => {
  const [createPostbackMutation] = useCreatePostback({
    refetchQueries: [ListAllOperations.Query.GetPostbackInfo],
    awaitRefetchQueries: true,
  });

  const createPostback = useCallback(
    ({ event, method, url }: PostbackValues) => {
      if (!event || !method || !url) {
        return undefined;
      }

      onSendRequest?.();

      return createPostbackMutation({
        variables: {
          input: {
            mediaItemId,
            event,
            method,
            url,
          },
        },
        onError: (error) => {
          toast.error({
            header: 'Error',
            text: error.message,
          });

          onError?.();
        },

        onCompleted,
      });
    },
    [createPostbackMutation, mediaItemId, onCompleted, onError, onSendRequest]
  );

  return {
    createPostback,
  };
};
