import 'react-toastify/dist/ReactToastify.css';
import { FC } from 'react';
import { Breakpoints } from 'const';
import { getMinimalUnderBreakpoint } from 'helpers';
import { ToastContainer as ToastifyContainer } from 'react-toastify';

import styles from './ToastContainer.module.scss';

export const ToastContainer: FC = () => (
  <ToastifyContainer
    className={styles.toastContainer}
    autoClose={10000}
    pauseOnHover={false}
    draggable={false}
    closeButton
    closeOnClick={false}
    icon={false}
    position={
      getMinimalUnderBreakpoint() === Breakpoints.Sm
        ? 'top-center'
        : 'bottom-right'
    }
    limit={5}
  />
);
