import { Resources } from 'i18n/types';

import commonRU from 'locales/ru/common.json';
import authRU from 'locales/ru/auth.json';
import companyPartnersRU from 'locales/ru/companyPartners.json';
import mediaRU from 'locales/ru/media.json';
import profileRU from 'locales/ru/profile.json';
import statisticRU from 'locales/ru/statistic.json';
import tariffsRU from 'locales/ru/tariffs.json';
import notFoundRU from 'locales/ru/notFound.json';
import paymentsRU from 'locales/ru/payments.json';

export const resourcesRu = {
  common: commonRU,
  auth: authRU,
  companyPartners: companyPartnersRU,
  media: mediaRU,
  profile: profileRU,
  statistic: statisticRU,
  tariffs: tariffsRU,
  notFound: notFoundRU,
  payments: paymentsRU,
} satisfies Resources;
