import { useEffect, useState, useMemo, RefObject } from 'react';
import { Column } from '@tanstack/react-table';

interface UseColumnWidthParams<T> {
  columns: Array<Column<T, unknown>>;
  tableContainerRef: RefObject<HTMLDivElement>;
}

/*
  Temporary column width calculation due to limitations with
  `table-layout: fixed;` where `minWidth` and `maxWidth` are not
  respected in Safari and Mozilla browsers. This approach recalculates
  widths on each window resize, which may impact performance.

  Will be refactored in https://eyeconweb.atlassian.net/browse/AMS-1726
 */
export const useColumnWidths = <T>({
  columns,
  tableContainerRef,
}: UseColumnWidthParams<T>) => {
  const [tableMaxWidth, setTableMaxWidth] = useState<number>(0);

  const fixedWidth = useMemo(
    () =>
      columns.reduce(
        (totalFixedWidth, { columnDef }) =>
          totalFixedWidth + (columnDef.size ?? 0),
        0
      ),
    [columns]
  );

  const autoColumnsCount = useMemo(
    () => columns.filter(({ columnDef }) => !columnDef.size).length,
    [columns]
  );

  const calculateWidths = useMemo(
    () =>
      columns.map(({ columnDef }) => {
        const { minSize, maxSize, size } = columnDef;

        const flexibleWidth = maxSize
          ? `clamp(${minSize}px, calc((${tableMaxWidth}px - ${fixedWidth}px) / ${autoColumnsCount}), ${maxSize}px)`
          : `max(${minSize}px, calc((${tableMaxWidth}px - ${fixedWidth}px) / ${autoColumnsCount}))`;

        return size ? `${size}px` : flexibleWidth;
      }),
    [columns, tableMaxWidth, fixedWidth, autoColumnsCount]
  );

  useEffect(() => {
    const updateMaxWidth = () => {
      if (tableContainerRef.current) {
        setTableMaxWidth(tableContainerRef.current.clientWidth);
      }
    };

    updateMaxWidth();
    window.addEventListener('resize', updateMaxWidth);

    return () => {
      window.removeEventListener('resize', updateMaxWidth);
    };
  }, [tableContainerRef]);

  return { columnWidths: calculateWidths };
};
