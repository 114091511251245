import { BaseStorageManager } from '../BaseStorageManager';
import { AuthStorageKey } from './const';

export class AuthStorage extends BaseStorageManager<AuthStorageKey> {
  clearAll = () => {
    Object.values(AuthStorageKey).forEach((key) => {
      this.removeValue(key);
    });
  };
}
