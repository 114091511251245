import { FC } from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'ui/Button';
import { FormBlockMarginSize, FormBlockWrapper, Input } from 'ui/formItems';
import { Tooltip } from 'ui/Tooltip';
import { TwoFactorValues } from './types';
import { validateTwoFactor } from './validation';
import { FieldName } from './const';
import { useCheckTwoFactor } from './hooks/useCheckTwoFactor';

const initialValues: TwoFactorValues = {
  code: '',
};

interface TwoFactorSendFormProps {
  buttonText: string;
  disabledButtonTooltip: string;
  inputLabel: string;
}

export const TwoFactorSendForm: FC<TwoFactorSendFormProps> = ({
  buttonText,
  disabledButtonTooltip,
  inputLabel,
}) => {
  const { checkTwoFactor } = useCheckTwoFactor();

  const handleSubmit = (values: TwoFactorValues) => {
    checkTwoFactor(values.code);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateTwoFactor}
      validateOnChange={false}
    >
      {({ dirty, isValid }) => (
        <Form>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Large}>
            <Input
              acceptedCharsRegExp={/[0-9]/}
              name={FieldName.Code}
              label={inputLabel}
              inputMode="numeric"
            />
          </FormBlockWrapper>
          <Tooltip
            shouldSetWidthFitContent={false}
            placement="right"
            tooltipContent={
              !isValid || !dirty ? disabledButtonTooltip : undefined
            }
          >
            <Button disabled={!isValid || !dirty} isFullWidth type="submit">
              {buttonText}
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
