import { t } from 'i18n';
import { ReferralStatus } from 'types/generated/gql';
import { TranslationUserType } from 'types';
import { CpaReferralLinkStatisticConnectionData } from './query/fragments/generated/CpaReferralLinkStatisticConnectionData';
import { CpaReferralLinkStatisticItemData } from './query/fragments/generated/CpaReferralLinkStatisticItemData';

export const extractDetailedStatisticItems = (
  cpaStatisticDetailedConnectionData?: CpaReferralLinkStatisticConnectionData
): Array<CpaReferralLinkStatisticItemData> | undefined =>
  cpaStatisticDetailedConnectionData?.edges.map((item) => item.node);

export const getReferralStatusToString = (
  translationUserType: TranslationUserType
): Record<ReferralStatus, string> => ({
  [ReferralStatus.Approved]: t(
    `statistic:${translationUserType}.content.dataTable.cpa.referralStatus.approved`
  ),
  [ReferralStatus.CpaPaid]: t(
    `statistic:${translationUserType}.content.dataTable.cpa.referralStatus.cpaPaid`
  ),
  [ReferralStatus.CpaUnpaid]: t(
    `statistic:${translationUserType}.content.dataTable.cpa.referralStatus.cpaUnpaid`
  ),
  [ReferralStatus.Hold]: t(
    `statistic:${translationUserType}.content.dataTable.cpa.referralStatus.hold`
  ),
  [ReferralStatus.Qualified]: t(
    `statistic:${translationUserType}.content.dataTable.cpa.referralStatus.qualified`
  ),
  [ReferralStatus.Rejected]: t(
    `statistic:${translationUserType}.content.dataTable.cpa.referralStatus.rejected`
  ),
  [ReferralStatus.Canceled]: t(
    `statistic:${translationUserType}.content.dataTable.cpa.referralStatus.canceled`
  ),
});
