import { useCallback, useRef, useState } from 'react';

export const useModalControl = () => {
  const [isOpen, setIsOpen] = useState(false);

  const confirmResolverRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const showConfirmation = useCallback((): Promise<boolean> => {
    setIsOpen(true);

    return new Promise((resolve) => {
      confirmResolverRef.current = resolve;
    });
  }, []);

  return {
    isOpen,
    showConfirmation,
    closeModal,
    confirmResolver: confirmResolverRef.current,
  };
};
