import { List, ListSize } from 'ui/List';
import { DropdownWithButton } from 'ui/DropdownWithButton';
import { DropdownPosition } from 'ui/Dropdown';
import { Currency } from 'types';
import { blurActiveElement } from 'helpers/blurActiveElement';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { CurrencyAccount } from 'types/generated/gql';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { Skeleton } from 'ui/Skeleton';
import { useLanguage } from 'contexts/LanguageContext';
import { useSwitchCurrentCurrencyAccount } from './hooks/useSwitchCurrentCurrencyAccount';
import {
  getDropdownStateByCurrency,
  getListItemsByCurrencies,
} from './helpers';
import styles from './CurrencyAccountDropdown.module.scss';

interface CurrencyAccountDropdownProps {
  loading: boolean;
  currentCurrencyAccount?: CurrencyAccount | null;
  availableCurrencyAccounts?: Array<CurrencyAccount | null>;
}

export const CurrencyAccountDropdown = ({
  currentCurrencyAccount,
  availableCurrencyAccounts,
  loading: availableCurrencyAccountsLoading = false,
}: CurrencyAccountDropdownProps) => {
  const { t } = useTranslation(['common', 'payments']);
  const { currentLanguage } = useLanguage();

  const dropdownButton =
    currentCurrencyAccount?.currency &&
    getDropdownStateByCurrency(currentCurrencyAccount?.currency);

  const {
    switchCurrentCurrencyAccount,
    loading: changeCurrencyPaymentLoading,
  } = useSwitchCurrentCurrencyAccount();

  const disabled =
    availableCurrencyAccountsLoading ||
    !availableCurrencyAccounts?.length ||
    !currentCurrencyAccount?.currency ||
    availableCurrencyAccounts.length === 1 ||
    changeCurrencyPaymentLoading;

  const shouldShowTooltip =
    !availableCurrencyAccountsLoading &&
    availableCurrencyAccounts?.length === 1;

  const handleItemClick = (currency?: Currency) => {
    if (currency === currentCurrencyAccount?.currency) {
      blurActiveElement();

      return;
    }

    if (currency) {
      switchCurrentCurrencyAccount(currency);
      blurActiveElement();
    }
  };

  if (availableCurrencyAccountsLoading || !dropdownButton) {
    return <Skeleton width={121} height={32} />;
  }

  return (
    <div className={styles.wrapper}>
      <Tooltip
        placement="bottom"
        tooltipTheme={TooltipTheme.Dark}
        tooltipContent={
          shouldShowTooltip
            ? t(
                'payments:partnerAndCompany.content.widget.balance.content.hint'
              )
            : undefined
        }
        shouldSetWidthFitContent={false}
      >
        <DropdownWithButton
          dropdownProps={{
            position: DropdownPosition.RightBottom,
            title: t('input.label.currency'),
          }}
          buttonContent={
            <div className={styles.innerButtonContent}>
              {dropdownButton.icon && dropdownButton.icon}
              {getCurrencyNameWithSymbol(dropdownButton.value)}
            </div>
          }
          buttonProps={{
            disabled,
          }}
        >
          <List
            items={getListItemsByCurrencies({
              onClick: handleItemClick,
              availableCurrencyAccounts,
              language: currentLanguage,
            })}
            size={ListSize.Small}
            highlightOnHover
          />
        </DropdownWithButton>
      </Tooltip>
    </div>
  );
};
