type DateRange = [Date | null, Date | null];

export const normalizeDateRange = (dateRange: DateRange): DateRange => {
  const [start, end] = dateRange;

  if (start && !end) {
    return [start, start];
  }

  if (!start && end) {
    return [end, end];
  }

  return dateRange;
};
