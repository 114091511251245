import { useAuth } from 'components/auth';
import { ReapplicationValues } from '../types';
import { useReapplyCompanyApplication } from '../../../queries/generated/ReapplyCompanyApplication';
import { useReapplyMutationHandlers } from './useReapplyMutationHandlers';

export const useReapplyCompanyApplicationMutation = () => {
  const { user } = useAuth();
  const { handleCompleted, handleError } = useReapplyMutationHandlers();

  const [reapplyApplicationMutation, { loading }] =
    useReapplyCompanyApplication();

  const reapplyApplication = ({
    contactValue,
    contactType,
    trafficSource,
  }: ReapplicationValues) => {
    reapplyApplicationMutation({
      variables: {
        input: {
          companyId: user.id,
          trafficType:
            !trafficSource || trafficSource === '' ? null : trafficSource,
          contact: {
            type: contactType,
            value: contactValue,
          },
        },
      },
      onCompleted: handleCompleted,
      onError: handleError,
    });
  };

  return {
    reapplyApplication,
    loading,
  };
};
