import { FC } from 'react';
import { useLoadMore } from 'hooks/useLoadMore';
import { Table } from 'ui/Table';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { EmptyDataBlock } from 'components/EmptyDataBlock';
import { useTranslation } from 'react-i18next';
import { UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useOpenModal } from '../../../../hooks/useOpenModal';
import { ReferralLinkMediaItemBasicData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { useGetReferralLinkMediaItemsQuery } from './hooks/useGetReferralLinkMediaItemsQuery';
import { useReferralLinkMediaTableColumns } from './hooks/useReferralLinkMediaTableColumns';

export const ReferralLinkMediaTable: FC = () => {
  const { openEditModal } = useOpenModal();
  const { t } = useTranslation('media');

  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  const {
    mediaItems,
    pageInfo,
    fetchMore,
    isSliced,
    error: getMediaItemsError,
    loading: getMediaItemsLoading,
  } = useGetReferralLinkMediaItemsQuery();

  const columns = useReferralLinkMediaTableColumns({
    isCompany: userTypeName === UserTypeName.Company,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const handleRowClick = ({ id }: ReferralLinkMediaItemBasicData) => {
    openEditModal(id);
  };

  const isLoading = (!mediaItems && getMediaItemsLoading) || getUserTypeLoading;
  const error = getUserTypeError || getMediaItemsError;

  if (isLoading) {
    return <Loader shouldExcludeMenuWidth />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!mediaItems || mediaItems.length === 0) {
    return <EmptyDataBlock emptyDataText={t('placeholder.links.noData')} />;
  }

  return (
    <Table
      onClickOnRow={handleRowClick}
      columns={columns}
      data={mediaItems}
      isLoading={getMediaItemsLoading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      error={getMediaItemsError}
      fetchMore={loadMore}
    />
  );
};
