import { Action, Modal, ModalState } from './types';
import { ModalAction } from './const';

export const reducer = (
  currentState: ModalState,
  action: Action
): ModalState => {
  switch (action.type) {
    case ModalAction.Open: {
      const newModal: Modal = {
        ...action.payload,
        isCloseDisabled: false,
        confirmClose: null,
      };

      return {
        ...currentState,
        modals: [...currentState.modals, newModal],
      };
    }

    case ModalAction.Close: {
      return {
        ...currentState,
        modals: [],
      };
    }

    case ModalAction.GoBack: {
      const updatedModals = currentState.modals.slice(0, -1);

      return {
        ...currentState,
        modals: updatedModals,
      };
    }

    case ModalAction.DisableClose: {
      const updatedModals = [...currentState.modals];
      const lastModalIndex = updatedModals.length - 1;

      if (lastModalIndex !== undefined) {
        updatedModals[lastModalIndex] = {
          ...updatedModals[lastModalIndex],
          isCloseDisabled: action.payload.isCloseDisabled,
        };
      }

      return {
        ...currentState,
        modals: updatedModals,
      };
    }

    case ModalAction.UpdateConfirmClose: {
      const updatedModals = [...currentState.modals];
      const lastModalIndex = updatedModals.length - 1;

      if (lastModalIndex !== undefined) {
        updatedModals[lastModalIndex] = {
          ...updatedModals[lastModalIndex],
          confirmClose: action.payload.confirmClose,
        };
      }

      return {
        ...currentState,
        modals: updatedModals,
      };
    }

    default:
      return currentState;
  }
};
