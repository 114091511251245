import { FC } from 'react';
import { TopBar } from 'components/TopBar';
import { ReactComponent as CalculatorIcon } from 'assets/icons/colors/Calculator.svg';
import { useTranslation } from 'react-i18next';
import { TariffsBlock } from 'pages/Tariffs/components/TariffsBlock';

export const Tariffs: FC = () => {
  const { t } = useTranslation('tariffs');

  return (
    <>
      <TopBar title={t('content.header.header')} icon={CalculatorIcon} />
      <TariffsBlock />
    </>
  );
};
