import { useNavigate } from 'react-router';
import { ApolloError } from '@apollo/client';
import { AppRoute } from 'const';
import { toast } from 'ui/toast';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';

export const useReapplyMutationHandlers = () => {
  const navigate = useNavigate();
  const { createLocalizedPath } = useCreateLocalizedPath();

  const handleCompleted = () => {
    navigate(createLocalizedPath(AppRoute.Lang.Application));
  };

  const handleError = (error: ApolloError) => {
    toast.error({
      header: 'Error',
      text: error.message,
      toastId: 'reapplyApplicationError',
    });
  };

  return {
    handleCompleted,
    handleError,
  };
};
