/**
 * When using Apollo's `relayStylePagination` with `fetchPolicy: 'cache-and-network'`,
 * there's a known issue where the UI initially displays all cached items,
 * even if we're only requesting a subset of them. This can cause a "jump" in the UI,
 * as it first shows all cached items, then updates to show only the requested items
 * once the network request completes.
 *
 * This hook is a workaround for that issue. It slices the data to the desired amount
 * on the first page, preventing the UI "jump".
 *
 * See more information https://github.com/apollographql/apollo-client/issues/11087
 */

import * as Apollo from '@apollo/client';
import { useState, useMemo, useCallback } from 'react';
import { sliceEdgesDeep } from '../helpers';
import { FetchMoreOptions } from '../types';

export interface PaginationDataParams<TData> {
  originalData: TData | undefined;
  originalFetchMore: <TFetchData, TFetchVariables>(
    fetchMoreOptions: FetchMoreOptions<TFetchVariables, TFetchData>
  ) => Promise<Apollo.ApolloQueryResult<TFetchData>>;
  perPage?: number;
  shouldSlice: boolean;
}

export const usePaginationData = <TData>({
  originalData,
  originalFetchMore,
  perPage,
  shouldSlice,
}: PaginationDataParams<TData>) => {
  const [isSliced, setIsSliced] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(true);

  const data = useMemo(() => {
    if (!shouldSlice || !originalData || !isFirstPage || !perPage) {
      setIsSliced(false);

      return originalData;
    }

    try {
      const slicedData = sliceEdgesDeep(originalData, perPage);

      setIsSliced(slicedData !== originalData);

      return slicedData;
    } catch {
      return originalData;
    }
  }, [isFirstPage, originalData, perPage, shouldSlice]);

  const interceptedFetchMore = useCallback<
    <TFetchData, TFetchVariables>(
      fetchMoreOptions: FetchMoreOptions<TFetchVariables, TFetchData>
    ) => Promise<Apollo.ApolloQueryResult<TFetchData>> | undefined
  >(
    (fetchMoreOptions) => {
      if (isSliced) {
        return undefined;
      }

      setIsFirstPage(false);

      return originalFetchMore(fetchMoreOptions);
    },
    [isSliced, originalFetchMore]
  );

  return {
    data,
    fetchMore: interceptedFetchMore,
    isSliced,
  };
};
