import { useEffect, useState } from 'react';
import { SCROLL_LOCKED_CLASS_NAME } from 'const';

export const useMenuDrawer = () => {
  const [isOpen, setIsOpened] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(SCROLL_LOCKED_CLASS_NAME);
    } else {
      document.body.classList.remove(SCROLL_LOCKED_CLASS_NAME);
    }

    return () => {
      document.body.classList.remove(SCROLL_LOCKED_CLASS_NAME);
    };
  }, [isOpen]);

  const toggleDrawer = () => {
    setIsOpened((prevState) => !prevState);
  };

  const closeDrawer = () => {
    setIsOpened(false);
  };

  return {
    isOpen,
    closeDrawer,
    toggleDrawer,
  };
};
