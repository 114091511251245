import { t } from 'i18n';
import { FormikErrors } from 'formik';
import { Currency } from 'types';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { WithdrawToGameAccountFormValues } from './types';

interface CreateValidateWithdrawToGameAccountFormFnParams {
  currency: Currency;
  currentAmount: number;
}

export const createValidateWithdrawToGameAccountFormFn =
  ({
    currency,
    currentAmount,
  }: CreateValidateWithdrawToGameAccountFormFnParams) =>
  (
    values: WithdrawToGameAccountFormValues
  ): FormikErrors<WithdrawToGameAccountFormValues> => {
    const errors: FormikErrors<WithdrawToGameAccountFormValues> = {};

    if (Number(values.amount) > currentAmount) {
      errors.amount = t('errors.insufficientFundsError');
    }

    if (Number(values.amount) < 1) {
      errors.amount = t(
        'payments:partnerAndCompany.content.drawer.vavadaAccount.input.validation.invalidAmountMinimum',
        {
          currency,
        }
      );
    }

    const requiredErrors = getRequiredErrors(values, {
      amount: true,
      accountNumber: true,
    });

    return { ...errors, ...requiredErrors };
  };
