import { FC } from 'react';
import { Placement } from '@floating-ui/react';
import { ErrorMessage } from 'formik';
import { Tooltip } from '../../../Tooltip';
import { TooltipTheme } from '../../../Tooltip/const';
import { useFormValidation } from './hooks/useFormValidation';

export interface FormValidationWrapperProps {
  name: string;
  errorTooltipPlacement?: Placement;
  horizontalOffset?: number;
}

export const FormValidationWrapper: FC<
  FormValidationWrapperProps & React.HTMLAttributes<HTMLElement>
> = ({ name, children, errorTooltipPlacement, horizontalOffset }) => {
  const { isTooltipVisible, handleBlur, handleFocus } = useFormValidation(name);

  return (
    <div onFocus={handleFocus} onBlur={handleBlur}>
      <Tooltip
        placement={errorTooltipPlacement}
        isOpen={isTooltipVisible}
        tooltipContent={<ErrorMessage name={name} />}
        tooltipTheme={TooltipTheme.Light}
        shouldSetWidthFitContent={false}
        horizontalOffset={horizontalOffset}
      >
        {children}
      </Tooltip>
    </div>
  );
};
