import { t } from 'i18n';
import { PostbackMacros } from 'types/generated/gql';
import { TranslationUserType } from 'types';

export const mapMacroToValue: Record<PostbackMacros, string> = {
  [PostbackMacros.ClickId]: '{click_id}',
  [PostbackMacros.Reward]: '{reward}',
  [PostbackMacros.Currency]: '{currency}',
  [PostbackMacros.Tid]: '{tid}',
};

export const mapMacroToDescription = (
  macro: PostbackMacros,
  translationUserType: TranslationUserType
): string => {
  const map: Record<PostbackMacros, string> = {
    [PostbackMacros.ClickId]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.select.subtitle1`
    ),
    [PostbackMacros.Currency]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.select.subtitle4`
    ),
    [PostbackMacros.Reward]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.select.subtitle3`
    ),
    [PostbackMacros.Tid]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.select.subtitle2`
    ),
  };

  return map[macro];
};
