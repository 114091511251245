import { useMemo } from 'react';
import { UserTypeName } from 'types';
import { useAuth } from 'components/auth';
import { useGetCompanyPartners } from '../queries/generated/GetCompanyPartners';
import { CompanyPartnersData } from '../queries/fragments/generated/CompanyPartnersData';
import { COMPANY_PARTNERS_ITEMS_PER_PAGE } from '../const';

export const useGetCompanyPartnersQuery = () => {
  const { user } = useAuth();

  const { data, fetchMore, error, loading, isSliced } = useGetCompanyPartners({
    variables: {
      userId: user.id,
      first: COMPANY_PARTNERS_ITEMS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const companyPartnersConnection =
    data?.user.__typename === UserTypeName.Company
      ? data.user.companyPartners
      : undefined;

  const companyPartnersItems: Array<CompanyPartnersData> | undefined = useMemo(
    () => companyPartnersConnection?.edges.map((item) => item.node),
    [companyPartnersConnection?.edges]
  );

  const pageInfo = companyPartnersConnection?.pageInfo;

  return {
    companyPartnersItems,
    pageInfo,
    fetchMore,
    error,
    loading,
    isSliced,
  };
};
