import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdatePostbackVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdatePostbackInput;
}>;

export type UpdatePostback = {
  __typename: 'Mutation';
  updatePostback: {
    __typename: 'UpdatePostbackPayload';
    postback: {
      __typename: 'Postback';
      event: SchemaTypes.PostbackEvent;
      id: string;
      isConnected: boolean;
      method: SchemaTypes.PostbackMethod;
      url: string;
    };
  };
};

export const UpdatePostbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePostback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePostbackInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePostback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postback' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'event' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isConnected' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'method' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdatePostbackMutationFn = Apollo.MutationFunction<
  UpdatePostback,
  UpdatePostbackVariables
>;

/**
 * __useUpdatePostback__
 *
 * To run a mutation, you first call `useUpdatePostback` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostback` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostback, { data, loading, error }] = useUpdatePostback({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePostback(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePostback,
    UpdatePostbackVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePostback, UpdatePostbackVariables>(
    UpdatePostbackDocument,
    options
  );
}
export type UpdatePostbackHookResult = ReturnType<typeof useUpdatePostback>;
export type UpdatePostbackMutationResult =
  Apollo.MutationResult<UpdatePostback>;
export type UpdatePostbackMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostback,
  UpdatePostbackVariables
>;
