import { FC } from 'react';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { NonNullableDateRange, UserTypeName } from 'types';
import { CompanyCpaStatisticTable } from './components/CompanyCpaStatisticTable';
import { PartnerCpaStatisticTable } from './components/PartnerCpaStatisticTable';

interface CpaStatisticTableProps {
  nonNullableDateRange: NonNullableDateRange;
}

export const CpaStatisticTable: FC<CpaStatisticTableProps> = ({
  nonNullableDateRange,
}) => {
  const { userTypeName } = useGetUserTypeNameQuery();

  return userTypeName === UserTypeName.Company ? (
    <CompanyCpaStatisticTable nonNullableDateRange={nonNullableDateRange} />
  ) : (
    <PartnerCpaStatisticTable nonNullableDateRange={nonNullableDateRange} />
  );
};
