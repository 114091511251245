import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { useWithdrawToGameAccountFromCurrentCurrencyAccount } from '../queries/generated/WithdrawToGameAccountFromCurrentCurrencyAccount';

interface UseWithdrawToGameAccountMutationParams {
  onWithdrawalCreated: () => void;
}

export const useWithdrawToGameAccountFromCurrentCurrencyAccountMutation = ({
  onWithdrawalCreated,
}: UseWithdrawToGameAccountMutationParams) => {
  const { t } = useTranslation(['common', 'payments']);

  const handleCompleted = () => {
    onWithdrawalCreated();
    toast.success(
      t('payments:partnerAndCompany.content.withdrawal.notice.vavadaAccount')
    );
  };

  const handleError = () => {
    toast.error(t('errors.commonError'));
  };

  const [withdrawToGameAccount, { loading }] =
    useWithdrawToGameAccountFromCurrentCurrencyAccount({
      onCompleted: handleCompleted,
      onError: handleError,
    });

  return { withdrawToGameAccount, loading };
};
