import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateGameAccountLinkVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateGameAccountLinkInput;
}>;

export type CreateGameAccountLink = {
  __typename: 'Mutation';
  createGameAccountLink: {
    __typename: 'CreateGameAccountLinkPayload';
    id: string;
  };
};

export const CreateGameAccountLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGameAccountLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateGameAccountLinkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGameAccountLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateGameAccountLinkMutationFn = Apollo.MutationFunction<
  CreateGameAccountLink,
  CreateGameAccountLinkVariables
>;

/**
 * __useCreateGameAccountLink__
 *
 * To run a mutation, you first call `useCreateGameAccountLink` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameAccountLink` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameAccountLink, { data, loading, error }] = useCreateGameAccountLink({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameAccountLink(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateGameAccountLink,
    CreateGameAccountLinkVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateGameAccountLink,
    CreateGameAccountLinkVariables
  >(CreateGameAccountLinkDocument, options);
}
export type CreateGameAccountLinkHookResult = ReturnType<
  typeof useCreateGameAccountLink
>;
export type CreateGameAccountLinkMutationResult =
  Apollo.MutationResult<CreateGameAccountLink>;
export type CreateGameAccountLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateGameAccountLink,
  CreateGameAccountLinkVariables
>;
