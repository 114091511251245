import { FC } from 'react';
import classNames from 'classnames';
import { ReactComponent as IconSpinner } from 'assets/icons/solid/Loader.svg';
import { Icon } from 'ui/Icon';
import styles from './Loader.module.scss';

interface LoaderProps {
  shouldExcludeMenuWidth?: boolean;
}

export const Loader: FC<LoaderProps> = ({ shouldExcludeMenuWidth }) => (
  <div
    data-testid="loader"
    className={classNames(
      shouldExcludeMenuWidth && styles.withExcludedMenuWidth,
      styles.loaderContainer
    )}
  >
    <Icon iconComponent={IconSpinner} spin />
  </div>
);
