import { toast as toastifyToast } from 'react-toastify';
import { ToastButton, ToastMethods } from './types';
import { ToastContent } from './components/ToastContent';

type ToastFunction = (params: string | ToastParams) => void;

interface ToastParams {
  header: string;
  text?: string;
  button?: ToastButton;
  /**
   * If toastId is passed, then only one toast with the given id is shown at that one moment.
   * If is not passed, many toasts can be shown at the same time
   */
  toastId?: string;
}

const createToast =
  (method: ToastMethods) =>
  (params: string | ToastParams): void => {
    const { header, text, button, toastId }: ToastParams =
      typeof params === 'string' ? { header: params } : params;

    toastifyToast[method](
      <ToastContent header={header} text={text} button={button} />,
      {
        toastId,
      }
    );
  };

export const toast: Record<ToastMethods, ToastFunction> = {
  success: createToast('success'),
  error: createToast('error'),
  info: createToast('info'),
};
