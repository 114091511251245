import { UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useSwitchPartnerCurrentCurrencyAccountMutation } from './useSwitchPartnerCurrentCurrencyAccountMutation';
import { useSwitchCompanyCurrentCurrencyAccountMutation } from './useSwitchCompanyCurrentCurrencyAccountMutation';

export const useSwitchCurrentCurrencyAccount = () => {
  const { userTypeName } = useGetUserTypeNameQuery();

  const useSwitchCurrentCurrencyAccountByUserType =
    userTypeName === UserTypeName.Partner
      ? useSwitchPartnerCurrentCurrencyAccountMutation
      : useSwitchCompanyCurrentCurrencyAccountMutation;

  return useSwitchCurrentCurrencyAccountByUserType();
};
