export enum TransactionStatusLabelType {
  Executed = 'completed',
  InProgress = 'inProgress',
  Error = 'error',
  ExecutedPartially = 'executedPartially',
}

export enum TransactionStatusLabelSize {
  Large = 'large',
  Small = 'small',
}
