import { FC } from 'react';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { Icon, IconSize } from 'ui/Icon';
import { ReactComponent as CopyIcon } from 'assets/icons/line/Copy.svg';
import { TextLink } from 'ui/TextLink';
import { LinkProps } from 'react-router-dom';
import { useHandleCopyWithTooltipStatus } from 'hooks/useHandleCopyWithTooltipStatus';
import styles from './CopyLink.module.scss';

export interface CopyLinkProps extends LinkProps {
  children: string;
  tooltipText: string;
  iconSize?: IconSize.Large | IconSize.Medium;
}

export const CopyLink: FC<CopyLinkProps> = ({
  children,
  tooltipText,
  iconSize = IconSize.Medium,
  ...linkProps
}) => {
  const { handleClick: handleCopyButtonClick, isTooltipVisible } =
    useHandleCopyWithTooltipStatus({
      text: children,
    });

  return (
    <div className={styles.mainContainer}>
      <div className={styles.ellipsisWrapper}>
        <TextLink {...linkProps} className={styles.copyLinkButton}>
          {children}
        </TextLink>
      </div>

      <Tooltip
        isOpen={isTooltipVisible}
        tooltipTheme={TooltipTheme.Light}
        tooltipContent={tooltipText}
        placement="top"
      >
        <button
          onClick={handleCopyButtonClick}
          type="button"
          className={styles.button}
        >
          <Icon
            containerClassName={styles.icon}
            hasDefaultColor={false}
            iconComponent={CopyIcon}
            size={iconSize}
          />
        </button>
      </Tooltip>
    </div>
  );
};
