import { useTranslation } from 'react-i18next';
import { ReactComponent as RewardIcon } from 'assets/icons/solid/Reward.svg';
import { CardWithIcon } from 'ui/CardWithIcon';
import { useNextRewardCurrencyModal } from './hooks/useNextRewardCurrencyModal';
import { useGetNextRewardInfoQuery } from './hooks/useGetNextRewardInfoQuery';
import { NextRewardCardBottomContent } from './components/NextRewardCardBottomContent';

interface NextRewardCardProps {
  className?: string;
}

export const NextRewardCard = ({ className }: NextRewardCardProps) => {
  const { t } = useTranslation('payments');

  const { nextRewardInfo, loading, error, refetch } =
    useGetNextRewardInfoQuery();
  const { openNextRewardCurrencyModal } = useNextRewardCurrencyModal({
    nextRewardInfo,
  });

  return (
    <CardWithIcon
      className={className}
      title={t('partnerAndCompany.content.widget.nextReward.header.title')}
      subTitle={
        <NextRewardCardBottomContent
          loading={loading}
          isError={!!error}
          onRefetch={refetch}
          currency={nextRewardInfo?.currency}
        />
      }
      onClick={openNextRewardCurrencyModal}
      icon={RewardIcon}
    />
  );
};
