import { Currency, CurrencySymbols } from 'types';

export const mapCurrencyToSymbol: Record<Currency, CurrencySymbols> = {
  [Currency.USD]: CurrencySymbols.USD,
  [Currency.RUB]: CurrencySymbols.RUB,
  [Currency.EUR]: CurrencySymbols.EUR,
  [Currency.UAH]: CurrencySymbols.UAH,
  [Currency.KZT]: CurrencySymbols.KZT,
  [Currency.BRL]: CurrencySymbols.BRL,
  [Currency.TRY]: CurrencySymbols.TRY,
  [Currency.MXN]: CurrencySymbols.MXN,
  [Currency.PLN]: CurrencySymbols.PLN,
  [Currency.HUF]: CurrencySymbols.HUF,
  [Currency.BGN]: CurrencySymbols.BGN,
  [Currency.SEK]: CurrencySymbols.SEK,
  [Currency.JPY]: CurrencySymbols.JPY,
  [Currency.KRW]: CurrencySymbols.KRW,
  [Currency.AUD]: CurrencySymbols.AUD,
  [Currency.NOK]: CurrencySymbols.NOK,
  [Currency.RON]: CurrencySymbols.RON,
  [Currency.BYN]: CurrencySymbols.BYN,
  [Currency.CAD]: CurrencySymbols.CAD,
  [Currency.UZS]: CurrencySymbols.UZS,
};
