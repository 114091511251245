import { TransactionStatusLabelType } from 'components/TransactionStatusLabel';
import { GameAccountWithdrawalInvoiceStatus } from 'types/generated/gql';

export const mapGameAccountWithdrawalInvoiceStatusToLabelType: Record<
  GameAccountWithdrawalInvoiceStatus,
  TransactionStatusLabelType
> = {
  [GameAccountWithdrawalInvoiceStatus.Executed]:
    TransactionStatusLabelType.Executed,
  [GameAccountWithdrawalInvoiceStatus.InProgress]:
    TransactionStatusLabelType.InProgress,
  [GameAccountWithdrawalInvoiceStatus.Error]: TransactionStatusLabelType.Error,
};
