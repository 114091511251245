import { AuthStatuses, useAuth } from 'components/auth';
import { AppRoute } from 'const';
import { FC } from 'react';
import { Navigate, Outlet, Path, useLocation } from 'react-router';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';

export const RedirectByUserStatusWrapper: FC = () => {
  const { user } = useAuth();
  const { createLocalizedPath } = useCreateLocalizedPath();

  const { authStatus } = user;

  const location = useLocation();

  const redirectPathIfAuthenticated =
    (location.state as Path) || createLocalizedPath(AppRoute.Lang.Main);

  if (authStatus === AuthStatuses.Authenticated) {
    return <Navigate to={redirectPathIfAuthenticated} replace />;
  }

  if (
    authStatus === AuthStatuses.TwoFactorAuthenticationRequired ||
    authStatus === AuthStatuses.TwoFactorGenerationRequired
  ) {
    return (
      <Navigate to={createLocalizedPath(AppRoute.Lang.TwoFactor)} replace />
    );
  }

  return <Outlet />;
};
