import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { useGetWithdrawalInvoices } from '../queries/generated/GetWithdrawalInvoices';
import { ITEMS_PER_PAGE } from '../const';
import { WithdrawalInvoiceData } from '../queries/fragments/generated/WithdrawalInvoiceData';

export const useGetWithdrawalInvoicesQuery = () => {
  const { user } = useAuth();

  const { data, fetchMore, loading, error } = useGetWithdrawalInvoices({
    variables: {
      first: ITEMS_PER_PAGE,
      userId: user.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const connection = data?.user.withdrawalInvoices;

  const withdrawalInvoices: Array<WithdrawalInvoiceData> | undefined = useMemo(
    () => connection?.edges.map((item) => item.node),
    [connection?.edges]
  );

  const pageInfo = connection?.pageInfo;

  return {
    withdrawalInvoices,
    fetchMore,
    pageInfo,
    loading,
    error,
  };
};
