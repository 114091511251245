import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/Button';
import styles from './LoadingError.module.scss';

interface LoadingErrorProps {
  loadingErrorCallback?: () => void;
}

export const LoadingError: FC<LoadingErrorProps> = ({
  loadingErrorCallback,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.loadingError}>
      {loadingErrorCallback ? (
        <>
          <span className={styles.textBeforeButton}>
            {t('errors.failedToLoadData')}
          </span>
          <Button onClick={loadingErrorCallback} linkStyled>
            {t('progress.refresh')}
          </Button>
        </>
      ) : (
        <span>{t('errors.failedToLoadData')}</span>
      )}
    </div>
  );
};
