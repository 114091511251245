export enum EditTab {
  Detail = 'detail',
  Tariff = 'tariff',
  Postbacks = 'postbacks',
}

export const companyEditTypeArray = [
  EditTab.Detail,
  EditTab.Tariff,
  EditTab.Postbacks,
];

export const partnerEditTypeArray = [
  EditTab.Detail,
  EditTab.Tariff,
  EditTab.Postbacks,
];
