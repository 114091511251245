import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetUserTypeNameVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetUserTypeName = {
  __typename: 'Query';
  user:
    | { __typename: 'Company'; id: string }
    | { __typename: 'Partner'; id: string };
};

export const GetUserTypeNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserTypeName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUserTypeName__
 *
 * To run a query within a React component, call `useGetUserTypeName` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTypeName` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTypeName({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserTypeName(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUserTypeName,
    GetUserTypeNameVariables
  > &
    (
      | { variables: GetUserTypeNameVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUserTypeName, GetUserTypeNameVariables>(
    GetUserTypeNameDocument,
    options
  );
}
export function useGetUserTypeNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserTypeName,
    GetUserTypeNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUserTypeName,
    GetUserTypeNameVariables
  >(GetUserTypeNameDocument, options);
}
export function useGetUserTypeNameSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetUserTypeName,
    GetUserTypeNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetUserTypeName,
    GetUserTypeNameVariables
  >(GetUserTypeNameDocument, options);
}
export type GetUserTypeNameHookResult = ReturnType<typeof useGetUserTypeName>;
export type GetUserTypeNameLazyQueryHookResult = ReturnType<
  typeof useGetUserTypeNameLazyQuery
>;
export type GetUserTypeNameSuspenseQueryHookResult = ReturnType<
  typeof useGetUserTypeNameSuspenseQuery
>;
export type GetUserTypeNameQueryResult = Apollo.QueryResult<
  GetUserTypeName,
  GetUserTypeNameVariables
>;
