import { useCallback, useState } from 'react';

/**
 * useEditingIds is a hook for managing a set of element IDs that are currently being edited.
 *
 * @returns {object} An object containing:
 * - isEditInProcess: A boolean indicating if any elements are currently being edited.
 * - editingElementsIds: IDs of elements that are currently being edited,
 * - updateEditingElementsIds: A function to add or remove an element's ID from the editing set.
 * - clearEditingElementsIds: A function to clear all IDs from the editing set.
 */
export const useEditingIds = () => {
  const [editingElementsIds, setEditingElementsIds] = useState<Set<string>>(
    new Set()
  );

  const updateEditingElementsIds = useCallback(
    (id: string, isElementEditing: boolean) => {
      setEditingElementsIds((prev) => {
        if (isElementEditing && !prev.has(id)) {
          const updated = new Set(prev);

          updated.add(id);

          return updated;
        }

        if (!isElementEditing && prev.has(id)) {
          const updated = new Set(prev);

          updated.delete(id);

          return updated;
        }

        return prev;
      });
    },
    []
  );

  const clearEditingElementsIds = useCallback(() => {
    if (editingElementsIds.size !== 0) {
      setEditingElementsIds(new Set());
    }
  }, [editingElementsIds.size]);

  return {
    isEditInProcess: !!editingElementsIds.size,
    editingElementsIds,
    updateEditingElementsIds,
    clearEditingElementsIds,
  };
};
