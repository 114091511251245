import type { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { AppRoute } from 'const';
import { Icon } from '../../../Icon';
import { MenuItemData } from '../../types';
import { checkIsLink } from './helpers';
import styles from './MenuItem.module.scss';

interface MenuItemProps {
  menuItem: MenuItemData;
  onClick?: () => void;
}
export const MenuItem: FC<MenuItemProps> = ({ menuItem, onClick }) => {
  const contentToRender = (
    <>
      <Icon
        containerClassName={styles.iconContainer}
        iconComponent={menuItem.icon}
        hasDefaultColor={false}
      />
      {menuItem.text}
    </>
  );

  if (checkIsLink(menuItem)) {
    return (
      <NavLink
        data-testid="menu-item"
        className={({ isActive }) =>
          classNames(styles.menuItem, isActive && styles.active)
        }
        to={menuItem.link}
        onClick={(e) => {
          if (menuItem.link === AppRoute.Lang.EmptyRoute) {
            e.preventDefault();

            return;
          }

          onClick?.();
        }}
      >
        {contentToRender}
      </NavLink>
    );
  }

  return (
    <button
      data-testid="menu-item"
      className={styles.menuItem}
      onClick={menuItem.onClick}
      type="button"
    >
      {contentToRender}
    </button>
  );
};
