import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { RewardHistoryItemData } from './RewardHistoryItemData';
import { PageInfoData } from '../../../../../../../../../queries/fragments/generated/PageInfoData';
export type RewardHistoryConnectionData = {
  __typename: 'RewardConnection';
  edges: Array<{
    __typename: 'RewardConnectionEdge';
    cursor: string;
    node: {
      __typename: 'Reward';
      createdAt: string;
      id: string;
      paymentModel: SchemaTypes.PaymentModel;
      paid: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
    };
  }>;
  pageInfo: {
    __typename: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};

export const RewardHistoryConnectionData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RewardHistoryConnectionData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RewardConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RewardHistoryItemData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PageInfoData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
