import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { CreatePartnerMediaFormValues } from './types';

export const validateCreatePartnerMediaForm = (
  values: CreatePartnerMediaFormValues
): FormikErrors<CreatePartnerMediaFormValues> =>
  getRequiredErrors(values, {
    mediaItemName: true,
    mediaCampaignId: true,
    tariffId: true,
  });
