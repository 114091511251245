import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Language } from 'types/generated/gql';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AppRouteParams } from 'utils/tsUtils/extractParamsFromAppRoute';
import { mapLanguageToPath } from 'utils/language/mapLanguageToPath';
import { checkIsLanguagePath } from 'utils/language/checkIsLanguagePath';
import { mapLanguagePathToLanguage } from 'utils/language/mapLanguagePathToLanguage';
import { LanguageContext } from './LanguageContext';
import { LanguageContextValue } from './types';

interface LanguageContextProviderProps {
  children: ReactNode;
  preferredLanguage: Language;
  currentLanguage: Language;
}

export const LanguageContextProvider: FC<LanguageContextProviderProps> = ({
  children,
  preferredLanguage,
  currentLanguage,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { lang } = useParams<AppRouteParams['Lang']['root']>();

  const changeLanguage = useCallback(
    (newLanguage: Language) => {
      const updatedPath = pathname.replace(
        new RegExp(`^\\/${lang}(\\/|$)`),
        `/${mapLanguageToPath(newLanguage)}$1`
      );

      if (
        !checkIsLanguagePath(lang) ||
        newLanguage !== mapLanguagePathToLanguage(lang)
      ) {
        navigate(updatedPath, { replace: true });
      }
    },
    [lang, navigate, pathname]
  );

  const value: LanguageContextValue = useMemo(
    () => ({
      preferredLanguage,
      currentLanguage,
      changeLanguage,
    }),
    [changeLanguage, currentLanguage, preferredLanguage]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
