import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetUserLastApplicationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetUserLastApplication = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        companyLastApplication: {
          __typename: 'CompanyApplication';
          declineReason?: string | null;
          id: string;
          number: string;
          status: SchemaTypes.CompanyApplicationStatus;
          trafficType?: string | null;
          contact: {
            __typename: 'Contact';
            type: SchemaTypes.ContactType;
            value: string;
          };
        };
      }
    | {
        __typename: 'Partner';
        id: string;
        partnerLastApplication: {
          __typename: 'PartnerApplication';
          declineReason?: string | null;
          id: string;
          number: string;
          status: SchemaTypes.PartnerApplicationStatus;
          trafficType?: string | null;
          contact: {
            __typename: 'Contact';
            type: SchemaTypes.ContactType;
            value: string;
          };
        };
      };
};

export const GetUserLastApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserLastApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: {
                          kind: 'Name',
                          value: 'partnerLastApplication',
                        },
                        name: { kind: 'Name', value: 'lastApplication' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contact' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'declineReason' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trafficType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: {
                          kind: 'Name',
                          value: 'companyLastApplication',
                        },
                        name: { kind: 'Name', value: 'lastApplication' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contact' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'declineReason' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trafficType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUserLastApplication__
 *
 * To run a query within a React component, call `useGetUserLastApplication` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLastApplication` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLastApplication({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserLastApplication(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUserLastApplication,
    GetUserLastApplicationVariables
  > &
    (
      | { variables: GetUserLastApplicationVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetUserLastApplication,
    GetUserLastApplicationVariables
  >(GetUserLastApplicationDocument, options);
}
export function useGetUserLastApplicationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserLastApplication,
    GetUserLastApplicationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUserLastApplication,
    GetUserLastApplicationVariables
  >(GetUserLastApplicationDocument, options);
}
export function useGetUserLastApplicationSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetUserLastApplication,
    GetUserLastApplicationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetUserLastApplication,
    GetUserLastApplicationVariables
  >(GetUserLastApplicationDocument, options);
}
export type GetUserLastApplicationHookResult = ReturnType<
  typeof useGetUserLastApplication
>;
export type GetUserLastApplicationLazyQueryHookResult = ReturnType<
  typeof useGetUserLastApplicationLazyQuery
>;
export type GetUserLastApplicationSuspenseQueryHookResult = ReturnType<
  typeof useGetUserLastApplicationSuspenseQuery
>;
export type GetUserLastApplicationQueryResult = Apollo.QueryResult<
  GetUserLastApplication,
  GetUserLastApplicationVariables
>;
