import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { FieldName } from './const';
import { ReapplicationValues } from './types';

export const validateReapplication = (
  values: ReapplicationValues
): FormikErrors<ReapplicationValues> => {
  const requiredErrors = getRequiredErrors(values, {
    [FieldName.ContactType]: true,
    [FieldName.ContactValue]: true,
    [FieldName.TrafficSource]: false,
  });

  return requiredErrors;
};
