import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'utils/formatDate';
import { CompanyPartnersData } from '../queries/fragments/generated/CompanyPartnersData';

const COLUMNS_SIZES = {
  nameAndIdSize: 232,
  mediaCountSize: 189,
  noteMinSize: 708,
  dateSize: 143,
} as const;

export const useCompanyPartnersTableColumns = (): Array<
  ColumnDef<CompanyPartnersData>
> => {
  const { t } = useTranslation('companyPartners');
  const columnHelper = createColumnHelper<CompanyPartnersData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<CompanyPartnersData, any>> = [
    columnHelper.accessor('id', {
      header: t('content.dataTable.columnHeader.partnerId'),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          title={row.original.username}
          subTitle={`#${row.original.id}`}
        />
      ),
      enablePinning: true,
      size: COLUMNS_SIZES.nameAndIdSize,
    }),
    columnHelper.accessor('mediaItemsCount', {
      header: t('content.dataTable.columnHeader.mediaCount'),
      cell: ({ row }) => row.original.mediaItemsCount,
      size: COLUMNS_SIZES.mediaCountSize,
    }),
    columnHelper.accessor('note', {
      header: t('content.dataTable.columnHeader.note'),
      cell: ({ row }) => row.original.note,
      minSize: COLUMNS_SIZES.noteMinSize,
    }),
    columnHelper.accessor('createdAt', {
      header: t('content.dataTable.columnHeader.created'),
      cell: ({ row }) =>
        formatDate(
          new Date(row.original.createdAt),
          DateFormat.FullDateWithoutHours
        ),
      size: COLUMNS_SIZES.dateSize,
    }),
  ];

  return columns;
};
