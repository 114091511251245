import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { TwoFactorValues } from './types';

export const validateTwoFactor = (
  values: TwoFactorValues
): FormikErrors<TwoFactorValues> => {
  const requiredErrors = getRequiredErrors(values, {
    code: true,
  });

  return requiredErrors;
};
