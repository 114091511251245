import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { ConfirmModal, ConfirmModalContextProvider } from 'ui/ConfirmModal';
import { GameAccountWithdrawalMethodStatus } from 'types/generated/gql';
import { PendingGameAccountModal } from '../../PendingGameAccountModal';
import { DeletedGameAccountModal } from '../../DeletedGameAccountModal';
import { ConfirmedGameAccountModal } from '../../ConfirmedGameAccountModal';

interface OpenGameAccountModalParams {
  status: GameAccountWithdrawalMethodStatus;
  accountNumber: string;
}

export const useGameAccountModal = () => {
  const { t } = useTranslation(['common', 'payments']);
  const { dispatch } = useModalContext();

  const openPendingGameAccountModal = useCallback(
    (accountNumber: string) => {
      dispatch(
        openModal({
          title: t(
            'payments:partnerAndCompany.content.drawer.addVavadaAccount.header.titleNewAccount'
          ),
          content: <PendingGameAccountModal accountNumber={accountNumber} />,
        })
      );
    },
    [dispatch, t]
  );

  const openConfirmedGameAccountModal = useCallback(
    (accountNumber: string) => {
      dispatch(
        openModal({
          title: t(
            'payments:partnerAndCompany.content.drawer.vavadaAccount.header.title'
          ),
          content: (
            <ConfirmModalContextProvider>
              <ConfirmedGameAccountModal accountNumber={accountNumber} />
              <ConfirmModal
                content={{
                  cancelButton: t('pageCloseConfirmModalContent.cancelButton'),
                  confirmButton: t(
                    'pageCloseConfirmModalContent.confirmButton'
                  ),
                  text: t('pageCloseConfirmModalContent.text'),
                  title: t('pageCloseConfirmModalContent.title'),
                }}
              />
            </ConfirmModalContextProvider>
          ),
        })
      );
    },
    [dispatch, t]
  );

  const openDeletedGameAccountModal = useCallback(
    (accountNumber: string) => {
      dispatch(
        openModal({
          title: t(
            'payments:partnerAndCompany.content.drawer.deleteMethod.header.header'
          ),
          content: <DeletedGameAccountModal accountNumber={accountNumber} />,
        })
      );
    },
    [dispatch, t]
  );

  const openGameAccountModal = useCallback(
    ({ status, accountNumber }: OpenGameAccountModalParams) => {
      const mapStatusToAction: Record<
        GameAccountWithdrawalMethodStatus,
        (accountNumber: string) => void
      > = {
        [GameAccountWithdrawalMethodStatus.Pending]:
          openPendingGameAccountModal,
        [GameAccountWithdrawalMethodStatus.Deleted]:
          openDeletedGameAccountModal,
        [GameAccountWithdrawalMethodStatus.Confirmed]:
          openConfirmedGameAccountModal,
      };

      const action = mapStatusToAction[status];

      action(accountNumber);
    },
    [
      openPendingGameAccountModal,
      openDeletedGameAccountModal,
      openConfirmedGameAccountModal,
    ]
  );

  return {
    openGameAccountModal,
  };
};
