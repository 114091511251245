import { t } from 'i18n';
import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { getPasswordError } from 'utils/form/getPasswordError';
import type { PasswordChangeValues } from './types';

export const validatePasswordChange = (
  values: PasswordChangeValues
): FormikErrors<PasswordChangeValues> => {
  const errors: FormikErrors<PasswordChangeValues> = {};

  const passwordError = getPasswordError(values.createPassword);

  if (passwordError) {
    errors.createPassword = passwordError;
  }

  if (values.createPassword !== values.confirmPassword) {
    errors.confirmPassword = t(
      'auth:resetPassword.input.changePassword.validation.invalidConfirmPassword'
    );
  }

  const requiredErrors = getRequiredErrors(values, {
    createPassword: true,
    confirmPassword: true,
  });

  return {
    ...errors,
    ...requiredErrors,
  };
};
