import { FC, useCallback } from 'react';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { useGetCurrentCurrencyAccountQuery } from 'hooks/queryHooks/useGetCurrentCurrencyAccountQuery';
import { updateConfirmClose, closeModal, useModalContext } from 'ui/Modal';
import { useConfirmModalContext } from 'ui/ConfirmModal';
import { WithdrawToGameAccountForm } from './components/WithdrawToGameAccountForm';

interface ConfirmedGameAccountModalProps {
  accountNumber: string;
}

export const ConfirmedGameAccountModal: FC<ConfirmedGameAccountModalProps> = ({
  accountNumber,
}) => {
  const { currentCurrencyAccount, error, loading } =
    useGetCurrentCurrencyAccountQuery();

  const { dispatch } = useModalContext();
  const { showConfirmation } = useConfirmModalContext();

  const handleDirtyChange = useCallback(
    (dirty: boolean) => {
      dispatch(
        updateConfirmClose({
          confirmClose: dirty ? showConfirmation : null,
        })
      );
    },
    [dispatch, showConfirmation]
  );

  const handleWithdrawalCreated = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  if (error || !currentCurrencyAccount) {
    return <Error />;
  }

  return (
    <WithdrawToGameAccountForm
      currentCurrencyAccount={currentCurrencyAccount}
      accountNumber={accountNumber}
      onDirtyChange={handleDirtyChange}
      onWithdrawalCreated={handleWithdrawalCreated}
    />
  );
};
