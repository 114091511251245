import { Breakpoints } from 'const';

const sortedBreakpoints = (
  Object.values(Breakpoints) as Array<Breakpoints>
).sort((a, b) => a - b);

export const getMinimalUnderBreakpoint = (): Breakpoints =>
  sortedBreakpoints.find((breakpoint) => {
    if (window.innerWidth <= breakpoint) {
      return breakpoint;
    }

    return undefined;
  }) || Breakpoints.Lg;
