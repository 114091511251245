import { useAuth } from 'components/auth';
import { useGetBasicTariffs } from 'pages/Tariffs/queries/generated/GetBasicTariffs';

export const useGetUserTariffsQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetBasicTariffs({
    variables: {
      userId: user.id,
    },
  });

  return { tariffs: data?.user.tariffs, loading, error };
};
