import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CpaReferralLinkStatisticInfoData } from './CpaReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
export type CpaReferralLinkStatisticTotalData_PromoCodeMediaItem = {
  __typename: 'PromoCodeMediaItem';
  id: string;
};

export type CpaReferralLinkStatisticTotalData_ReferralLinkMediaItem = {
  __typename: 'ReferralLinkMediaItem';
  id: string;
  cpaStatistic: {
    __typename: 'CpaReferralLinkStatistic';
    total: {
      __typename: 'CpaReferralLinkStatisticInfo';
      numberOfRedeposits: number;
      wasFD: boolean;
      averageDeposit: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      depositsAll: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      redepositsAll: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      withdrawalsAll: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
    };
  };
};

export type CpaReferralLinkStatisticTotalData =
  | CpaReferralLinkStatisticTotalData_PromoCodeMediaItem
  | CpaReferralLinkStatisticTotalData_ReferralLinkMediaItem;

export const CpaReferralLinkStatisticTotalData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CpaReferralLinkStatisticTotalData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MediaItemInterface' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReferralLinkMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cpaStatistic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'end' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'end' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'start' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'start' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CpaReferralLinkStatisticInfoData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
