export enum MarginBottomSize {
  Large = 'Large',
  Medium = 'medium',
  Small = 'small',
  None = 'none',
}

export enum IndentBetweenRowsSize {
  Large = 'Large',
  Small = 'small',
}
