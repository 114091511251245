import * as SchemaTypes from '../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type CompanyPartnersData = {
  __typename: 'CompanyPartner';
  createdAt: string;
  id: string;
  mediaItemsCount: number;
  note?: string | null;
  username: string;
};

export const CompanyPartnersData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyPartnersData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CompanyPartner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaItemsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
