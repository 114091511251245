import { IconSize } from '../../Icon';
import { ListSize } from '../const';

export const getIconSize = (dropdownSize?: ListSize) => {
  switch (dropdownSize) {
    case ListSize.Small:
      return IconSize.Small;
    default:
      return IconSize.Large;
  }
};
