import * as SchemaTypes from '../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { ReferralLinkMediaItemBasicData } from './ReferralLinkMediaItemBasicData';
import { TariffForMediaBasicData } from './TariffForMediaBasicData';
import { TariffForMediaData } from './TariffForMediaData';
export type ReferralLinkMediaItemData = {
  __typename: 'ReferralLinkMediaItem';
  createdAt: string;
  link: string;
  id: string;
  name: string;
  tariff:
    | {
        __typename: 'CpaTariff';
        geolocation: Array<CommonType.Country>;
        createdAt: string;
        id: string;
        name: string;
        baseline: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        reward: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
      }
    | {
        __typename: 'RevShareTariff';
        rewardPercent: string;
        createdAt: string;
        id: string;
        name: string;
      };
  postbackInfo: {
    __typename: 'PostbackInfo';
    commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
  };
  owner:
    | { __typename: 'Company'; id: string; name: string }
    | { __typename: 'CompanyPartner'; id: string; username: string }
    | { __typename: 'Partner'; id: string; username: string };
};

export const ReferralLinkMediaItemData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralLinkMediaItemData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReferralLinkMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ReferralLinkMediaItemBasicData' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tariff' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TariffForMediaData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
