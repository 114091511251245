import { AuthStatuses } from 'components/auth';
import { AuthStatusesType } from 'components/auth/const';
import { ReactElement } from 'react';
import { TwoFactorCheckBlock } from './components/TwoFactorCheckBlock';
import { TwoFactorGenerationBlock } from './components/TwoFactorGenerationBlock';

export const mapAuthStatusToBlock: Partial<
  Record<AuthStatusesType, ReactElement>
> = {
  [AuthStatuses.TwoFactorAuthenticationRequired]: <TwoFactorCheckBlock />,
  [AuthStatuses.TwoFactorGenerationRequired]: <TwoFactorGenerationBlock />,
};
