import { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Contact } from 'types/generated/gql';
import { useAuth } from 'components/auth';
import { FieldName } from './const';
import { validateProfileIdentities } from './validation';
import { CompanyIdentitiesValues } from './types';
import { useUpdateCompanyIdentitiesMutation } from './hooks/useUpdateCompanyIdentitiesMutation';
import { CompanyProfileFormContent } from './CompanyProfileFormContent';

interface CompanyProfileFormProps {
  companyName: string;
  contact: Contact;
  webSite?: string | null;
  onDirtyChange: (dirty: boolean) => void;
}

export const CompanyProfileForm: FC<CompanyProfileFormProps> = ({
  companyName,
  contact,
  webSite,
  onDirtyChange,
}) => {
  const { user } = useAuth();

  const { updateCompanyIdentities, loading } =
    useUpdateCompanyIdentitiesMutation({
      companyId: user.id,
    });

  const initialValues: CompanyIdentitiesValues = {
    [FieldName.CompanyName]: companyName,
    [FieldName.Website]: webSite || null,
    [FieldName.ContactType]: contact.type,
    [FieldName.ContactValue]: contact.value,
  };

  const handleSubmit = (
    formValues: CompanyIdentitiesValues,
    formikHelpers: FormikHelpers<CompanyIdentitiesValues>
  ) => {
    updateCompanyIdentities(formValues, () => {
      formikHelpers.resetForm({
        values: formValues,
      });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validateProfileIdentities}
    >
      <CompanyProfileFormContent
        loading={loading}
        onDirtyChange={onDirtyChange}
      />
    </Formik>
  );
};
