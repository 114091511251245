import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { CopyLink } from 'ui/CopyLink';
import { CompanyRevShareStatisticItemData } from '../queries/fragments/generated/CompanyRevShareStatisticItemData';
import { getStatisticRevShareItemOwnerToShow } from '../helpers';
import { COLUMNS_SIZES } from '../../const';

export const useCompanyRevShareStatisticItemsColumns = (): Array<
  ColumnDef<CompanyRevShareStatisticItemData>
> => {
  const { t } = useTranslation(['common', 'statistic']);
  const columnHelper = createColumnHelper<CompanyRevShareStatisticItemData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<CompanyRevShareStatisticItemData, any>> = [
    columnHelper.accessor('id', {
      header: t('statistic:company.content.dataTable.revShare.mediaElement'),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          title={row.original.name}
          subTitle={
            <CopyLink
              tooltipText={t('linkCopied')}
              to={row.original.link}
              target="_blank"
            >
              {row.original.link}
            </CopyLink>
          }
        />
      ),
      size: COLUMNS_SIZES.mediaNameSize,
      enablePinning: true,
    }),
    columnHelper.accessor('owner', {
      header: t('statistic:company.content.dataTable.revShare.owner'),
      cell: ({ row }) =>
        getStatisticRevShareItemOwnerToShow(row.original.owner),
      minSize: COLUMNS_SIZES.ownerSize,
    }),
    columnHelper.accessor('statisticInfo.hits', {
      header: t('statistic:company.content.dataTable.revShare.hits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.hosts', {
      header: t('statistic:company.content.dataTable.revShare.hosts'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.registrations', {
      header: t('statistic:company.content.dataTable.revShare.registrations'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.firstDeposits', {
      header: t('statistic:company.content.dataTable.revShare.firstDeposits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.depositsAll', {
      header: t('statistic:company.content.dataTable.revShare.depositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.withdrawalsAll', {
      header: t('statistic:company.content.dataTable.revShare.withdrawalsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.depositsFee', {
      header: t('statistic:company.content.dataTable.revShare.depositsFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.withdrawalsFee', {
      header: t('statistic:company.content.dataTable.revShare.withdrawalsFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.adminFee', {
      header: t('statistic:company.content.dataTable.revShare.adminFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.reward', {
      header: t('statistic:company.content.dataTable.revShare.reward'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.averageDeposit', {
      header: t('statistic:company.content.dataTable.revShare.averageDeposit'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.redepositsAll', {
      header: t('statistic:company.content.dataTable.revShare.redepositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
    }),

    columnHelper.accessor('statisticInfo.conversionHostsToRegPercent', {
      header: t('statistic:company.content.dataTable.revShare.hosts2reg'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.conversionRegToDepPercent', {
      header: t('statistic:company.content.dataTable.revShare.reg2dep'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.numberOfRedeposits', {
      header: t(
        'statistic:company.content.dataTable.revShare.numberOfRedeposits'
      ),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
    }),
  ];

  return columns;
};
