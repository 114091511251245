import { ApolloError } from '@apollo/client';
import { Dispatch, SetStateAction } from 'react';
import { AppRoute } from 'const';
import { toast } from 'ui/toast';
import { PasswordResetValues } from '../types';
import { useResetUserPassword } from '../queries/generated/ResetUserPassword';

interface UsePasswordResetParams {
  setEmail: Dispatch<SetStateAction<string>>;
}

export const usePasswordReset = ({ setEmail }: UsePasswordResetParams) => {
  const [resetUserPassword, { loading }] = useResetUserPassword();

  const handleCompleted = (email: string) => {
    setEmail(email);
  };

  const handleError = (error: ApolloError) => {
    toast.error({
      header: 'Error',
      text: error.message,
      toastId: 'passwordResetError',
    });
  };

  const passwordReset = (values: PasswordResetValues) => {
    resetUserPassword({
      variables: {
        input: {
          currentEmail: values.email,
          setPasswordPath: AppRoute.Lang.PasswordChange,
        },
      },
      onCompleted: () => handleCompleted(values.email),
      onError: handleError,
    });
  };

  return {
    passwordReset,
    loading,
  };
};
