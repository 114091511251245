import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { CopyText } from 'ui/CopyText';
import { SupportButtons } from 'components/SupportButtons';
import styles from './PendingBlock.module.scss';

interface PendingBlockProps {
  applicationNumber: string;
}

export const PendingBlock: FC<PendingBlockProps> = ({ applicationNumber }) => {
  const { t } = useTranslation('auth');
  const translationUserType = useTranslationUserType();

  return (
    <div data-testid="pending-block">
      <div className={styles.contentContainer}>
        <h1 className={SPEC_HEADER_LARGE}>
          {t(`signup.${translationUserType}.content.statusPending.title`)}
        </h1>
        <div className={styles.descriptionBlock}>
          {t(`signup.${translationUserType}.content.statusPending.subtitle`)}
          <CopyText
            tooltipText={t(
              `signup.${translationUserType}.action.statusPending.hint.copyLink`
            )}
            containerClassName={styles.copyTextContainer}
          >
            {applicationNumber}
          </CopyText>
        </div>
      </div>
      <SupportButtons />
    </div>
  );
};
