import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useUpdatePartnerIdentities } from '../queries/generated/UpdatePartnerIdentities';
import { PartnerIdentitiesValues } from '../types';

type UpdatePartnerIdentities = (
  profileIdentitiesValues: PartnerIdentitiesValues,
  onCompleted: () => void
) => void;

interface UseProfileFormParams {
  partnerId: string;
}

export const useUpdatePartnerIdentitiesMutation = ({
  partnerId,
}: UseProfileFormParams) => {
  const { t } = useTranslation();
  const [updatePartnerIdentitiesMutation, { loading }] =
    useUpdatePartnerIdentities();

  const updatePartnerIdentities: UpdatePartnerIdentities = (
    { firstName, lastName, birthday, contactValue, contactType },
    onCompleted: () => void
  ) => {
    const formattedDate =
      birthday && formatDate(birthday, DateFormat.DateScalar);

    updatePartnerIdentitiesMutation({
      variables: {
        input: {
          id: partnerId,
          birthday: formattedDate,
          firstName: firstName || null,
          lastName: lastName || null,
          contact: {
            type: contactType,
            value: contactValue,
          },
        },
      },
      onError: () => {
        toast.error(t('errors.updateDataError'));
      },
      onCompleted: () => {
        onCompleted();
        toast.success(t('updateDataSuccess'));
      },
    });
  };

  return { updatePartnerIdentities, loading };
};
