import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { PROMO_CODE_MEDIA_ITEMS_PER_PAGE } from '../const';
import { useGetPromoCodeMediaItems } from '../queries/generated/GetPromoCodeMediaItems';
import { PromoCodeMediaItemData } from '../queries/fragments/generated/PromoCodeMediaItemData';

export const useGetPromoCodeMediaItemsQuery = () => {
  const { user } = useAuth();

  const { data, fetchMore, loading, error } = useGetPromoCodeMediaItems({
    variables: {
      first: PROMO_CODE_MEDIA_ITEMS_PER_PAGE,
      userId: user.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const promoCodeMediaItemsConnection = data?.user.promoCodeMediaItems;

  const promoCodeMediaItems: Array<PromoCodeMediaItemData> | undefined =
    useMemo(
      () => promoCodeMediaItemsConnection?.edges.map((item) => item.node),
      [promoCodeMediaItemsConnection?.edges]
    );

  const pageInfo = promoCodeMediaItemsConnection?.pageInfo;

  return { promoCodeMediaItems, fetchMore, pageInfo, loading, error };
};
