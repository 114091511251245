import classNames from 'classnames';
import styles from './FormBlockWrapper.module.scss';
import { MarginBottomSize, IndentBetweenRowsSize } from './const';

const mapMarginSizeToStyle: Record<MarginBottomSize, string> = {
  [MarginBottomSize.Large]: styles.largeMarginBottom,
  [MarginBottomSize.Medium]: styles.mediumMarginBottom,
  [MarginBottomSize.Small]: styles.smallMarginBottom,
  [MarginBottomSize.None]: '',
};

const mapIndentBetweenRowsToStyle: Record<IndentBetweenRowsSize, string> = {
  [IndentBetweenRowsSize.Large]: styles.largeIndentBetweenRows,
  [IndentBetweenRowsSize.Small]: styles.smallIndentBetweenRows,
};

interface FormBlockWrapperProps {
  className?: string;
  children: React.ReactNode;
  marginBottomSize?: MarginBottomSize;
  indentBetweenRowSize?: IndentBetweenRowsSize;
}

export const FormBlockWrapper: React.FC<FormBlockWrapperProps> = ({
  children,
  className,
  marginBottomSize = MarginBottomSize.None,
  indentBetweenRowSize = IndentBetweenRowsSize.Small,
}) => (
  <div
    className={classNames(
      className,
      styles.formBlockWrapper,
      mapMarginSizeToStyle[marginBottomSize],
      mapIndentBetweenRowsToStyle[indentBetweenRowSize]
    )}
  >
    {children}
  </div>
);
