import {
  CompanyApplication,
  CompanyApplicationStatus,
  PartnerApplication,
  PartnerApplicationStatus,
} from 'types/generated/gql';
import { UserTypeName } from 'types';
import { ApprovedBlock } from '../components/ApprovedBlock';
import { DeclinedBlock } from '../components/DeclinedBlock';
import { PendingBlock } from '../components/PendingBlock';

export const getComponentByApplicationInfo = (
  applicationInfo: PartnerApplication | CompanyApplication
) => {
  const mapPartnerApplicationStatusToInfoComponent: Record<
    PartnerApplicationStatus,
    JSX.Element
  > = {
    [PartnerApplicationStatus.Approved]: (
      <ApprovedBlock userTypeName={UserTypeName.Partner} />
    ),
    [PartnerApplicationStatus.Declined]: (
      <DeclinedBlock declineReason={applicationInfo.declineReason} />
    ),
    [PartnerApplicationStatus.Pending]: (
      <PendingBlock applicationNumber={applicationInfo.number} />
    ),
  };

  const mapCompanyApplicationStatusToInfoComponent: Record<
    CompanyApplicationStatus,
    JSX.Element
  > = {
    [CompanyApplicationStatus.Approved]: (
      <ApprovedBlock userTypeName={UserTypeName.Company} />
    ),
    [CompanyApplicationStatus.Declined]: (
      <DeclinedBlock declineReason={applicationInfo.declineReason} />
    ),
    [CompanyApplicationStatus.Pending]: (
      <PendingBlock applicationNumber={applicationInfo.number} />
    ),
  };

  return applicationInfo.__typename === 'PartnerApplication'
    ? mapPartnerApplicationStatusToInfoComponent[applicationInfo.status]
    : mapCompanyApplicationStatusToInfoComponent[applicationInfo.status];
};
