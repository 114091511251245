import { GameAccountWithdrawalMethodStatus } from 'types/generated/gql';
import { GameAccountStatusLabelType } from 'components/GameAccountStatusLabel';
import { WithdrawalMethodTypeName } from 'types';
import { WithdrawalMethodsData } from './queries/fragments/generated/WithdrawalMethodsData';

export const mapWithdrawalStatusToLabelStatus: Record<
  | GameAccountWithdrawalMethodStatus.Deleted
  | GameAccountWithdrawalMethodStatus.Pending,
  GameAccountStatusLabelType
> = {
  [GameAccountWithdrawalMethodStatus.Pending]:
    GameAccountStatusLabelType.Pending,
  [GameAccountWithdrawalMethodStatus.Deleted]:
    GameAccountStatusLabelType.Deleted,
};

export const checkIfHasGameAccount = (
  withdrawalMethods?: Array<WithdrawalMethodsData>
) =>
  withdrawalMethods?.some(
    (method) =>
      method.__typename === WithdrawalMethodTypeName.GameAccountWithdrawalMethod
  );
