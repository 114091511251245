import {
  DatePicker,
  DatePickerHandleProps,
  DatePickerRange,
} from 'ui/DatePicker';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import classnames from 'classnames';
import { useDatePickerWithRange } from './hooks/useDatePickerWithRange';
import styles from './DatePickerWithRange.module.scss';
import { ButtonAlign } from '../Button/const';

export interface DatePickerWithRangeProps {
  onDateSubmit: (date: DatePickerRange) => void;
  disabled?: boolean;
  name?: string;
  openInitialDate?: Date;
  minDateInitial?: Date | null | undefined;
  startDateInitial?: Date | null | undefined;
  endDateInitial?: Date | null | undefined;
  onChange?: (date: DatePickerRange) => void;
  dateResetTo?: DatePickerHandleProps;
  hideRangesOnMobile?: boolean;
}

export const DatePickerWithRange = ({
  onDateSubmit,
  endDateInitial,
  startDateInitial,
  dateResetTo,
  minDateInitial,
  openInitialDate,
  ...rest
}: DatePickerWithRangeProps) => {
  const {
    customRanges,
    activeCustomRange,
    startDate,
    endDate,
    handleSubmit,
    handleChange,
    dateTitle,
  } = useDatePickerWithRange({
    minDateInitial,
    onDateSubmit,
    startDateInitial,
    endDateInitial,
  });

  return (
    <div className={styles.datePickerContainer}>
      <div className={classnames(styles.rangesContainer)}>
        {customRanges.map(({ title, onClick, id }) => (
          <Button
            align={ButtonAlign.Left}
            key={id}
            theme={ButtonTheme.BlueGrey}
            size={ButtonSize.Medium}
            className={classnames(
              styles.range,
              activeCustomRange === id && styles.active
            )}
            onClick={onClick}
          >
            {title}
          </Button>
        ))}
      </div>
      <div className={styles.datePicker}>
        <div className={styles.dateTitle}>{dateTitle}</div>
        <DatePicker
          {...rest}
          openInitialDate={openInitialDate}
          isSelectsRange
          className={styles.dropdown}
          onChange={handleChange}
          startDateInitial={startDate}
          dateResetTo={dateResetTo}
          endDateInitial={endDate}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};
