import { FC } from 'react';
import classNames from 'classnames';
import { DropdownProps } from 'ui/Dropdown/Dropdown';
import { DropdownPosition } from '../../const';
import { mapPositionToClassName } from './helpers';
import styles from './DropdownContainer.module.scss';

type DropdownContainerProps = Pick<
  DropdownProps,
  | 'position'
  | 'addStyleToContainer'
  | 'children'
  | 'withStyleMaxContent'
  | 'hasGrayOutline'
>;

export const DropdownContainer: FC<DropdownContainerProps> = ({
  position = DropdownPosition.LeftBottom,
  addStyleToContainer,
  withStyleMaxContent,
  hasGrayOutline,
  children,
}) => (
  <div
    tabIndex={-1}
    className={classNames(
      styles.container,
      addStyleToContainer && styles.commonStyles,
      withStyleMaxContent && styles.withStyleMaxContent,
      mapPositionToClassName[position],
      hasGrayOutline && styles.withGrayOutline
    )}
  >
    {children}
  </div>
);
