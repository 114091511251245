type AnyObject = Record<string, unknown>;
type ObjectWithEdges<T extends AnyObject> = T & { edges: Array<unknown> };
type ResponseData = AnyObject | Array<ResponseData> | string | number;

const checkIfHasEdges = <T extends AnyObject>(
  anyObject: T
): anyObject is ObjectWithEdges<T> =>
  'edges' in anyObject && Array.isArray(anyObject.edges);

const sliceEdges = (
  edges: Array<unknown>,
  countToSlice: number
): Array<unknown> => {
  if (countToSlice >= edges.length) {
    return edges;
  }

  return edges.slice(0, countToSlice);
};

export const sliceEdgesDeep = <T extends ResponseData | Array<ResponseData>>(
  data: T,
  countToSlice: number,
  isSliced = { value: false }
): T => {
  if (Array.isArray(data)) {
    return data.map((item) =>
      sliceEdgesDeep(item, countToSlice, isSliced)
    ) as T;
  }

  if (typeof data !== 'object' || data === null) {
    return data;
  }

  if (checkIfHasEdges(data)) {
    const { edges } = data;
    const slicedEdges = sliceEdges(edges, countToSlice);

    if (edges === slicedEdges) {
      return data;
    }

    if (isSliced.value) {
      throw new Error(
        'Detected more than 1 edges property in the response data'
      );
    }

    // eslint-disable-next-line no-param-reassign
    isSliced.value = true;

    return {
      ...data,
      edges: slicedEdges,
    };
  }

  const updatedData: AnyObject = {};

  Object.entries(data).forEach(([key, value]) => {
    updatedData[key] = sliceEdgesDeep(value as T, countToSlice, isSliced);
  });

  const result = isSliced.value ? updatedData : data;

  return result as T;
};
