import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { PromoCodeMediaItemsConnectionData } from '../fragments/generated/PromoCodeMediaItemsConnectionData';
import { PromoCodeMediaItemData } from '../fragments/generated/PromoCodeMediaItemData';
import { PageInfoData } from '../../../../../../../../queries/fragments/generated/PageInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPromoCodeMediaItemsVariables = SchemaTypes.Exact<{
  after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  before?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  first: SchemaTypes.Scalars['Int']['input'];
  last?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetPromoCodeMediaItems = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        promoCodeMediaItems: {
          __typename: 'PromoCodeMediaItemConnection';
          edges: Array<{
            __typename: 'PromoCodeMediaItemConnectionEdge';
            cursor: string;
            node: {
              __typename: 'PromoCodeMediaItem';
              createdAt: string;
              id: string;
              referralLink: string;
              tariffName: string;
              token: string;
              type: SchemaTypes.PromoCodeMediaItemType;
              owner:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanyPartner'; id: string; username: string }
                | { __typename: 'Partner'; id: string; username: string };
            };
          }>;
          pageInfo: {
            __typename: 'PageInfo';
            endCursor?: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor: string;
          };
        };
      }
    | {
        __typename: 'Partner';
        id: string;
        promoCodeMediaItems: {
          __typename: 'PromoCodeMediaItemConnection';
          edges: Array<{
            __typename: 'PromoCodeMediaItemConnectionEdge';
            cursor: string;
            node: {
              __typename: 'PromoCodeMediaItem';
              createdAt: string;
              id: string;
              referralLink: string;
              tariffName: string;
              token: string;
              type: SchemaTypes.PromoCodeMediaItemType;
              owner:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanyPartner'; id: string; username: string }
                | { __typename: 'Partner'; id: string; username: string };
            };
          }>;
          pageInfo: {
            __typename: 'PageInfo';
            endCursor?: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor: string;
          };
        };
      };
};

export const GetPromoCodeMediaItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPromoCodeMediaItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCodeMediaItems' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'before' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'before' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'first' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'last' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'last' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'PromoCodeMediaItemsConnectionData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCodeMediaItems' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'before' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'before' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'first' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'last' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'last' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'PromoCodeMediaItemsConnectionData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PromoCodeMediaItemsConnectionData.definitions,
    ...PromoCodeMediaItemData.definitions,
    ...PageInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPromoCodeMediaItems__
 *
 * To run a query within a React component, call `useGetPromoCodeMediaItems` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoCodeMediaItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoCodeMediaItems({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPromoCodeMediaItems(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPromoCodeMediaItems,
    GetPromoCodeMediaItemsVariables
  > &
    (
      | { variables: GetPromoCodeMediaItemsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPromoCodeMediaItems,
    GetPromoCodeMediaItemsVariables
  >(GetPromoCodeMediaItemsDocument, options);
}
export function useGetPromoCodeMediaItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPromoCodeMediaItems,
    GetPromoCodeMediaItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPromoCodeMediaItems,
    GetPromoCodeMediaItemsVariables
  >(GetPromoCodeMediaItemsDocument, options);
}
export function useGetPromoCodeMediaItemsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPromoCodeMediaItems,
    GetPromoCodeMediaItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetPromoCodeMediaItems,
    GetPromoCodeMediaItemsVariables
  >(GetPromoCodeMediaItemsDocument, options);
}
export type GetPromoCodeMediaItemsHookResult = ReturnType<
  typeof useGetPromoCodeMediaItems
>;
export type GetPromoCodeMediaItemsLazyQueryHookResult = ReturnType<
  typeof useGetPromoCodeMediaItemsLazyQuery
>;
export type GetPromoCodeMediaItemsSuspenseQueryHookResult = ReturnType<
  typeof useGetPromoCodeMediaItemsSuspenseQuery
>;
export type GetPromoCodeMediaItemsQueryResult = Apollo.QueryResult<
  GetPromoCodeMediaItems,
  GetPromoCodeMediaItemsVariables
>;
