import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { LinkGameAccountModal } from '../../LinkGameAccountModal';

export const useLinkGameAccountModal = () => {
  const { t } = useTranslation('payments');
  const { dispatch } = useModalContext();

  const openLinkGameAccountModal = useCallback(() => {
    dispatch(
      openModal({
        title: t(
          'partnerAndCompany.content.drawer.addVavadaAccount.header.titleNewAccount'
        ),
        content: <LinkGameAccountModal />,
      })
    );
  }, [dispatch, t]);

  return {
    openLinkGameAccountModal,
  };
};
