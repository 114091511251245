import classNames from 'classnames';
import { ReactComponent as LoadingIcon } from 'assets/icons/solid/Loader.svg';
import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';
import styles from './Button.module.scss';
import { ButtonSize, ButtonTheme, ButtonAlign } from './const';
import { getButtonStyles, getIconSize } from './helpers';
import { LinkStyledProp } from './types';
import { Icon } from '../Icon';
import type { IconComponent } from '../../types';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  textWrapperClassName?: string;
  linkStyled?: LinkStyledProp;
  isLoading?: boolean;
  size?: ButtonSize;
  theme?: ButtonTheme;
  align?: ButtonAlign;
  iconConfig?: {
    iconComponent: IconComponent;
    before?: boolean;
    containerClassName?: string;
  };
  isFullWidth?: boolean;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  align = ButtonAlign.Center,
  children,
  className,
  textWrapperClassName,
  linkStyled,
  type = 'button',
  iconConfig,
  isLoading,
  size = ButtonSize.Large,
  theme = ButtonTheme.Blue,
  disabled,
  isFullWidth,
  ...rest
}) => {
  const hasLoading = isLoading && !linkStyled;

  const icon = iconConfig && (
    <Icon
      containerClassName={iconConfig.containerClassName}
      hasDefaultColor={false}
      iconComponent={iconConfig.iconComponent}
      size={getIconSize(size, linkStyled)}
    />
  );

  const contentToRender = (
    <>
      {iconConfig?.before && icon}
      {children && (
        <span className={classNames(textWrapperClassName, styles.text)}>
          {children}
        </span>
      )}
      {!iconConfig?.before && icon}
    </>
  );

  const Component =
    typeof linkStyled === 'object' && linkStyled.inline ? 'span' : 'button';

  return (
    <Component
      data-testid="button"
      type={type}
      className={classNames(
        className,
        hasLoading && styles.buttonWithLoading,
        isFullWidth && styles.fullWidth,
        getButtonStyles(theme, size, linkStyled),
        styles[align]
      )}
      disabled={disabled || hasLoading}
      {...rest}
    >
      {hasLoading ? (
        <>
          <Icon
            size={getIconSize(size)}
            containerClassName={styles.loadingIcon}
            iconComponent={LoadingIcon}
            hasDefaultColor={false}
            spin
          />
          <div
            className={styles.contentContainerIfLoading}
            data-testid="button-content-wrapper"
          >
            {contentToRender}
          </div>
        </>
      ) : (
        contentToRender
      )}
    </Component>
  );
};
