import { FC } from 'react';
import { VerticalLayout } from 'ui/VerticalLayout';
import { Footer } from 'components/Footer';
import { PasswordChangeBlock } from './components/PasswordChangeBlock';

export const PasswordChange: FC = () => (
  <VerticalLayout
    centralBlock={<PasswordChangeBlock />}
    bottomBlock={<Footer />}
  />
);
