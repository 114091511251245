import { components, GroupBase, NoticeProps } from 'react-select';
import { SelectOption } from '../../type';
import styles from './NoOptionsMessage.module.scss';

type NoOptionsMessageProps = NoticeProps<
  SelectOption,
  false,
  GroupBase<SelectOption>
>;

export const NoOptionsMessage = (props: NoOptionsMessageProps) => (
  <div className={styles.noOptionMessage}>
    <components.NoOptionsMessage {...props} />
  </div>
);
