import { TariffTypeName } from 'types';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { TariffBasicData } from '../../../../../queries/generated/TariffBasicData';

export const getRewardByTariff = (tariff: TariffBasicData): string | null => {
  if (tariff.__typename === TariffTypeName.RevShareTariff) {
    return `${tariff.rewardPercent} %`;
  }

  if (tariff.__typename === TariffTypeName.CpaTariff) {
    return getMoneyAmount(tariff.reward);
  }

  return null;
};
