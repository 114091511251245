import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { NonNullableDateRange } from 'types';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { useGetCpaStatisticDetailedTotalQuery } from './useGetCpaStatisticDetailedTotalQuery';
import { CpaReferralLinkStatisticInfoData } from '../query/fragments/generated/CpaReferralLinkStatisticInfoData';

type GetSummaryRowData = (
  info: CpaReferralLinkStatisticInfoData
) => Array<ReactNode>;

const getSummaryRowData: GetSummaryRowData = ({
  averageDeposit,
  depositsAll,
  numberOfRedeposits,
  redepositsAll,
  wasFD,
  withdrawalsAll,
}) => {
  const result: Array<ReactNode> = [
    wasFD,
    getMoneyAmount(depositsAll),
    getMoneyAmount(averageDeposit),
    getMoneyAmount(redepositsAll),
    numberOfRedeposits,
    getMoneyAmount(withdrawalsAll),
  ];

  return result;
};

interface UseGenerateCpaStatisticDetailedSummaryRowParams {
  cpaMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const useGenerateCpaStatisticDetailedSummaryRow = ({
  cpaMediaItemId,
  nonNullableDateRange,
}: UseGenerateCpaStatisticDetailedSummaryRowParams) => {
  const { t } = useTranslation('statistic');
  const translationUserType = useTranslationUserType();

  const { statisticTotal, loading, error } =
    useGetCpaStatisticDetailedTotalQuery({
      cpaMediaItemId,
      nonNullableDateRange,
    });

  const summaryRowData = statisticTotal
    ? [
        t(`${translationUserType}.content.dataTable.cpa.allMedia`),
        undefined,
        ...getSummaryRowData(statisticTotal),
      ]
    : undefined;

  return { summaryRowData, loading, error };
};
