import { AppRoute } from 'const';
import { useLanguage } from 'contexts/LanguageContext';
import { useNavigate } from 'react-router';
import { addBasePath } from 'utils/routing/addBasePath';
import { AuthStorageKey, Storage } from 'utils/storage';

export const useNavigateToSuccessPage = () => {
  const navigate = useNavigate();
  const { currentLanguage } = useLanguage();

  const navigateToSuccessPage = () => {
    if (Storage.authStorage.getValue(AuthStorageKey.ShowTwoFactorSuccess)) {
      navigate({
        pathname: addBasePath(
          AppRoute.Lang.TwoFactorConnected,
          currentLanguage
        ),
      });
    }
  };

  return { navigateToSuccessPage };
};
