import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Token } from 'components/auth';

export const getWithTokenLink = (tokenData: Token): ApolloLink =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      Authorization: tokenData.token ? `Bearer ${tokenData.token}` : '',
    },
  }));
