import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { PartnerIdentitiesValues } from './types';
import { FieldName } from './const';

export const validateProfileIdentities = (
  values: PartnerIdentitiesValues
): FormikErrors<PartnerIdentitiesValues> => {
  const errors: FormikErrors<PartnerIdentitiesValues> = {};

  const requiredErrors = getRequiredErrors(values, {
    [FieldName.ContactType]: true,
    [FieldName.ContactValue]: true,
  });

  return {
    ...errors,
    ...requiredErrors,
  };
};
