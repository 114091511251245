import { Dispatch, FC, SetStateAction } from 'react';
import { TextWithLink } from 'ui/TextWithLink';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { SPEC_HEADER_LARGE, AppRoute } from 'const';
import { useTranslation } from 'react-i18next';
import { PasswordResetForm } from '../PasswordResetForm';
import styles from './PasswordResetFormWrapper.module.scss';

interface PasswordResetFormWrapperProps {
  setEmail: Dispatch<SetStateAction<string>>;
}

export const PasswordResetFormWrapper: FC<PasswordResetFormWrapperProps> = ({
  setEmail,
}) => {
  const { t } = useTranslation('auth');
  const { createLocalizedPath } = useCreateLocalizedPath();

  return (
    <div>
      <div className={styles.contentContainer}>
        <h1 className={SPEC_HEADER_LARGE}>
          {t('resetPassword.content.resetPassword.title')}
        </h1>
        <p className={styles.description}>
          {t('resetPassword.content.resetPassword.subtitle')}
        </p>
        <PasswordResetForm setEmail={setEmail} />
      </div>
      <TextWithLink
        text={t('resetPassword.action.resetPassword.label.noAccessToEmail')}
        linkText={t(
          'resetPassword.action.resetPassword.button.noAccessToEmail'
        )}
        to={createLocalizedPath(AppRoute.Lang.Support)}
      />
    </div>
  );
};
