import { MouseEventHandler, useRef, useState } from 'react';
import { copyToClipboard } from 'utils/copyToClipboard';

export const CLOSE_TOOLTIP_TIMEOUT_MS = 1000;

interface UseHandleCopyWithTooltipStatusParams {
  text: string;
}

interface UseHandleCopyWithTooltipStatusResult {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  isTooltipVisible: boolean;
}

export const useHandleCopyWithTooltipStatus = ({
  text,
}: UseHandleCopyWithTooltipStatusParams): UseHandleCopyWithTooltipStatusResult => {
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!text) {
      return;
    }

    copyToClipboard(text);
    setIsTooltipVisible(true);

    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      setIsTooltipVisible(false);
    }, CLOSE_TOOLTIP_TIMEOUT_MS);
  };

  return {
    handleClick,
    isTooltipVisible,
  };
};
