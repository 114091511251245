import { FC } from 'react';
import { IconComponent } from 'types';
import { ReactComponent as AddIcon } from 'assets/icons/line/Add.svg';
import { Icon } from 'ui/Icon';
import { Button, ButtonSize } from 'ui/Button';
import classnames from 'classnames';
import styles from './TopBar.module.scss';

interface TopBarProps {
  title: string;
  icon: IconComponent;
  action?: {
    onClick: () => void;
    buttonText: string;
  };
  withDefaultMargin?: boolean;
}

export const TopBar: FC<TopBarProps> = ({
  title,
  icon,
  action,
  withDefaultMargin = true,
}) => (
  <div
    className={classnames(
      styles.container,
      withDefaultMargin && styles.defaultMargin
    )}
  >
    <div className={styles.titleContainer}>
      <Icon
        iconComponent={icon}
        containerClassName={styles.iconContainer}
        iconClassName={styles.icon}
      />
      <h1 className={styles.title}>{title}</h1>
    </div>
    {action && (
      <>
        <Button
          className={styles.desktopButton}
          size={ButtonSize.Medium}
          onClick={action.onClick}
        >
          {action.buttonText}
        </Button>
        <Button
          data-testid="mobile-top-bar-button"
          className={styles.mobileButton}
          size={ButtonSize.Medium}
          iconConfig={{ iconComponent: AddIcon }}
          onClick={action.onClick}
        />
      </>
    )}
  </div>
);
