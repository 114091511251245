import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { RevShareReferralLinkStatisticInfoData } from './RevShareReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
export type RevShareReferralLinkStatisticItemData = {
  __typename: 'RevShareReferralLinkStatisticItem';
  id: string;
  playerName?: string | null;
  statisticInfo: {
    __typename: 'RevShareReferralLinkStatisticInfo';
    numberOfRedeposits: number;
    wasFD: boolean;
    adminFee: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    averageDeposit: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    depositsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    depositsFee: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    redepositsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    withdrawalsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    withdrawalsFee: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
  };
};

export const RevShareReferralLinkStatisticItemData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RevShareReferralLinkStatisticItemData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RevShareReferralLinkStatisticItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playerName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statisticInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RevShareReferralLinkStatisticInfoData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
