import { useAuth } from 'components/auth';
import { useGetCompanyName } from 'queries/generated/GetCompanyName';
import { UserTypeName } from 'types';

export const useGetCompanyNameQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetCompanyName({
    variables: {
      id: user.id,
    },
    fetchPolicy: 'cache-first',
  });

  const companyName =
    data?.user.__typename === UserTypeName.Company ? data.user.name : undefined;

  return { companyName, loading, error };
};
