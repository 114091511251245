import { Storage } from '../storage';
import { Value } from '../types';

export class BaseStorageManager<TKey extends string> {
  setValue = (key: TKey, value: Value): void => {
    Storage.local.setValue(key, value);
  };

  getValue = <T extends Value>(key: TKey): T | null =>
    Storage.local.getValue(key);

  removeValue = (key: TKey): void => {
    Storage.local.removeValue(key);
  };
}
