import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostbackEvent } from 'types/generated/gql';
import { InputWithCopy } from 'ui/formItems';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { FieldName } from '../../../Postback/const';
import { MacrosDropdown } from './components/MacrosDropdown';
import { useMacrosDropdownState } from './hooks/useMacrosDropdownState';
import styles from './UrlInput.module.scss';

interface UrlInputProps {
  isLoading: boolean;
  selectedPostback?: PostbackEvent;
}

export const UrlInput: FC<UrlInputProps> = ({
  isLoading,
  selectedPostback,
}) => {
  const { t } = useTranslation(['common', 'media']);
  const translationUserType = useTranslationUserType();

  const [isFocused, setIsFocused] = useState(false);
  const isDropdownOpen = useMacrosDropdownState(isFocused);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={styles.urlInput}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      data-testid="url-input"
    >
      <InputWithCopy
        tooltipText={t('linkCopied')}
        disabled={isLoading}
        name={FieldName.Url}
        label={t(
          `media:${translationUserType}.content.drawer.details.postback.card.input.label.url`
        )}
        formValidationProps={{ errorTooltipPlacement: 'left' }}
        maxLength={200}
      />
      {selectedPostback && (
        <MacrosDropdown
          selectedPostback={selectedPostback}
          isOpen={isDropdownOpen}
        />
      )}
    </div>
  );
};
