import { FC } from 'react';
import { IconComponent } from 'types';
import { useField } from 'formik';
import { CardWithIcon } from 'ui/CardWithIcon';
import { Radio } from 'ui/formItems/components/Radio';

interface RadioCardProps {
  title: string;
  subTitle: string;
  icon: IconComponent;
  name: string;
  value: string;
  className?: string;
}

export const RadioCard: FC<RadioCardProps> = ({
  value,
  name,
  title,
  subTitle,
  icon,
  className,
}) => {
  const [field, , setters] = useField(name);

  const handleClick = () => {
    setters.setValue(value);
  };

  const isRadioChecked = value === field.value;

  return (
    <CardWithIcon
      role="radio"
      aria-checked={isRadioChecked}
      tabIndex={0}
      title={title}
      subTitle={subTitle}
      onClick={handleClick}
      isActive={isRadioChecked}
      className={className}
      icon={icon}
      rightElement={<Radio name={name} value={value} />}
    />
  );
};
