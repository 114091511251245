import { useGetUserStatusQuery } from 'hooks/queryHooks/useGetUserStatusQuery';
import { CompanyStatus, PartnerStatus } from 'types/generated/gql';

export const useIsUserStatusActive = () => {
  const { status, loading } = useGetUserStatusQuery();

  return {
    isUserStatusActive:
      status === PartnerStatus.Active || status === CompanyStatus.Active,
    loading,
  };
};
