import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
export type WithdrawalInvoiceData = {
  __typename: 'GameAccountWithdrawalInvoice';
  status: SchemaTypes.GameAccountWithdrawalInvoiceStatus;
  date: string;
  id: string;
  method: {
    __typename: 'GameAccountWithdrawalMethod';
    gameAccountNumber: string;
    id: string;
  };
  amount: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  paidAmount?: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  } | null;
};

export const WithdrawalInvoiceData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WithdrawalInvoiceData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WithdrawalInvoiceInterface' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paidAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GameAccountWithdrawalInvoice' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'method' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gameAccountNumber' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
