import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { checkIsValidUrl } from 'utils/checkIsValidUrl';
import { t } from 'i18n';
import { PostbackValues } from '../../types';

export const validatePostbackForm = (
  values: PostbackValues
): FormikErrors<PostbackValues> => {
  const errors: FormikErrors<PostbackValues> = {};

  if (values.url && !checkIsValidUrl(values.url)) {
    errors.url = t('errors.incorrectFormat');
  }

  const requiredErrors = getRequiredErrors(values, {
    event: true,
    method: true,
    url: true,
  });

  return {
    ...errors,
    ...requiredErrors,
  };
};
