import classNames from 'classnames';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { Icon, IconSize } from '../../../Icon';
import { inputIconConfig } from '../../types';
import styles from './InputIcon.module.scss';

interface InputIconProps {
  className?: string;
  iconConfig?: inputIconConfig;
  withTopIndent?: boolean;
}

export const InputIcon: React.FC<InputIconProps> = ({
  className,
  iconConfig,
  withTopIndent = true,
}) => {
  if (!iconConfig?.icon) {
    return null;
  }

  const { icon, iconSize = IconSize.Large, onClick, tooltip } = iconConfig;

  const containerClassNames = classNames(
    className,
    styles.container,
    withTopIndent && styles.withTopIndent,
    onClick && styles.withOnClick
  );

  const iconElement = (
    <Icon
      size={iconSize}
      hasDefaultColor={false}
      iconComponent={icon}
      onClick={onClick}
      containerClassName={tooltip ? undefined : containerClassNames}
    />
  );

  return tooltip ? (
    <Tooltip
      isOpen={tooltip.isTooltipVisible}
      tooltipTheme={TooltipTheme.Light}
      tooltipContent={tooltip.tooltipText}
      placement="top"
      tooltipTriggerClassName={containerClassNames}
    >
      {iconElement}
    </Tooltip>
  ) : (
    iconElement
  );
};
