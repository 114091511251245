import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { LocalError } from 'const';
import { checkIsDateRangeNonNullable } from 'helpers/checkIsDateRangeNonNullable';
import { NonNullableDateRange } from 'types';
import { ButtonTheme } from 'ui/Button';
import { DatePickerHandleProps, DatePickerRange } from 'ui/DatePicker';
import { DropdownPosition } from 'ui/Dropdown';
import { Icon, IconSize } from 'ui/Icon';
import { toast } from 'ui/toast';
import { DatePickerWithRange } from 'ui/DatePickerWithRange';
import { ReactComponent as CalendarIcon } from 'assets/icons/line/Calendar.svg';
import { DropdownWithButton } from 'ui/DropdownWithButton';
import { getButtonName } from './helpers';
import styles from './DatePickerWithRangeButton.module.scss';

const ERROR_TOAST_ID = 'empty-date-error';

export interface DatePickerWithRangeButtonProps {
  onDateSubmit: (date: DatePickerRange) => void;
  dateResetTo?: DatePickerHandleProps;
  startDateInitial: Date;
  endDateInitial: Date;
  dropdownPosition?: DropdownPosition;
  hideRangesOnMobile?: boolean;
  'data-testid'?: string;
}

export const DatePickerWithRangeButton: FC<DatePickerWithRangeButtonProps> = ({
  startDateInitial,
  endDateInitial,
  onDateSubmit,
  dateResetTo,
  dropdownPosition,
  hideRangesOnMobile,
  'data-testid': dataTestid,
}) => {
  const { t } = useTranslation('common');

  const [[startDate, endDate], setPickedDateRange] =
    useState<NonNullableDateRange>([startDateInitial, endDateInitial]);

  const handleDateSubmit = (date: DatePickerRange) => {
    if (checkIsDateRangeNonNullable(date)) {
      setPickedDateRange(date);
      onDateSubmit(date);

      return;
    }

    toast.error({
      header: t('errors.commonError'),
      text: LocalError.NullableDateRange,
      toastId: ERROR_TOAST_ID,
    });
  };

  return (
    <div className={styles.container} data-testid={dataTestid}>
      <span className={styles.period}>{t('input.label.period')}</span>
      <DropdownWithButton
        buttonContent={
          <>
            <Icon
              hasDefaultColor={false}
              iconComponent={CalendarIcon}
              size={IconSize.Small}
            />
            {getButtonName([startDate, endDate])}
          </>
        }
        buttonProps={{
          theme: ButtonTheme.BlueGrey,
        }}
        dropdownProps={{
          addStyleToContainer: false,
          position: dropdownPosition,
          title: t('input.label.period'),
        }}
      >
        <DatePickerWithRange
          onDateSubmit={handleDateSubmit}
          dateResetTo={dateResetTo}
          startDateInitial={startDateInitial}
          endDateInitial={endDateInitial}
        />
      </DropdownWithButton>
    </div>
  );
};
