import { TableCellAlign } from './const';
import styles from './Table.module.scss';

export const calcColumnLeftOffset = (index: number) => {
  if (index === 0) {
    return '0px';
  }

  // TODO: Extend functionality to dynamically calculate column widths
  //  for pinning multiple columns, not just the first one
  return undefined;
};

export const mapAlignToStyle: Record<TableCellAlign, string> = {
  [TableCellAlign.Left]: styles.alignLeft,
  [TableCellAlign.Right]: styles.alignRight,
  [TableCellAlign.Center]: styles.alignCenter,
};
