import { AuthStorage, AuthStorageKey } from './authStorage';

interface StorageType {
  authStorage: AuthStorage;
}

export const Storage: StorageType = {
  authStorage: new AuthStorage(),
};

export { AuthStorageKey };
