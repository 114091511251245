import React, { HTMLProps } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useTooltipState } from '../hooks/useTooltipState';

const accessibleByTabProps = {
  role: 'button',
  tabIndex: 0,
};

interface TooltipTriggerProps extends HTMLProps<HTMLElement> {
  shouldSetWidthFitContent?: boolean;
  isAccessibleByTab?: boolean;
}

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  TooltipTriggerProps
>(
  (
    { children, shouldSetWidthFitContent, isAccessibleByTab, ...props },
    propRef
  ) => {
    const state = useTooltipState();
    const ref = useMergeRefs([state.refs.setReference, propRef]);

    return (
      <div
        data-testid="tooltip-trigger"
        style={{ width: shouldSetWidthFitContent ? 'fit-content' : undefined }}
        ref={ref}
        {...(isAccessibleByTab ? accessibleByTabProps : {})}
        {...state.getReferenceProps(props)}
      >
        {children}
      </div>
    );
  }
);
