import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { Currency } from 'types';
import { useLanguage } from 'contexts/LanguageContext';

interface UseSwitchNextRewardCurrencyRequestHandlersParams {
  onCompleted: (currency: Currency) => void;
}

export const useSwitchNextRewardCurrencyRequestHandlers = ({
  onCompleted,
}: UseSwitchNextRewardCurrencyRequestHandlersParams) => {
  const { t } = useTranslation('payments');
  const { currentLanguage } = useLanguage();

  const handleCompleted = useCallback(
    (currency: Currency) => {
      onCompleted?.(currency);

      toast.success({
        header: t(
          'partnerAndCompany.content.changeCurrency.content.modal.title'
        ),
        text: t(
          'partnerAndCompany.content.changeCurrency.content.modal.subtitle',
          {
            currency: getCurrencyDisplayName({
              currencyCode: currency,
              language: currentLanguage,
            }),
          }
        ),
      });
    },
    [currentLanguage, onCompleted, t]
  );

  const handleError = useCallback((errorMessage: string) => {
    toast.error({
      header: 'Error',
      text: errorMessage,
    });
  }, []);

  return {
    handleCompleted,
    handleError,
  };
};
