import { BreadcrumbsItem } from 'ui/Breadcrumbs';
import { CustomLabelsForCrumbs } from '../types';

export const applyCustomLabelsToCrumbs = (
  crumbs: Array<BreadcrumbsItem>,
  customLabelsForCrumbs: CustomLabelsForCrumbs
) =>
  crumbs.map((crumb) => {
    const { path } = crumb;

    if (!path) {
      return crumb;
    }

    const customLabel = customLabelsForCrumbs[path];

    return customLabel ? { ...crumb, label: customLabel } : crumb;
  });
