import { useAuth } from 'components/auth';
import { useGetTariff } from '../../../../../queries/generated/GetTariff';

export const useGetUserTariffQuery = (tariffId: string) => {
  const { user } = useAuth();

  const { data, loading, error } = useGetTariff({
    variables: {
      userId: user.id,
      tariffId,
    },
  });

  return { tariff: data?.user.tariff, loading, error };
};
