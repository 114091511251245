import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.scss';
import { Button, ButtonSize, ButtonTheme } from '../../../Button';

interface FooterProps {
  onReset: () => void;
  onSubmit: () => void;
}

export const Footer: FC<FooterProps> = ({ onReset, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.footerContainer}>
      <Button
        size={ButtonSize.Small}
        theme={ButtonTheme.RedGrey}
        onClick={onReset}
      >
        {t('datePicker.button.reset')}
      </Button>
      <Button
        size={ButtonSize.Small}
        theme={ButtonTheme.BlueGhost}
        onClick={onSubmit}
      >
        {t('datePicker.button.apply')}
      </Button>
    </div>
  );
};
