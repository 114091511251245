import { useAuth } from 'components/auth';
import { useGetAvailableCurrencyAccounts } from '../queries/generated/GetAvailableCurrencyAccounts';

export const useGetAvailableCurrencyAccountsQuery = () => {
  const { user } = useAuth();

  const { data, error, loading, refetch } = useGetAvailableCurrencyAccounts({
    variables: {
      userId: user.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const availableCurrencyAccounts = data?.user.availableCurrencyAccounts;

  return {
    availableCurrencyAccounts,
    error,
    loading,
    refetch,
  };
};
