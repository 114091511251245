import { FC } from 'react';
import { Table } from 'ui/Table';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { useLoadMore } from 'hooks/useLoadMore';
import { EmptyDataBlock } from 'components/EmptyDataBlock';
import { useTranslation } from 'react-i18next';
import { useGetCompanyPartnersQuery } from './hooks/useGetCompanyPartnersQuery';
import { useCompanyPartnersTableColumns } from './hooks/useCompanyPartnersTableColumns';

export const CompanyPartnersTable: FC = () => {
  const { t } = useTranslation('companyPartners');

  const {
    companyPartnersItems,
    pageInfo,
    fetchMore,
    error,
    loading,
    isSliced,
  } = useGetCompanyPartnersQuery();

  const columns = useCompanyPartnersTableColumns();

  const loadMore = useLoadMore(fetchMore, pageInfo);

  if (!companyPartnersItems && loading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!companyPartnersItems || companyPartnersItems.length === 0) {
    return <EmptyDataBlock emptyDataText={t('content.placeholder.noData')} />;
  }

  return (
    <Table
      columns={columns}
      data={companyPartnersItems}
      isLoading={loading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      error={error}
      fetchMore={loadMore}
    />
  );
};
