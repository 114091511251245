import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from 'ui/Table';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { useLoadMore } from 'hooks/useLoadMore';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { AppRoute } from 'const';
import { NonNullableDateRange } from 'types';
import { useGetCompanyRevShareStatisticItemsQuery } from './hooks/useGetCompanyRevShareStatisticItemsQuery';
import { useCompanyRevShareStatisticItemsColumns } from './hooks/useCompanyRevShareStatisticItemsColumns';
import { useGenerateCompanyRevShareStatisticSummaryRow } from './hooks/useGenerateCompanyRevShareStatisticSummaryRow';
import { CompanyRevShareStatisticItemData } from './queries/fragments/generated/CompanyRevShareStatisticItemData';

interface PartnerRevShareStatisticTableProps {
  nonNullableDateRange: NonNullableDateRange;
}

export const CompanyRevShareStatisticTable: FC<
  PartnerRevShareStatisticTableProps
> = ({ nonNullableDateRange }) => {
  const { t } = useTranslation('statistic');
  const [searchParams] = useSearchParams();
  const { createLocalizedPath } = useCreateLocalizedPath();

  const navigate = useNavigate();

  const {
    statisticItems,
    fetchMore,
    pageInfo,
    loading: itemsQueryLoading,
    error: itemsQueryError,
    isSliced,
  } = useGetCompanyRevShareStatisticItemsQuery(nonNullableDateRange);

  const {
    summaryRowData,
    loading: totalQueryLoading,
    error: totalQueryError,
  } = useGenerateCompanyRevShareStatisticSummaryRow(nonNullableDateRange);

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const columns = useCompanyRevShareStatisticItemsColumns();

  const loading = itemsQueryLoading || totalQueryLoading;
  const error = itemsQueryError || totalQueryError;

  const handleClickOnRow = ({ id }: CompanyRevShareStatisticItemData) => {
    const path = createLocalizedPath(
      composeRoutes(
        [
          AppRoute.Lang.Statistic.root,
          AppRoute.Lang.Statistic.RevShare.root,
          id,
        ],
        {
          searchParams,
        }
      )
    );

    navigate(path);
  };

  if ((!statisticItems || !summaryRowData) && loading) {
    return <Loader />;
  }

  if (error || statisticItems === undefined) {
    return <Error error={error} />;
  }

  return (
    <Table
      onClickOnRow={handleClickOnRow}
      columns={columns}
      data={statisticItems}
      summaryRow={summaryRowData}
      emptyText={t('company.content.placeholder.noData')}
      isLoading={loading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
    />
  );
};
