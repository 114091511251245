import { FC } from 'react';
import { StatusLabel } from 'ui/StatusLabel';
import {
  GameAccountStatusLabelSize,
  GameAccountStatusLabelType,
} from './const';
import {
  mapSizeToStatusLabelSize,
  mapWithdrawalStatusToStatusLabelType,
  mapWithdrawalStatusToText,
} from './helpers';

interface TransactionStatusLabelProps {
  status: GameAccountStatusLabelType;
  size?: GameAccountStatusLabelSize;
}

export const GameAccountStatusLabel: FC<TransactionStatusLabelProps> = ({
  status,
  size = GameAccountStatusLabelSize.Large,
}) => (
  <StatusLabel
    type={mapWithdrawalStatusToStatusLabelType[status]}
    size={mapSizeToStatusLabelSize[size]}
  >
    {mapWithdrawalStatusToText(status)}
  </StatusLabel>
);
