import { useAuth } from 'components/auth';
import { useGetTariffsForMediaItemCreation } from '../queries/generated/GetTariffsForMediaItemCreation';

export const useGetTariffForMediaItemCreationQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetTariffsForMediaItemCreation({
    variables: {
      userId: user.id,
    },
  });

  return { tariffs: data?.user.tariffsForMediaItemCreation, loading, error };
};
