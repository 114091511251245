import {
  PropsWithChildren,
  Reducer,
  Context,
  useReducer,
  createContext,
  Dispatch,
  useMemo,
} from 'react';

type CreateStoreReturnType<StateType, ActionType> = readonly [
  Context<{ state: StateType; dispatch: Dispatch<ActionType> }>,
  (props: PropsWithChildren<unknown>) => JSX.Element,
];

export const createStore = <StateType, ActionType>(
  reducer: Reducer<StateType, ActionType>,
  initialState: StateType
): CreateStoreReturnType<StateType, ActionType> => {
  const defaultDispatch: Dispatch<ActionType> = () => initialState;

  const context = createContext({
    state: initialState,
    dispatch: defaultDispatch,
  });

  const Provider = (props: PropsWithChildren<unknown>) => {
    const [state, dispatch] = useReducer<Reducer<StateType, ActionType>>(
      reducer,
      initialState
    );

    const providerValue = useMemo(
      () => ({ state, dispatch }),
      [state, dispatch]
    );

    return <context.Provider value={providerValue} {...props} />;
  };

  return [context, Provider] as const;
};
