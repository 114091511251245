import { useContext } from 'react';
import { AuthContextValue } from '../types';
import { AuthContext } from '../context/AuthContext';

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      'useAuth must be used within an AuthContext.Provider wrapper'
    );
  }

  return context;
};
