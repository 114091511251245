import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { FieldName } from '../../../../Postback/const';

export const useMacrosDropdownState = (isFocused: boolean) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [field] = useField<string>(FieldName.Url);

  useEffect(() => {
    if (
      field.value &&
      field.value.at(-1) === '=' &&
      field.value.at(-2) !== '=' &&
      isFocused
    ) {
      setIsDropdownOpen(true);

      return;
    }

    setIsDropdownOpen(false);
  }, [field.value, isFocused]);

  return isDropdownOpen;
};
