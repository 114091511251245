import { useAuth } from 'components/auth';
import { t } from 'i18n';
import { AppRoute } from 'const';
import { UserPrivilege } from 'types/generated/userPrivilege';
import { useLogout } from 'hooks/useLogout';
import { MenuItemData } from 'ui/Menu/types';
import { ReactComponent as DashboardIcon } from 'assets/icons/colors/Dashboard.svg';
import { ReactComponent as StatisticIcon } from 'assets/icons/colors/Chart.svg';
import { ReactComponent as TariffIcon } from 'assets/icons/colors/Calculator.svg';
import { ReactComponent as MediaIcon } from 'assets/icons/colors/Image.svg';
import { ReactComponent as PartnersListIcon } from 'assets/icons/colors/PartnersList.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/colors/WadOfMoney.svg';
import { ReactComponent as UserIcon } from 'assets/icons/colors/User.svg';
import { ReactComponent as RegionIcon } from 'assets/icons/colors/Region.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/colors/Logout.svg';
import { useLanguage } from 'contexts/LanguageContext';

interface UseGetMenuData {
  topMenuData: Array<MenuItemData>;
  bottomMenuData: Array<MenuItemData>;
}

export const useGetMenuData = (): UseGetMenuData => {
  const auth = useAuth();
  const { logout } = useLogout();
  const { currentLanguage } = useLanguage();

  return {
    topMenuData: [
      {
        icon: DashboardIcon,
        link: AppRoute.Lang.EmptyRoute,
        text: t('header.dashboard'),
        isHidden: false,
      },
      {
        icon: StatisticIcon,
        link: AppRoute.Lang.Statistic.root,
        text: t('header.statistics'),
        isHidden: false,
      },
      {
        icon: TariffIcon,
        link: AppRoute.Lang.Tariffs,
        text: t('header.tariffs'),
        isHidden: !auth.privileges.isSomeGranted([
          UserPrivilege.ViewPartnerTariffs,
          UserPrivilege.ViewCompanyTariffs,
        ]),
      },
      {
        icon: MediaIcon,
        link: AppRoute.Lang.Media,
        text: t('header.media'),
        isHidden: !auth.privileges.isSomeGranted([
          UserPrivilege.ViewPartnerMediaItem,
          UserPrivilege.ViewCompanyReferralLinkMediaItems,
        ]),
      },
      {
        icon: PartnersListIcon,
        link: AppRoute.Lang.CompanyPartners,
        text: t('header.yourPartners'),
        isHidden: !auth.privileges.areEveryGranted([
          UserPrivilege.ViewCompanyPartners,
        ]),
      },
    ],
    bottomMenuData: [
      {
        icon: PaymentIcon,
        link: AppRoute.Lang.Payments,
        text: t('header.payments'),
        isHidden: !auth.privileges.isSomeGranted([
          UserPrivilege.ViewCompanyNextRewardInfo,
          UserPrivilege.ViewPartnerNextRewardInfo,
        ]),
      },
      {
        icon: UserIcon,
        link: AppRoute.Lang.Profile,
        text: t('header.account'),
        isHidden: !auth.privileges.areEveryGranted([UserPrivilege.ViewUser]),
      },
      {
        icon: RegionIcon,
        onClick: () => () => undefined,
        text: currentLanguage,
        isHidden: !auth.privileges.areEveryGranted([UserPrivilege.ViewUser]),
      },
      {
        icon: LogoutIcon,
        onClick: () => logout(),
        text: t('header.signOut'),
        isHidden: false,
      },
    ],
  };
};
