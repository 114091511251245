import { Column } from '@tanstack/react-table';
import { ReactNode, RefObject } from 'react';
import { useColumnWidths } from './hooks/useColumnWidths';

interface ColGroupProps<T> {
  columns: Array<Column<T, unknown>>;
  tableContainerRef: RefObject<HTMLDivElement>;
}

export const ColGroup = <T,>({
  columns,
  tableContainerRef,
}: ColGroupProps<T>): ReactNode => {
  const { columnWidths } = useColumnWidths({ columns, tableContainerRef });

  return (
    <colgroup>
      {columns.map(({ id }, index) => (
        <col
          key={id}
          style={{
            width: columnWidths[index],
          }}
        />
      ))}
    </colgroup>
  );
};
