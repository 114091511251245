import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'ui/Table';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { useLoadMore } from 'hooks/useLoadMore';
import { useRewardHistoryItemsColumns } from './hooks/useRewardHistoryItemsColumns';
import { useGetRewardHistoryQuery } from './hooks/useGetRewardHistoryQuery';

export const RewardHistoryTable: FC = () => {
  const { t } = useTranslation('payments');

  const { rewardHistoryItems, fetchMore, pageInfo, loading, error } =
    useGetRewardHistoryQuery();

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const columns = useRewardHistoryItemsColumns();

  if (!rewardHistoryItems && loading) {
    return <Loader />;
  }

  if (error || rewardHistoryItems === undefined) {
    return <Error error={error} />;
  }

  return (
    <Table
      columns={columns}
      data={rewardHistoryItems}
      emptyText={t('partnerAndCompany.content.placeholder.rewards.noData')}
      isLoading={loading}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
    />
  );
};
