import { Children, FC, ReactNode } from 'react';
import { MAX_CHILDREN_COUNT } from './const';
import styles from './SubHeaderBlock.module.scss';

interface SubHeaderBlockProps {
  children: ReactNode;
}

export const SubHeaderBlock: FC<SubHeaderBlockProps> = ({ children }) => {
  const childrenArray = Children.toArray(children);

  if (childrenArray.length > MAX_CHILDREN_COUNT) {
    throw new Error(
      `Component ${SubHeaderBlock.name} can accept maximum ${MAX_CHILDREN_COUNT} children`
    );
  }

  return <div className={styles.container}>{children}</div>;
};
