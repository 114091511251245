import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import { AppMenu } from 'components/AppMenu/AppMenu';
import { AuthStorageKey, Storage } from 'utils/storage';

import { Modal, ModalProvider } from 'ui/Modal';
import styles from './MainLayout.module.scss';

export const MainLayout: FC = () => {
  useEffect(() => {
    Storage.authStorage.removeValue(AuthStorageKey.ShowTwoFactorSuccess);
  }, []);

  return (
    <ModalProvider>
      <div className={styles.layout}>
        <AppMenu />
        <Outlet />
      </div>
      <Modal />
    </ModalProvider>
  );
};
