import { Dispatch, FC, SetStateAction } from 'react';
import { SPEC_HEADER_LARGE } from 'const';
import { useTranslation } from 'react-i18next';
import { PasswordChangeForm } from '../PasswordChangeForm';
import styles from './PasswordChangeFormWrapper.module.scss';

interface PasswordChangeFormWrapperProps {
  setPasswordChanged: Dispatch<SetStateAction<boolean>>;
}

export const PasswordChangeFormWrapper: FC<PasswordChangeFormWrapperProps> = ({
  setPasswordChanged,
}) => {
  const { t } = useTranslation('auth');

  return (
    <div className={styles.container}>
      <h1 className={SPEC_HEADER_LARGE}>
        {t('resetPassword.content.changePassword.title')}
      </h1>
      <p className={styles.description}>
        {t('resetPassword.content.changePassword.subtitle')}
      </p>
      <PasswordChangeForm setPasswordChanged={setPasswordChanged} />
    </div>
  );
};
