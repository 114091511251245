import { ApolloError } from '@apollo/client';
import { AppRoute, ErrorCode } from 'const';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { checkIsApolloErrorHasErrorCode } from 'utils/checkIsApolloErrorHasErrorCode';

export const useRedirectToNotFound = (error?: ApolloError) => {
  const navigate = useNavigate();
  const { createLocalizedPath } = useCreateLocalizedPath();

  useEffect(() => {
    if (checkIsApolloErrorHasErrorCode(error, ErrorCode.NotFound)) {
      navigate(createLocalizedPath(AppRoute.Lang.NotFoundPage));
    }
  }, [createLocalizedPath, error, navigate]);
};
