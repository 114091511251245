import { useAuth } from 'components/auth';
import { DateFormat, formatDate } from 'utils/formatDate';
import { MediaItemTypeName, NonNullableDateRange } from 'types';
import { useGetRevShareStatisticDetailedTotal } from '../query/generated/GetRevShareStatisticDetailedTotal';

const { DateScalar } = DateFormat;

interface UseGetRevShareStatisticDetailedTotalQueryParams {
  revShareMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const useGetRevShareStatisticDetailedTotalQuery = ({
  revShareMediaItemId,
  nonNullableDateRange,
}: UseGetRevShareStatisticDetailedTotalQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const { data, loading, error } = useGetRevShareStatisticDetailedTotal({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      userId: user.id,
      revShareMediaItemId,
    },
    cacheFirstOnPop: true,
  });

  const statisticTotal =
    data?.user.mediaItem?.__typename === MediaItemTypeName.ReferralLinkMediaItem
      ? data.user.mediaItem.revShareStatistic.total
      : undefined;

  return { statisticTotal, loading, error };
};
