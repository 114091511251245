import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorRetry } from 'components/ErrorRetry';
import { CurrencyAccount } from 'types/generated/gql';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { useLanguage } from 'contexts/LanguageContext';

interface BalanceCardBottomContentProps {
  loading: boolean;
  isError: boolean;
  onRefetch: () => void;
  currentCurrencyAccount?: CurrencyAccount | null;
  availableCurrencyAccounts?: Array<CurrencyAccount | null>;
}

export const BalanceCardBottomContent: FC<BalanceCardBottomContentProps> = ({
  currentCurrencyAccount,
  availableCurrencyAccounts,
  loading,
  isError,
  onRefetch,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();

  const isCurrentCurrencyAccountInvalid =
    !currentCurrencyAccount?.currency ||
    currentCurrencyAccount.amount === null ||
    !availableCurrencyAccounts ||
    isError;

  if (loading) {
    return t('loading');
  }

  if (isCurrentCurrencyAccountInvalid) {
    return <ErrorRetry onRefetch={onRefetch} />;
  }

  return getCurrencyDisplayName({
    currencyCode: currentCurrencyAccount.currency,
    language: currentLanguage,
  });
};
