import { t } from 'i18n';
import { PostbackEvent, PostbackMethod } from 'types/generated/gql';
import { TranslationUserType } from 'types';

export const mapEventToName = (
  event: PostbackEvent,
  translationUserType: TranslationUserType
): string => {
  const map: Record<PostbackEvent, string> = {
    [PostbackEvent.FirstDeposit]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackEvents.firstDeposit`
    ),
    [PostbackEvent.QualificationCheck]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackEvents.qualificationCheck`
    ),
    [PostbackEvent.Registration]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackEvents.signUp`
    ),
    [PostbackEvent.Reward]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackEvents.reward`
    ),
  };

  return map[event];
};

export const mapMethodToName = (
  method: PostbackMethod,
  translationUserType: TranslationUserType
): string => {
  const map: Record<PostbackMethod, string> = {
    [PostbackMethod.Get]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackMethods.get`
    ),
    [PostbackMethod.Post]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackMethods.post`
    ),
  };

  return map[method];
};
