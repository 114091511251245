import { ModalAction } from '../const';
import { Modal } from '../types';

type Payload = Pick<Modal, 'confirmClose'>;

export interface UpdateConfirmCloseAction {
  type: ModalAction.UpdateConfirmClose;
  payload: Payload;
}

export const updateConfirmClose = (
  payload: Payload
): UpdateConfirmCloseAction => ({
  type: ModalAction.UpdateConfirmClose,
  payload,
});
