import { FC } from 'react';
import { checkIsCountryCode } from 'utils/countries/checkIsCountryCode';
import { Country, Currency } from 'types';
import {
  getCountryCodeByCurrencyCode,
  getFlagEmojiByCountryCode,
} from './helpers';
import styles from './FlagIcon.module.scss';

interface FlagIconProps {
  code: Currency | Country;
}

export const FlagIcon: FC<FlagIconProps> = ({ code }) => {
  const countryCode = checkIsCountryCode(code)
    ? code
    : getCountryCodeByCurrencyCode(code);

  if (!countryCode) {
    return null;
  }

  const flagEmoji = getFlagEmojiByCountryCode(countryCode);

  return (
    <span data-testid="flag-emoji" className={styles.emoji}>
      {flagEmoji}
    </span>
  );
};
