import { TooltipOptions } from '../types';
import { useTooltip } from '../hooks/useTooltip';
import { TooltipContext } from '../hooks/useTooltipState';

export const TooltipContextProvider: React.FC<
  React.PropsWithChildren<TooltipOptions>
> = ({ children, ...options }) => {
  const tooltip = useTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};
