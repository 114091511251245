import { FC } from 'react';
import classNames from 'classnames';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { FlagIcon } from 'ui/FlagIcon';
import { useLanguage } from 'contexts/LanguageContext';
import { mapLanguageToCountryCode } from 'utils/language/mapLanguageToCountryCode';
import { mapLanguageToDisplayName } from 'utils/language/mapLanguageToDisplayName';
import styles from './LanguageDropdownButton.module.scss';

interface LanguageDropdownButtonProps {
  isActive?: boolean;
}

export const LanguageDropdownButton: FC<LanguageDropdownButtonProps> = ({
  isActive,
}) => {
  const { currentLanguage } = useLanguage();

  return (
    <Button
      className={classNames(styles.button, isActive && styles.activeButton)}
      theme={ButtonTheme.BlueGhost}
      size={ButtonSize.Small}
      linkStyled
    >
      <div className={styles.buttonContent}>
        <div className={styles.innerButtonContent}>
          <FlagIcon code={mapLanguageToCountryCode[currentLanguage]} />
          {mapLanguageToDisplayName(currentLanguage)}
        </div>
      </div>
    </Button>
  );
};
