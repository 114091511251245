import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PostbackEvents } from 'types/generated/gql';
import { CardParams } from '../types';

interface UseCreationCardsParams {
  mediaItemId: string;
  postbackEvents?: PostbackEvents | null;
  isPostbacksExisted: boolean;
}

export const useCreationCardsData = ({
  mediaItemId,
  postbackEvents,
  isPostbacksExisted,
}: UseCreationCardsParams) => {
  const [creationCards, setCreationCards] = useState<Array<CardParams>>([]);

  const removeCreationCard = (cardId: string) => {
    setCreationCards((prev) => prev.filter((card) => card.id !== cardId));
  };

  const addMoreCreationCard = () => {
    if (!postbackEvents) {
      return;
    }

    setCreationCards((prev) => [
      ...prev,
      {
        onCreationCompleted: removeCreationCard,
        onRemoveButtonClick: removeCreationCard,
        id: uuidv4(),
        mediaItemId,
        events: postbackEvents.availableEvents,
      },
    ]);
  };

  useEffect(() => {
    if (!postbackEvents || isPostbacksExisted) {
      return;
    }

    setCreationCards([
      {
        onCreationCompleted: removeCreationCard,
        onRemoveButtonClick: removeCreationCard,
        id: uuidv4(),
        mediaItemId,
        events: postbackEvents.availableEvents,
      },
    ]);
  }, [isPostbacksExisted, mediaItemId, postbackEvents]);

  // update creation card if postback events changes
  useEffect(() => {
    setCreationCards((prevCards) =>
      prevCards.map((prevCard) => ({
        ...prevCard,
        events: postbackEvents?.availableEvents ?? [],
      }))
    );
  }, [postbackEvents]);

  return {
    creationCards,
    addMoreCreationCard,
  };
};
