import { FC } from 'react';
import styles from './ErrorContent.module.scss';

interface ErrorContentProps {
  header: string;
  text?: string;
}

export const ErrorContent: FC<ErrorContentProps> = ({ header, text }) => (
  <>
    <div className={styles.header}>{header}</div>
    {text && <div className={styles.text}>{text}</div>}
  </>
);
