import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import styles from './TwoFactorCheckBlock.module.scss';
import { TwoFactorSendForm } from '../TwoFactorSendForm';

export const TwoFactorCheckBlock: FC = () => {
  const { t } = useTranslation(['common', 'auth']);

  return (
    <>
      <h1 className={SPEC_HEADER_LARGE}>{t('auth:login.content.2FA.title')}</h1>
      <div className={styles.textBlock}>
        <p>{t('auth:login.content.2FA.subtitle')}</p>
      </div>
      <TwoFactorSendForm
        disabledButtonTooltip={t(
          'auth:login.input.2FA.validation.codeRequired'
        )}
        inputLabel={t('auth:login.input.2FA.label.code')}
        buttonText={t('auth:login.action.2FA.button.login')}
      />
    </>
  );
};
