import { AuthStatuses, useAuth } from 'components/auth';

export const useTwoFactorRequired = () => {
  const { user } = useAuth();

  const isTwoFactorRequired =
    user.authStatus === AuthStatuses.TwoFactorAuthenticationRequired ||
    user.authStatus === AuthStatuses.TwoFactorGenerationRequired;

  return { isTwoFactorRequired };
};
