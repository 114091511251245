import { useReapplyPartnerApplicationMutation } from './useReapplyPartnerApplicationMutation';
import { useReapplyCompanyApplicationMutation } from './useReapplyCompanyApplicationMutation';
import { ApplicationTypeName } from '../types';

export const useReapplyApplication = (
  applicationTypeName: ApplicationTypeName
) => {
  const useReapplyDependOnApplicationTypeName =
    applicationTypeName === 'PartnerApplication'
      ? useReapplyPartnerApplicationMutation
      : useReapplyCompanyApplicationMutation;

  return useReapplyDependOnApplicationTypeName();
};
