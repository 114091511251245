import { FC } from 'react';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { EmptyDataBlock } from 'components/EmptyDataBlock';
import { useTranslation } from 'react-i18next';
import { TariffBasicData } from '../../queries/generated/TariffBasicData';
import { TariffCard } from '../TariffCard';
import styles from './TariffsBlock.module.scss';
import { useGetUserTariffsQuery } from './hooks/useGetUserTariffsQuery';

export const TariffsBlock: FC = () => {
  const { t } = useTranslation('tariffs');

  const { tariffs, loading, error } = useGetUserTariffsQuery();

  if (loading) {
    return <Loader shouldExcludeMenuWidth />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!tariffs || tariffs.length === 0) {
    return <EmptyDataBlock emptyDataText={t('content.placeholder.noData')} />;
  }

  return (
    <div className={styles.tariffList}>
      {tariffs.map((tariff: TariffBasicData) => (
        <TariffCard tariff={tariff} key={tariff.id} />
      ))}
    </div>
  );
};
