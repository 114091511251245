import * as SchemaTypes from '../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type MoneyAmountData = {
  __typename: 'MoneyAmount';
  currency: CommonType.Currency;
  value: number;
};

export const MoneyAmountData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MoneyAmountData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MoneyAmount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
