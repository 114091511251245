import { PostbackEvent } from 'types/generated/gql';
import { useGetMacrosByEvent } from '../queries/generated/GetMacrosByEvent';

interface UseGetMacrosByEventQueryParams {
  postbackEvent: PostbackEvent;
}

export const useGetMacrosByEventQuery = ({
  postbackEvent,
}: UseGetMacrosByEventQueryParams) => {
  const { data } = useGetMacrosByEvent({
    variables: {
      postbackEvent,
    },
  });

  return data?.postbackMacrosByPostbackEvent;
};
