import { useCallback } from 'react';
import { useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { toast } from 'ui/toast';

export const useActivateRequestHandlers = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  const handleCompleted = useCallback(async () => {
    try {
      await auth.refreshToken();
    } catch (error) {
      toast.error({
        header: 'Error',
        text: t('errors.commonError'),
        toastId: 'refreshTokenError',
      });
    }
  }, [auth, t]);

  const handleError = useCallback(async (error: ApolloError) => {
    toast.error({
      header: 'Error',
      text: error.message,
      toastId: 'activatePartnerError',
    });
  }, []);

  return {
    handleCompleted,
    handleError,
  };
};
