import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { ConfirmModal, ConfirmModalContextProvider } from 'ui/ConfirmModal';
import { ApplicationToAddPartnersModal } from '../components/ApplicationToAddPartnersModal';

export const useApplicationToAddPartnersModal = () => {
  const { t } = useTranslation(['common', 'companyPartners']);
  const { dispatch } = useModalContext();

  const openAddPartnerModal = useCallback(() => {
    dispatch(
      openModal({
        title: t('companyPartners:content.drawer.addPartner.header.title'),
        content: (
          <ConfirmModalContextProvider>
            <ApplicationToAddPartnersModal />
            <ConfirmModal
              content={{
                cancelButton: t('pageCloseConfirmModalContent.cancelButton'),
                confirmButton: t('pageCloseConfirmModalContent.confirmButton'),
                text: t('pageCloseConfirmModalContent.text'),
                title: t('pageCloseConfirmModalContent.title'),
              }}
            />
          </ConfirmModalContextProvider>
        ),
      })
    );
  }, [dispatch, t]);

  return {
    openAddPartnerModal,
  };
};
