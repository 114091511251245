import { t } from 'i18n';
import { DateFormat, formatDate } from 'utils/formatDate';
import { CountriesList } from 'components/CountriesList';
import { KeyValueGridRow } from 'ui/KeyValueGrid';
import { PaymentModel } from 'components/PaymentModel';
import { TariffTypeName, TranslationUserType } from 'types';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import {
  TariffForMediaData,
  TariffForMediaData_CpaTariff,
  TariffForMediaData_RevShareTariff,
} from '../../../../queries/fragments/generated/TariffForMediaData';

const getCpaTariffKeyValueGridRows = (
  tariff: TariffForMediaData_CpaTariff,
  translationUserType: TranslationUserType
): Array<KeyValueGridRow> => [
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.tariffName`
    ),
    value: tariff.name,
  },
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.paymentType`
    ),
    value: <PaymentModel tariffTypeName={TariffTypeName.CpaTariff} />,
  },
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.reward`
    ),
    value: getMoneyAmount(tariff.reward),
  },
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.baseline`
    ),
    value: getMoneyAmount(tariff.baseline),
  },
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.createdDate`
    ),
    value: formatDate(
      new Date(tariff.createdAt),
      DateFormat.FullDateWithoutHours
    ),
  },
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.location`
    ),
    value: <CountriesList countries={tariff.geolocation} />,
  },
];

const getRevShareTariffKeyValueGridRows = (
  tariff: TariffForMediaData_RevShareTariff,
  translationUserType: TranslationUserType
): Array<KeyValueGridRow> => [
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.tariffName`
    ),
    value: tariff.name,
  },
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.paymentType`
    ),
    value: <PaymentModel tariffTypeName={TariffTypeName.RevShareTariff} />,
  },
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.reward`
    ),
    value: `${tariff.rewardPercent} %`,
  },
  {
    title: t(
      `media:${translationUserType}.content.drawer.details.tariff.label.createdDate`
    ),
    value: formatDate(
      new Date(tariff.createdAt),
      DateFormat.FullDateWithoutHours
    ),
  },
];

export const getTariffKeyValueGridRowsByType = (
  tariff: TariffForMediaData,
  translationUserType: TranslationUserType
) => {
  if (tariff.__typename === TariffTypeName.CpaTariff) {
    return getCpaTariffKeyValueGridRows(tariff, translationUserType);
  }

  if (tariff.__typename === TariffTypeName.RevShareTariff) {
    return getRevShareTariffKeyValueGridRows(tariff, translationUserType);
  }

  throw new Error('Unknown tariff type');
};
