import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type ReapplyCompanyApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ReapplyCompanyApplicationInput;
}>;

export type ReapplyCompanyApplication = {
  __typename: 'Mutation';
  reapplyCompanyApplication: {
    __typename: 'ReapplyCompanyApplicationPayload';
    application: {
      __typename: 'CompanyApplication';
      declineReason?: string | null;
      id: string;
      number: string;
      status: SchemaTypes.CompanyApplicationStatus;
      trafficType?: string | null;
      contact: {
        __typename: 'Contact';
        type: SchemaTypes.ContactType;
        value: string;
      };
    };
  };
};

export const ReapplyCompanyApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReapplyCompanyApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReapplyCompanyApplicationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reapplyCompanyApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'declineReason' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trafficType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ReapplyCompanyApplicationMutationFn = Apollo.MutationFunction<
  ReapplyCompanyApplication,
  ReapplyCompanyApplicationVariables
>;

/**
 * __useReapplyCompanyApplication__
 *
 * To run a mutation, you first call `useReapplyCompanyApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReapplyCompanyApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reapplyCompanyApplication, { data, loading, error }] = useReapplyCompanyApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReapplyCompanyApplication(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReapplyCompanyApplication,
    ReapplyCompanyApplicationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ReapplyCompanyApplication,
    ReapplyCompanyApplicationVariables
  >(ReapplyCompanyApplicationDocument, options);
}
export type ReapplyCompanyApplicationHookResult = ReturnType<
  typeof useReapplyCompanyApplication
>;
export type ReapplyCompanyApplicationMutationResult =
  Apollo.MutationResult<ReapplyCompanyApplication>;
export type ReapplyCompanyApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    ReapplyCompanyApplication,
    ReapplyCompanyApplicationVariables
  >;
