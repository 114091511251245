import { FC } from 'react';
import { Outlet } from 'react-router';
import { GreetingBlock } from './components/GreetingBlock';
import styles from './AuthLayout.module.scss';

export const AuthLayout: FC = () => (
  <div className={styles.authLayout}>
    <div className={styles.content}>
      <Outlet />
    </div>
    <div className={styles.line} />
    <div className={styles.background}>
      <GreetingBlock />
    </div>
  </div>
);
