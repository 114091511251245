import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type RewardHistoryItemData = {
  __typename: 'Reward';
  createdAt: string;
  id: string;
  paymentModel: SchemaTypes.PaymentModel;
  paid: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
};

export const RewardHistoryItemData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RewardHistoryItemData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reward' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paid' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentModel' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
