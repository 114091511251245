import { useAuth } from 'components/auth';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface UseLogoutResult {
  isLoading: boolean;
  logout: () => Promise<void>;
}

export const useLogout = (): UseLogoutResult => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const logout = useCallback(async () => {
    setIsLoading(true);

    try {
      await auth.logOut();
    } catch (error) {
      toast.error(t('errors.commonError'));
    } finally {
      setIsLoading(false);
    }
  }, [auth, t]);

  return { isLoading, logout };
};
