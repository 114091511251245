import { isObject } from './isObject';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = Record<string, any>;

export const isDeepEqual = (
  object1: AnyObject,
  object2: AnyObject
): boolean => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) {
    return false;
  }

  return objKeys1.every((key) => {
    const value1 = object1[key];
    const value2 = object2[key];

    if (isObject(value1) && isObject(value2)) {
      return isDeepEqual(value1, value2);
    }

    return value1 === value2;
  });
};
