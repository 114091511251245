import { t } from 'i18n';
import { CommonPostbackStatus } from 'types/generated/gql';
import { ButtonTheme } from 'ui/Button';
import { TranslationUserType } from 'types';

export const mapPostbackStatusToButtonTheme: Record<
  CommonPostbackStatus,
  ButtonTheme
> = {
  [CommonPostbackStatus.NotConnected]: ButtonTheme.BlueGrey,
  [CommonPostbackStatus.Connected]: ButtonTheme.BlueGhost,
  [CommonPostbackStatus.Disconnected]: ButtonTheme.RedGhost,
  [CommonPostbackStatus.PartiallyDisconnected]: ButtonTheme.RedGhost,
};

export const mapPostbackStatusToTooltip = (
  status: CommonPostbackStatus,
  translationUserType: TranslationUserType
): string => {
  const map: Record<CommonPostbackStatus, string> = {
    [CommonPostbackStatus.NotConnected]: t(
      `media:${translationUserType}.content.dataTable.hint.postback.status1`
    ),
    [CommonPostbackStatus.Connected]: t(
      `media:${translationUserType}.content.dataTable.hint.postback.status2`
    ),
    [CommonPostbackStatus.Disconnected]: t(
      `media:${translationUserType}.content.dataTable.hint.postback.status3`
    ),
    [CommonPostbackStatus.PartiallyDisconnected]: t(
      `media:${translationUserType}.content.dataTable.hint.postback.status4`
    ),
  };

  return map[status];
};
