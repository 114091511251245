import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { AuthStatuses, useAuth } from 'components/auth';
import { AppRoute } from 'const';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';

export const PrivateRouteWrapper: FC = () => {
  const { user } = useAuth();
  const location = useLocation();

  const { createLocalizedPath } = useCreateLocalizedPath();

  const shouldNavigateToLogin = user.authStatus !== AuthStatuses.Authenticated;

  if (shouldNavigateToLogin) {
    return (
      <Navigate
        to={createLocalizedPath(AppRoute.Lang.Login)}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};
