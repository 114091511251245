import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetStatisticDetailedItemNameVariables = SchemaTypes.Exact<{
  mediaItemId: SchemaTypes.Scalars['ID']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetStatisticDetailedItemName = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        mediaItem:
          | { __typename: 'PromoCodeMediaItem'; id: string }
          | { __typename: 'ReferralLinkMediaItem'; name: string; id: string };
      }
    | {
        __typename: 'Partner';
        id: string;
        mediaItem:
          | { __typename: 'PromoCodeMediaItem'; id: string }
          | { __typename: 'ReferralLinkMediaItem'; name: string; id: string };
      };
};

export const GetStatisticDetailedItemNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStatisticDetailedItemName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mediaItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mediaItemId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'ReferralLinkMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mediaItemId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'ReferralLinkMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetStatisticDetailedItemName__
 *
 * To run a query within a React component, call `useGetStatisticDetailedItemName` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticDetailedItemName` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticDetailedItemName({
 *   variables: {
 *      mediaItemId: // value for 'mediaItemId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStatisticDetailedItemName(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetStatisticDetailedItemName,
    GetStatisticDetailedItemNameVariables
  > &
    (
      | { variables: GetStatisticDetailedItemNameVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetStatisticDetailedItemName,
    GetStatisticDetailedItemNameVariables
  >(GetStatisticDetailedItemNameDocument, options);
}
export function useGetStatisticDetailedItemNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetStatisticDetailedItemName,
    GetStatisticDetailedItemNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetStatisticDetailedItemName,
    GetStatisticDetailedItemNameVariables
  >(GetStatisticDetailedItemNameDocument, options);
}
export function useGetStatisticDetailedItemNameSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetStatisticDetailedItemName,
    GetStatisticDetailedItemNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetStatisticDetailedItemName,
    GetStatisticDetailedItemNameVariables
  >(GetStatisticDetailedItemNameDocument, options);
}
export type GetStatisticDetailedItemNameHookResult = ReturnType<
  typeof useGetStatisticDetailedItemName
>;
export type GetStatisticDetailedItemNameLazyQueryHookResult = ReturnType<
  typeof useGetStatisticDetailedItemNameLazyQuery
>;
export type GetStatisticDetailedItemNameSuspenseQueryHookResult = ReturnType<
  typeof useGetStatisticDetailedItemNameSuspenseQuery
>;
export type GetStatisticDetailedItemNameQueryResult = Apollo.QueryResult<
  GetStatisticDetailedItemName,
  GetStatisticDetailedItemNameVariables
>;
