import { components, GroupBase, InputProps } from 'react-select';
import { SelectOption } from '../../type';
import styles from './Input.module.scss';

export const Input = (
  props: InputProps<
    SelectOption<string>,
    false,
    GroupBase<SelectOption<string>>
  >
) => {
  const { isHidden } = props;

  if (isHidden) {
    return null;
  }

  return <components.Input size={1} className={styles.input} {...props} />;
};
