import { FC, useEffect, useMemo } from 'react';
import {
  FormBlockIndentBetweenRowsSize,
  FormBlockMarginSize,
  FormBlockWrapper,
} from 'ui/formItems';
import { useEditingIdsContext } from 'contexts/EditingIdsContext';
import { PostbackEvent } from 'types/generated/gql';
import { Form, useFormikContext } from 'formik';
import { BottomIndentSize, Card } from 'ui/Card';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { SelectsBlock } from './components/SelectsBlock';
import { UrlInput } from './components/UrlInput';
import { ButtonsBlock } from './components/ButtonsBlock';
import { CreationState, PostbackValues } from '../../types';
import styles from './PostbackForm.module.scss';

interface PostbackFormProps {
  id: string;
  creationState?: CreationState;
  events: Array<PostbackEvent>;
  isDeletePostbackLoading: boolean;
  shouldShowRemoveButton: boolean;
  onRemoveButtonClick: () => void;
  isConnected?: boolean;
  initialValues: PostbackValues;
}

export const PostbackForm: FC<PostbackFormProps> = ({
  id,
  creationState,
  events,
  isDeletePostbackLoading,
  shouldShowRemoveButton,
  onRemoveButtonClick,
  isConnected,
  initialValues,
}) => {
  const { t } = useTranslation(['common', 'media']);
  const { updateEditingElementsIds } = useEditingIdsContext();
  const translationUserType = useTranslationUserType();

  const { values, dirty, isValid, isSubmitting, resetForm } =
    useFormikContext<PostbackValues>();

  const subtitle = useMemo(
    () =>
      isConnected ? (
        t(
          `media:${translationUserType}.content.drawer.details.postback.card.header.status2`
        )
      ) : (
        <span className={styles.redSubTitle}>
          {t(
            `media:${translationUserType}.content.drawer.details.postback.card.header.status1`
          )}
        </span>
      ),
    [isConnected, t, translationUserType]
  );

  useEffect(() => {
    updateEditingElementsIds(id, dirty);

    return () => {
      updateEditingElementsIds(id, false);
    };
  }, [dirty, id, updateEditingElementsIds]);

  return (
    <Card
      bottomIndentSize={BottomIndentSize.Large}
      title={t(
        `media:${translationUserType}.content.drawer.details.postback.card.header.title`
      )}
      subTitle={!creationState && subtitle}
      headerRightBlock={
        !creationState &&
        !isSubmitting &&
        dirty && (
          <Button
            onClick={() =>
              resetForm({
                values: initialValues,
              })
            }
            size={ButtonSize.Small}
            theme={ButtonTheme.RedLight}
          >
            {t(
              `media:${translationUserType}.content.drawer.details.postback.action.button.card.cancel`
            )}
          </Button>
        )
      }
    >
      <Form
        data-testid={creationState ? 'creation-form' : 'edit-form'}
        noValidate
      >
        <FormBlockWrapper
          marginBottomSize={FormBlockMarginSize.Small}
          indentBetweenRowSize={FormBlockIndentBetweenRowsSize.Large}
        >
          <SelectsBlock
            creationState={creationState}
            events={events}
            isLoading={isSubmitting || isDeletePostbackLoading}
          />
          <UrlInput
            selectedPostback={values.event}
            isLoading={isSubmitting || isDeletePostbackLoading}
          />
        </FormBlockWrapper>
        <ButtonsBlock
          creationState={creationState}
          shouldShowRemoveButton={shouldShowRemoveButton}
          onRemoveButtonClick={onRemoveButtonClick}
          isDirty={dirty}
          isDeleteLoading={isDeletePostbackLoading}
          isSubmitting={isSubmitting}
          isValid={isValid}
        />
      </Form>
    </Card>
  );
};
