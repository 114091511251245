import * as SchemaTypes from '../../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type WithdrawalMethodsData = {
  __typename: 'GameAccountWithdrawalMethod';
  gameAccountNumber: string;
  status: SchemaTypes.GameAccountWithdrawalMethodStatus;
  id: string;
};

export const WithdrawalMethodsData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WithdrawalMethodsData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WithdrawalMethodInterface' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GameAccountWithdrawalMethod' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gameAccountNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
