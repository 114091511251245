import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type SwitchCompanyCurrentCurrencyAccountVariables = SchemaTypes.Exact<{
  input: SchemaTypes.SwitchCompanyCurrentCurrencyAccountInput;
}>;

export type SwitchCompanyCurrentCurrencyAccount = {
  __typename: 'Mutation';
  switchCompanyCurrentCurrencyAccount: {
    __typename: 'SwitchCompanyCurrentCurrencyAccountPayload';
    company: {
      __typename: 'Company';
      id: string;
      availableCurrencyAccounts: Array<{
        __typename: 'CurrencyAccount';
        amount?: number | null;
        currency: CommonType.Currency;
        id: string;
        isCurrent: boolean;
      } | null>;
    };
  };
};

export const SwitchCompanyCurrentCurrencyAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchCompanyCurrentCurrencyAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'SwitchCompanyCurrentCurrencyAccountInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'switchCompanyCurrentCurrencyAccount',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'availableCurrencyAccounts',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isCurrent' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SwitchCompanyCurrentCurrencyAccountMutationFn =
  Apollo.MutationFunction<
    SwitchCompanyCurrentCurrencyAccount,
    SwitchCompanyCurrentCurrencyAccountVariables
  >;

/**
 * __useSwitchCompanyCurrentCurrencyAccount__
 *
 * To run a mutation, you first call `useSwitchCompanyCurrentCurrencyAccount` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchCompanyCurrentCurrencyAccount` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchCompanyCurrentCurrencyAccount, { data, loading, error }] = useSwitchCompanyCurrentCurrencyAccount({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchCompanyCurrentCurrencyAccount(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SwitchCompanyCurrentCurrencyAccount,
    SwitchCompanyCurrentCurrencyAccountVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SwitchCompanyCurrentCurrencyAccount,
    SwitchCompanyCurrentCurrencyAccountVariables
  >(SwitchCompanyCurrentCurrencyAccountDocument, options);
}
export type SwitchCompanyCurrentCurrencyAccountHookResult = ReturnType<
  typeof useSwitchCompanyCurrentCurrencyAccount
>;
export type SwitchCompanyCurrentCurrencyAccountMutationResult =
  Apollo.MutationResult<SwitchCompanyCurrentCurrencyAccount>;
export type SwitchCompanyCurrentCurrencyAccountMutationOptions =
  Apollo.BaseMutationOptions<
    SwitchCompanyCurrentCurrencyAccount,
    SwitchCompanyCurrentCurrencyAccountVariables
  >;
