import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { CreateCompanyMediaFormValues } from './types';

export const validateCreateCompanyMediaForm = (
  values: CreateCompanyMediaFormValues
): FormikErrors<CreateCompanyMediaFormValues> =>
  getRequiredErrors(values, {
    mediaItemName: true,
    mediaCampaignId: true,
    ownerId: true,
    tariffId: true,
  });
