import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { mapBooleanToString } from 'helpers';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { CpaReferralLinkStatisticItemData } from '../query/fragments/generated/CpaReferralLinkStatisticItemData';
import { getReferralStatusToString } from '../helpers';
import { COLUMNS_SIZES } from '../../../const';

interface UseTestColumnsParams {
  link?: string;
}

export const useCpaStatisticDetailedColumns = ({
  link,
}: UseTestColumnsParams): Array<
  ColumnDef<CpaReferralLinkStatisticItemData>
> => {
  const { t } = useTranslation(['common', 'statistic']);
  const columnHelper = createColumnHelper<CpaReferralLinkStatisticItemData>();

  const translationUserType = useTranslationUserType();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<CpaReferralLinkStatisticItemData, any>> = [
    columnHelper.accessor('playerName', {
      header: t(
        `statistic:${translationUserType}.content.dataTable.cpa.mediaElement`
      ),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          title={row.original.playerName ?? '—'}
          subTitle={link}
        />
      ),
      enablePinning: true,
      size: COLUMNS_SIZES.mediaNameSize,
    }),
    columnHelper.accessor('referralStatus', {
      header: t(
        `statistic:${translationUserType}.content.dataTable.cpa.playerStatus`
      ),
      cell: ({ row }) =>
        getReferralStatusToString(translationUserType)[
          row.original.referralStatus
        ],
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.wasFD', {
      header: t(`statistic:${translationUserType}.content.dataTable.cpa.wasFD`),
      cell: ({ row }) => mapBooleanToString(row.original.statisticInfo.wasFD),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.depositsAll', {
      header: t(
        `statistic:${translationUserType}.content.dataTable.cpa.depositsAll`
      ),
      cell: ({ row }) => getMoneyAmount(row.original.statisticInfo.depositsAll),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.averageDeposit', {
      header: t(
        `statistic:${translationUserType}.content.dataTable.cpa.averageDeposit`
      ),
      cell: ({ row }) =>
        getMoneyAmount(row.original.statisticInfo.averageDeposit),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.redepositsAll', {
      header: t(
        `statistic:${translationUserType}.content.dataTable.cpa.redepositsAll`
      ),
      cell: ({ row }) =>
        getMoneyAmount(row.original.statisticInfo.redepositsAll),
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.numberOfRedeposits', {
      header: t(
        `statistic:${translationUserType}.content.dataTable.cpa.numberOfRedeposits`
      ),
      cell: ({ row }) => row.original.statisticInfo.numberOfRedeposits,
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.withdrawalsAll', {
      header: t(
        `statistic:${translationUserType}.content.dataTable.cpa.withdrawalsAll`
      ),
      cell: ({ row }) =>
        getMoneyAmount(row.original.statisticInfo.withdrawalsAll),
      minSize: COLUMNS_SIZES.default,
    }),
  ];

  return columns;
};
