import { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import logoSrc from 'assets/images/logo.svg';
import styles from './Logo.module.scss';

interface LogoProps {
  className?: string;
}

export const Logo: FC<LogoProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(className, styles.logoContainer)}>
      <img
        src={logoSrc}
        alt={t('logoAlt')}
        className={styles.logo}
        data-testid="logo"
      />
    </div>
  );
};
