import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { PaymentModel as PaymentModelType } from 'types/generated/gql';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { DateFormat, formatDate } from 'utils/formatDate';
import { PaymentModel } from 'components/PaymentModel';
import { TableCellAlign } from 'ui/Table';
import { COLUMNS_SIZES } from '../const';
import { RewardHistoryItemData } from '../queries/fragments/generated/RewardHistoryItemData';

export const useRewardHistoryItemsColumns = (): Array<
  ColumnDef<RewardHistoryItemData>
> => {
  const { t } = useTranslation(['common', 'payments']);
  const columnHelper = createColumnHelper<RewardHistoryItemData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<RewardHistoryItemData, any>> = [
    columnHelper.accessor('paymentModel', {
      header: t(
        'payments:partnerAndCompany.content.dataTable.rewards.column.paymentType'
      ),
      cell: ({ renderValue }) => (
        // TODO: will be refactor in https://eyeconweb.atlassian.net/browse/AMS-1475
        <PaymentModel
          tariffTypeName={
            renderValue() === PaymentModelType.RevShare
              ? 'RevShareTariff'
              : 'CpaTariff'
          }
        />
      ),
      minSize: COLUMNS_SIZES.paymentModalMinSize,
    }),
    columnHelper.accessor('createdAt', {
      header: t(
        'payments:partnerAndCompany.content.dataTable.rewards.column.date'
      ),
      cell: ({ renderValue }) =>
        formatDate(new Date(renderValue()), DateFormat.FullDateWithoutHours),
      size: COLUMNS_SIZES.dateSize,
    }),
    columnHelper.accessor('paid', {
      header: t(
        'payments:partnerAndCompany.content.dataTable.rewards.column.amount'
      ),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      meta: { align: TableCellAlign.Right },
      size: COLUMNS_SIZES.sumSize,
    }),
  ];

  return columns;
};
