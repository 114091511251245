import { t } from 'i18n';
import { SelectOption } from 'ui/formItems';

export const getCompanyOption = (
  companyName?: string,
  search?: string
): SelectOption | undefined => {
  if (!companyName) {
    return undefined;
  }

  const option = {
    label: companyName,
    value: null,
    labelRight: t('company'),
  };

  if (!search) {
    return option;
  }

  return companyName.toLowerCase().includes(search.toLowerCase())
    ? option
    : undefined;
};
