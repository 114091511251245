import * as SchemaTypes from '../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type TariffForMediaBasicData_CpaTariff = {
  __typename: 'CpaTariff';
  id: string;
  name: string;
};

export type TariffForMediaBasicData_RevShareTariff = {
  __typename: 'RevShareTariff';
  id: string;
  name: string;
};

export type TariffForMediaBasicData =
  | TariffForMediaBasicData_CpaTariff
  | TariffForMediaBasicData_RevShareTariff;

export const TariffForMediaBasicData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TariffForMediaBasicData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TariffInterface' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
