import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'ui/Icon';
import { ReactComponent as IconSpinner } from 'assets/icons/solid/Loader.svg';
import styles from './LoadingItem.module.scss';

export const LoadingItem: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.loadingItem}>
      <Icon iconComponent={IconSpinner} hasDefaultColor={false} spin />
      {t('loading')}
    </div>
  );
};
