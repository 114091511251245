import { components, ControlProps } from 'react-select';
import styles from './Control.module.scss';
import { SelectOption } from '../../type';

export const Control = ({
  children,
  ...props
}: ControlProps<SelectOption, false>) => (
  <components.Control className={styles.control} {...props}>
    {children}
  </components.Control>
);
