import { KeyboardEvent } from 'react';
import { HTMLInputTextareaElement } from '../types';

const specialKeys = new Set<KeyboardEvent['key']>([
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'Tab',
]);

const isSpecialKey = (
  event: KeyboardEvent<HTMLInputTextareaElement>
): boolean =>
  specialKeys.has(event.key) || event.ctrlKey || event.metaKey || event.altKey;

interface CheckShouldBlockInputParams {
  event: KeyboardEvent<HTMLInputTextareaElement>;
  acceptedCharsRegExp?: RegExp;
}

export const checkShouldBlockInput = ({
  event,
  acceptedCharsRegExp,
}: CheckShouldBlockInputParams) => {
  if (!acceptedCharsRegExp) {
    return false;
  }

  return (
    event.altKey ||
    (!isSpecialKey(event) && !acceptedCharsRegExp.test(event.key))
  );
};
