import { FC } from 'react';
import { useNavigate } from 'react-router';
import { SPEC_HEADER_LARGE, AppRoute } from 'const';
import { Button } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import styles from './PasswordChangeSuccessful.module.scss';

export const PasswordChangeSuccessful: FC = () => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const { createLocalizedPath } = useCreateLocalizedPath();

  const handleClick = () => {
    navigate(createLocalizedPath(AppRoute.Lang.Login));
  };

  return (
    <div className={styles.container}>
      <h1 className={SPEC_HEADER_LARGE}>
        {t('resetPassword.content.passwordSuccess.title')}
      </h1>
      <p className={styles.description}>
        {t('resetPassword.content.passwordSuccess.subtitle')}
      </p>
      <Button isFullWidth onClick={handleClick}>
        {t('resetPassword.action.passwordSuccess.button.login')}
      </Button>
    </div>
  );
};
