import { Dispatch, SetStateAction } from 'react';
import { DatePickerStates } from 'ui/DatePicker/types';
import { type ReactDatePickerCustomHeaderProps } from 'react-datepicker';

interface UseHeaderDataParams {
  datePickerStates: DatePickerStates;
  setDatePickerStates: Dispatch<SetStateAction<DatePickerStates>>;
  setSelectsRange: Dispatch<SetStateAction<boolean>>;
  datePickerCustomHeaderProps: ReactDatePickerCustomHeaderProps;
}

export const useHeaderData = ({
  datePickerStates,
  setDatePickerStates,
  setSelectsRange,
  datePickerCustomHeaderProps,
}: UseHeaderDataParams) => {
  const { showFullMonthYearPicker, showMonthYearPicker, showYearPicker } =
    datePickerStates;

  const {
    decreaseYear,
    decreaseMonth,
    increaseYear,
    increaseMonth,
    prevYearButtonDisabled,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    nextYearButtonDisabled,
  } = datePickerCustomHeaderProps;

  const handleMonthClick = () => {
    setDatePickerStates({
      showMonthYearPicker: true,
      showFullMonthYearPicker: true,
      showYearPicker: false,
    });
    setSelectsRange(false);
  };

  const handleYearClick = () => {
    setDatePickerStates({
      showMonthYearPicker: false,
      showFullMonthYearPicker: false,
      showYearPicker: true,
    });
    setSelectsRange(false);
  };

  const isRenderMonth =
    !showMonthYearPicker && !showFullMonthYearPicker && !showYearPicker;

  const handlePrevClick = () => {
    if (showFullMonthYearPicker && showMonthYearPicker) {
      decreaseYear();
    } else {
      decreaseMonth();
    }
  };

  const handleNextClick = () => {
    if (showFullMonthYearPicker && showMonthYearPicker) {
      increaseYear();
    } else {
      increaseMonth();
    }
  };

  const isDisablePrevButton =
    showFullMonthYearPicker && showMonthYearPicker
      ? prevYearButtonDisabled
      : prevMonthButtonDisabled;

  const isDisableNextButton =
    showFullMonthYearPicker && showMonthYearPicker
      ? nextYearButtonDisabled
      : nextMonthButtonDisabled;

  return {
    handlePrevClick,
    handleNextClick,
    isRenderMonth,
    isDisablePrevButton,
    isDisableNextButton,
    handleMonthClick,
    handleYearClick,
  };
};
