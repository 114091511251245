import { MouseEvent } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import classNames from 'classnames';
import { Breakpoints } from 'const';
import { getMinimalUnderBreakpoint } from 'helpers';
import { ReactComponent as ArrowIcon } from 'assets/icons/line/AltArrowDown.svg';
import { SelectOption } from '../../type';
import { Icon } from '../../../../../Icon';
import styles from './DropdownIndicator.module.scss';

export const DropdownIndicator = (
  props: DropdownIndicatorProps<SelectOption, false>
) => {
  const { selectProps } = props;

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        iconClassName={classNames(
          styles.icon,
          selectProps.menuIsOpen && !isMobile && styles.isOpen
        )}
        iconComponent={ArrowIcon}
        hasDefaultColor={false}
        onMouseDown={handleMouseDown}
      />
    </components.DropdownIndicator>
  );
};
