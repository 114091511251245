import { useTranslation } from 'react-i18next';
import { Card } from 'ui/Card';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { Skeleton } from 'ui/Skeleton';
import { CurrencyAccountDropdown } from './components/CurrencyAccountDropdown';
import { useGetAvailableCurrencyAccountsQuery } from './hooks/useGetAvailableCurrencyAccountsQuery';
import styles from './BalanceCard.module.scss';
import { BalanceCardBottomContent } from './components/BalanceCardBottomContent';

interface BalanceCardProps {
  className?: string;
}

export const BalanceCard = ({ className }: BalanceCardProps) => {
  const { t } = useTranslation('payments');

  const { availableCurrencyAccounts, loading, error, refetch } =
    useGetAvailableCurrencyAccountsQuery();

  const currentCurrencyAccount = availableCurrencyAccounts?.find(
    (availableCurrencyAccount) => availableCurrencyAccount?.isCurrent
  );

  return (
    <Card
      title={t('partnerAndCompany.content.widget.balance.content.title')}
      headerRightBlock={
        <CurrencyAccountDropdown
          currentCurrencyAccount={currentCurrencyAccount}
          availableCurrencyAccounts={availableCurrencyAccounts}
          loading={loading}
        />
      }
      hasHeaderSeparator={false}
      className={className}
    >
      <div className={styles.innerWrapper}>
        {loading || currentCurrencyAccount?.amount == null ? (
          <Skeleton width={285} height={48} />
        ) : (
          <div className={styles.balanceWrapper}>
            <p className={styles.balance}>{currentCurrencyAccount.amount}</p>
            <p className={styles.currency}>
              {getCurrencyNameWithSymbol(currentCurrencyAccount.currency)}
            </p>
          </div>
        )}
        <div className={styles.bottomCurrencyBlock}>
          <BalanceCardBottomContent
            currentCurrencyAccount={currentCurrencyAccount}
            availableCurrencyAccounts={availableCurrencyAccounts}
            isError={!!error}
            onRefetch={refetch}
            loading={loading}
          />
        </div>
      </div>
    </Card>
  );
};
