import { FC } from 'react';
import classNames from 'classnames';
import { Button, ButtonTextColor } from 'ui/Button';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { Icon, IconSize } from 'ui/Icon';
import { ReactComponent as CopyIcon } from 'assets/icons/line/Copy.svg';
import { useHandleCopyWithTooltipStatus } from 'hooks/useHandleCopyWithTooltipStatus';
import styles from './CopyText.module.scss';

interface CopyTextProps {
  children: string;
  tooltipText: string;
  justifyBetween?: boolean;
  containerClassName?: string;
}

export const CopyText: FC<CopyTextProps> = ({
  children,
  tooltipText,
  justifyBetween = false,
  containerClassName,
}) => {
  const { handleClick, isTooltipVisible } = useHandleCopyWithTooltipStatus({
    text: children,
  });

  return (
    <span className={classNames(styles.container, containerClassName)}>
      <Tooltip
        isOpen={isTooltipVisible}
        tooltipTheme={TooltipTheme.Light}
        tooltipContent={tooltipText}
        tooltipTriggerClassName={styles.tooltipContainer}
        placement="top"
      >
        <Button
          onClick={handleClick}
          linkStyled={{
            inline: true,
            color: ButtonTextColor.BlueGrey,
          }}
          className={styles.copyTextButton}
          textWrapperClassName={
            justifyBetween ? styles.withJustifyBetween : undefined
          }
        >
          {children}{' '}
          <Icon
            containerClassName={styles.icon}
            hasDefaultColor={false}
            iconComponent={CopyIcon}
            size={IconSize.Medium}
          />
        </Button>
      </Tooltip>
    </span>
  );
};
