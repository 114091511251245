import { t } from 'i18n';
import { StatusLabelSize, StatusLabelType } from 'ui/StatusLabel';
import {
  TransactionStatusLabelSize,
  TransactionStatusLabelType,
} from './const';

export const mapTypeToStatusLabelType: Record<
  TransactionStatusLabelType,
  StatusLabelType
> = {
  [TransactionStatusLabelType.Executed]: StatusLabelType.Success,
  [TransactionStatusLabelType.InProgress]: StatusLabelType.InProcess,
  [TransactionStatusLabelType.Error]: StatusLabelType.Error,
  [TransactionStatusLabelType.ExecutedPartially]: StatusLabelType.Warning,
};

export const mapTypeToText: Record<TransactionStatusLabelType, string> = {
  [TransactionStatusLabelType.Executed]: t('transactionStatus.executed'),
  [TransactionStatusLabelType.InProgress]: t('transactionStatus.inProgress'),
  [TransactionStatusLabelType.Error]: t('transactionStatus.error'),
  [TransactionStatusLabelType.ExecutedPartially]: t(
    'transactionStatus.executedPartially'
  ),
};

export const mapSizeToStatusLabelSize: Record<
  TransactionStatusLabelSize,
  StatusLabelSize
> = {
  [TransactionStatusLabelSize.Large]: StatusLabelSize.Large,
  [TransactionStatusLabelSize.Small]: StatusLabelSize.Small,
};
