import { CSSProperties, FC } from 'react';
import { calculateStyle } from './utils/calculateStyle';
import styles from './Skeleton.module.scss';

interface SkeletonProps {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}

export const Skeleton: FC<SkeletonProps> = ({ width, height }) => {
  const style = calculateStyle({ width, height });

  return (
    <div aria-label="skeleton" className={styles.skeleton} style={style} />
  );
};
