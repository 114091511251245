import { useAuth } from 'components/auth';
import { useGetCurrentCurrencyAccount } from 'queries/generated/GetCurrentCurrencyAccount';

export const useGetCurrentCurrencyAccountQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetCurrentCurrencyAccount({
    variables: {
      id: user.id,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    currentCurrencyAccount: data?.user.currentCurrencyAccount,
    loading,
    error,
  };
};
