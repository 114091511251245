import { ReferralLinkMediaItemData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemData';

export const getMediaItemOwnerToShow = (
  mediaItemOwner: ReferralLinkMediaItemData['owner']
): string | null => {
  if (mediaItemOwner.__typename === 'CompanyPartner') {
    return mediaItemOwner.username;
  }

  if (mediaItemOwner.__typename === 'Company') {
    return mediaItemOwner.name;
  }

  return null;
};
