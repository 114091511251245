import { ReactComponent as RevShareIcon } from 'assets/icons/line/Revshare.svg';
import { ReactComponent as CPAIcon } from 'assets/icons/line/CPA.svg';
import { IconComponent, TariffTypeName } from 'types';
import styles from './PaymentModel.module.scss';

export const mapTariffTypeToIcon: { [key in TariffTypeName]: IconComponent } = {
  [TariffTypeName.RevShareTariff]: RevShareIcon,
  [TariffTypeName.CpaTariff]: CPAIcon,
};

export const mapTariffTypeToClass: { [key in TariffTypeName]: string } = {
  [TariffTypeName.RevShareTariff]: styles.revShare,
  [TariffTypeName.CpaTariff]: styles.cpa,
};
