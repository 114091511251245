import { useAuth } from 'components/auth';
import { useCallback, useState } from 'react';
import { useActivatePartner } from '../queries/generated/ActivatePartner';
import { useActivateRequestHandlers } from './useActivateRequestHandlers';

export const useActivatePartnerMutation = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const { handleCompleted, handleError } = useActivateRequestHandlers();

  const [activatePartnerMutation] = useActivatePartner({
    variables: {
      input: {
        id: auth.user.id,
      },
    },
    onCompleted: async () => {
      await handleCompleted();
      setIsLoading(false);
    },
    onError: (error) => {
      handleError(error);
      setIsLoading(false);
    },
  });

  const activateUser = useCallback(() => {
    setIsLoading(true);
    activatePartnerMutation();
  }, [activatePartnerMutation]);

  return { isLoading, activateUser };
};
