import { Currency, UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useSwitchPartnerNextRewardCurrencyMutation } from './useSwitchPartnerNextRewardCurrencyMutation';
import { useSwitchCompanyNextRewardCurrencyMutation } from './useSwitchCompanyNextRewardCurrencyMutation';

interface UseSwitchNextRewardCurrencyParams {
  onCompleted: (currency: Currency) => void;
}

export const useSwitchNextRewardCurrency = ({
  onCompleted,
}: UseSwitchNextRewardCurrencyParams) => {
  const { userTypeName } = useGetUserTypeNameQuery();

  const useSwitchNextRewardCurrencyByUserType =
    userTypeName === UserTypeName.Partner
      ? useSwitchPartnerNextRewardCurrencyMutation
      : useSwitchCompanyNextRewardCurrencyMutation;

  return useSwitchNextRewardCurrencyByUserType(onCompleted);
};
