import { OptionProps } from 'react-select';
import classNames from 'classnames';
import type { SelectOption } from '../../type';
import styles from './Option.module.scss';

export const Option = ({
  data,
  className,
  isSelected,
  innerRef,
  innerProps,
}: OptionProps<SelectOption>) => {
  const { label, labelRight, leftElement } = data;

  return (
    <div
      data-testid={isSelected ? 'selected-option' : 'option'}
      ref={innerRef}
      className={classNames(
        className,
        styles.option,
        isSelected ? styles.selected : undefined
      )}
      {...innerProps}
    >
      {leftElement}
      <span className={styles.label}>{label}</span>
      <span className={styles.rightLabel}>{labelRight}</span>
    </div>
  );
};
