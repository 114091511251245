import { useAuth } from 'components/auth';
import { MediaItemTypeName } from 'types';
import { useGetStatisticDetailedItemName } from '../query/generated/GetStatisticDetailedItemName';

interface useGetStatisticDetailedItemNameQueryParams {
  mediaItemId?: string;
}

export const useGetStatisticDetailedItemNameQuery = ({
  mediaItemId,
}: useGetStatisticDetailedItemNameQueryParams) => {
  const { user } = useAuth();

  const { data, loading, error } = useGetStatisticDetailedItemName({
    variables: {
      userId: user.id,
      mediaItemId: mediaItemId ?? '',
    },
    fetchPolicy: 'cache-first',
    skip: !mediaItemId,
  });

  const statisticMediaItemName =
    data?.user.mediaItem?.__typename === MediaItemTypeName.ReferralLinkMediaItem
      ? data.user.mediaItem.name
      : undefined;

  return {
    statisticMediaItemName,
    loading,
    error,
  };
};
