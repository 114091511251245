import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SPEC_HEADER_LARGE } from 'const';
import { TextLink } from 'ui/TextLink';
import notFoundImage from 'assets/images/404.svg';
import styles from './NotFound.module.scss';

export const NotFound: FC = () => {
  const { t } = useTranslation('notFound');

  return (
    <>
      <h1 className={classNames(SPEC_HEADER_LARGE, styles.header)}>
        {t('title')}
      </h1>
      <p className={styles.text}>{t('subtitle')}</p>
      <img src={notFoundImage} alt={t('title')} className={styles.errorIcon} />
      <p className={styles.text}>
        {t('contactLabel')}
        <br />
        <TextLink to={`mailto:${t('contactEmail')}`}>
          {t('contactEmail')}
        </TextLink>
      </p>
    </>
  );
};
