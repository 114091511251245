import { FormikErrors } from 'formik';
import { t } from 'i18n';
import { checkEmailValidity } from 'utils/form/checkEmailValidity';
import { LoginValues } from './types';

export const validateLogin = (
  values: LoginValues
): FormikErrors<LoginValues> => {
  const errors: FormikErrors<LoginValues> = {};

  if (!values.email) {
    errors.email = t('auth:login.input.login.validation.emailRequired');
  } else if (!checkEmailValidity(values.email)) {
    errors.email = t('auth:login.input.login.validation.invalidEmail');
  }

  if (!values.password) {
    errors.password = t('auth:login.input.login.validation.passwordRequired');
  }

  return errors;
};
