import { createStore } from 'utils/createStore';
import { reducer } from './reducer';
import { ModalState } from './types';

const initialState: ModalState = {
  modals: [],
};

const [ModalContext, ModalProvider] = createStore(reducer, initialState);

export { ModalContext, ModalProvider };
