import type { FC } from 'react';
import { ReactComponent as MenuIcon } from 'assets/icons/line/Menu.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/line/Close.svg';
import classNames from 'classnames';
import { checkIsLanguage } from 'utils/language/checkIsLanguage';
import { MenuItemData } from './types';
import { MenuItem } from './components/MenuItem';
import { LanguageDropdownMenuItem } from './components/LanguageDropdownMenuItem';
import { Button, ButtonSize, ButtonTheme } from '../Button';
import { useMenuDrawer } from './hooks/useMenuDrawer';
import styles from './Menu.module.scss';

interface MenuProps {
  topMenuData: Array<MenuItemData>;
  bottomMenuData: Array<MenuItemData>;
  topImgSrc: string;
  topImgAlt: string;
}

export const Menu: FC<MenuProps> = ({
  topMenuData,
  bottomMenuData,
  topImgSrc,
  topImgAlt,
}) => {
  const { isOpen, toggleDrawer, closeDrawer } = useMenuDrawer();

  return (
    <nav data-testid="menu" className={styles.menu}>
      <div className={styles.menuContainer}>
        <div className={styles.topImgWrapper} data-testid="top-img-wrapper">
          <Button
            data-testid="drawer-button"
            onClick={toggleDrawer}
            className={styles.menuButton}
            theme={ButtonTheme.Grey}
            size={ButtonSize.Medium}
            iconConfig={{
              iconComponent: isOpen ? CloseIcon : MenuIcon,
            }}
          />
          <img
            src={topImgSrc}
            className={styles.topImg}
            data-testid="top-img"
            alt={topImgAlt}
          />
        </div>
        <div
          data-testid="menu-content"
          className={classNames(
            styles.contentWrapper,
            isOpen && styles.drawerOpen
          )}
        >
          <ul
            data-testid="top-menu-container"
            className={styles.topMenuContainer}
          >
            {topMenuData.map(
              (menuData) =>
                !menuData.isHidden && (
                  <li key={menuData.text}>
                    <MenuItem onClick={closeDrawer} menuItem={menuData} />
                  </li>
                )
            )}
          </ul>
          <ul data-testid="bottom-menu-container">
            {bottomMenuData.map((menuData) => {
              if (menuData.isHidden) return null;

              if (checkIsLanguage(menuData.text)) {
                return (
                  <LanguageDropdownMenuItem
                    key={menuData.text}
                    menuItem={menuData}
                  />
                );
              }

              return (
                <li key={menuData.text}>
                  <MenuItem onClick={closeDrawer} menuItem={menuData} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
