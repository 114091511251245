import { FC } from 'react';
import { NonNullableDateRange } from 'types';
import { StatisticType } from '../../../../const';
import { CpaStatisticTable } from '../CpaStatisticTable';
import { RevShareStatisticTable } from '../RevShareStatisticTable';

interface StatisticTableByTypeProps {
  statisticType: StatisticType;
  nonNullableDateRange: NonNullableDateRange;
}

export const StatisticTableByType: FC<StatisticTableByTypeProps> = ({
  nonNullableDateRange,
  statisticType,
}) => {
  const map: Record<StatisticType, JSX.Element> = {
    [StatisticType.Cpa]: (
      <CpaStatisticTable nonNullableDateRange={nonNullableDateRange} />
    ),
    [StatisticType.RevShare]: (
      <RevShareStatisticTable nonNullableDateRange={nonNullableDateRange} />
    ),
  };

  return map[statisticType];
};
