import { components, GroupBase, IndicatorsContainerProps } from 'react-select';
import { SelectOption } from '../../type';
import styles from './indicatorsContainer.module.scss';

export const IndicatorsContainer = (
  props: IndicatorsContainerProps<SelectOption, false, GroupBase<SelectOption>>
) => (
  <components.IndicatorsContainer
    className={styles.indicatorsContainer}
    {...props}
  />
);
