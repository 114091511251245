export enum FieldName {
  CompanyName = 'companyName',
  Website = 'website',
  ContactValue = 'contactValue',
  ContactType = 'contactType',
}

export const mapFiledNameToMaxLength = {
  [FieldName.Website]: 50,
  [FieldName.ContactValue]: 30,
} as const;
