import { useParams } from 'react-router';
import { checkIsLanguagePath } from 'utils/language/checkIsLanguagePath';
import { useLanguage } from 'contexts/LanguageContext';
import { AppRouteParams } from '../utils/tsUtils/extractParamsFromAppRoute';

export const useCreateLocalizedPath = () => {
  const { lang } = useParams<AppRouteParams['Lang']['root']>();
  const { currentLanguage } = useLanguage();

  return {
    createLocalizedPath: (path: string) =>
      `/${checkIsLanguagePath(lang) ? lang : currentLanguage}/${path}`,
  };
};
