import { FC } from 'react';
import { KeyValueGrid } from 'ui/KeyValueGrid';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { TariffForMediaData } from '../../../../queries/fragments/generated/TariffForMediaData';
import { getTariffKeyValueGridRowsByType } from './helpers';

interface MediaTariffProps {
  tariff: TariffForMediaData;
}

export const MediaTariff: FC<MediaTariffProps> = ({ tariff }) => {
  const translationUserType = useTranslationUserType();

  return (
    <KeyValueGrid
      data={getTariffKeyValueGridRowsByType(tariff, translationUserType)}
      shouldStackOnMd
    />
  );
};
