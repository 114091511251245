import { GraphQLRequest } from '@apollo/client';

export const apolloFetch: typeof fetch = (
  input: RequestInfo | URL,
  init?: RequestInit
) => {
  if (!init) {
    return fetch(input);
  }

  const body = JSON.parse(init.body?.toString() || '') as GraphQLRequest;
  const { operationName } = body;

  return fetch(`${input}?name=${operationName}`, init);
};
