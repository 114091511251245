import { FC, useState } from 'react';
import { VerticalLayout } from 'ui/VerticalLayout';
import { ReturnButton } from 'components/ReturnButton';
import { Footer } from 'components/Footer';
import { PasswordResetBlock } from './components/PasswordResetBlock';

export const PasswordReset: FC = () => {
  const [email, setEmail] = useState<string>('');

  return (
    <VerticalLayout
      topBlock={!email && <ReturnButton />}
      centralBlock={<PasswordResetBlock email={email} setEmail={setEmail} />}
      bottomBlock={<Footer />}
    />
  );
};
