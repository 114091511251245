import { useEffect, useMemo } from 'react';
import { UserPrivilege } from 'types/generated/userPrivilege';
import { Storage, AuthStorageKey } from 'utils/storage';
import { AuthContext } from './AuthContext';
import { defaultUser } from '../const';
import { useAuthUserData } from '../hooks/useAuthUserData';
import * as authApi from '../api/api';

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { user, setUser, updateUserData, accessToken } = useAuthUserData();
  const { authStorage } = Storage;

  const value = useMemo(() => {
    const logIn = async (identifier: string, password: string) => {
      const data = await authApi.getToken(identifier, password);

      if (data) {
        updateUserData(data);
        authStorage.setValue(AuthStorageKey.LoggedInKey, Date.now().toString());
      }
    };

    const logOut = () =>
      authApi.logOut().finally(() => {
        Storage.authStorage.clearAll();
        setUser(defaultUser);
      });

    const refreshToken = async () => {
      const data = await authApi.getRefreshToken();

      if (data) {
        updateUserData(data);
      }
    };

    const createTwoFactor = () => authApi.generateTwoFactor();

    const checkTwoFactor = async (authCode: string) => {
      const data = await authApi.checkTwoFactor(authCode);

      if (data) {
        updateUserData(data);
      }
    };

    const setPassword = (password: string, token: string) =>
      authApi.passwordSet(password, token);

    const areEveryGranted = (privileges?: Array<UserPrivilege>): boolean =>
      !privileges ||
      privileges.every((item) => user?.privileges.includes(item));

    const isSomeGranted = (privileges?: Array<UserPrivilege>): boolean =>
      !privileges || privileges.some((item) => user?.privileges.includes(item));

    return {
      user,
      accessToken,
      logIn,
      logOut,
      refreshToken,
      createTwoFactor,
      checkTwoFactor,
      setPassword,
      privileges: {
        areEveryGranted,
        isSomeGranted,
      },
    };
  }, [accessToken, authStorage, setUser, updateUserData, user]);

  useEffect(() => {
    // to support logging out from all windows
    const syncLogout = (event: StorageEvent) => {
      if (event.key === AuthStorageKey.LoggedInKey && !event.newValue) {
        value.logOut();
      }
    };

    window.addEventListener('storage', syncLogout);

    return () => {
      window.removeEventListener('storage', syncLogout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
