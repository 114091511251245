import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'ui/formItems';
import { useCurrencySelectOptions } from 'hooks/useCurrencySelectOptions';
import { TranslationUserType } from 'types';

interface CurrencySelectProps {
  name: string;
  isCompany?: boolean;
}

export const CurrencySelect: FC<CurrencySelectProps> = ({
  name,
  isCompany = false,
}) => {
  const { t } = useTranslation('auth');
  const { currencySelectOptions, error, loading } = useCurrencySelectOptions();

  const translationUserType = isCompany
    ? TranslationUserType.Company
    : TranslationUserType.Partner;

  return (
    <Select
      isLoadingError={!!error}
      isLoading={loading}
      label={t(
        `signup.${translationUserType}.input.registration.label.currency`
      )}
      formValidationProps={{ errorTooltipPlacement: 'right' }}
      name={name}
      options={currencySelectOptions}
    />
  );
};
