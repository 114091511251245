import { FC } from 'react';
import { useHandleCopyWithTooltipStatus } from 'hooks/useHandleCopyWithTooltipStatus';
import { useField } from 'formik';
import { ReactComponent as CopyIcon } from 'assets/icons/line/Copy.svg';
import { Input, InputProps } from '../Input/Input';

interface InputWithCopyProps extends Omit<InputProps, 'rightIconConfig'> {
  tooltipText: string;
}

export const InputWithCopy: FC<InputWithCopyProps> = ({
  tooltipText,
  ...inputProps
}) => {
  const { name } = inputProps;
  const [field] = useField(name);

  const { handleClick, isTooltipVisible } = useHandleCopyWithTooltipStatus({
    text: field.value,
  });

  return (
    <Input
      {...inputProps}
      rightIconConfig={
        field.value
          ? {
              icon: CopyIcon,
              onClick: handleClick,
              tooltip: {
                isTooltipVisible,
                tooltipText,
              },
            }
          : undefined
      }
    />
  );
};
