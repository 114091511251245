import { CompanyCpaStatisticItemData } from '../queries/fragments/generated/CompanyCpaStatisticItemData';

export const getStatisticCpaItemOwnerToShow = (
  CompanyCpaStatisticItemOwner: CompanyCpaStatisticItemData['owner']
): string => {
  if (CompanyCpaStatisticItemOwner.__typename === 'CompanyPartner') {
    return CompanyCpaStatisticItemOwner.username;
  }

  if (CompanyCpaStatisticItemOwner.__typename === 'Company') {
    return CompanyCpaStatisticItemOwner.name;
  }

  throw new Error('Unknown owner type');
};
