import { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { PostbackEvent } from 'types/generated/gql';
import { FieldName } from './const';
import { CreationState, PostbackValues } from '../../types';
import { validatePostbackForm } from './validation';
import { useCreatePostbackMutation } from './hooks/useCreatePostbackMutation';
import { useUpdatePostbackMutation } from './hooks/useUpdatePostbackMutation';
import { useDeletePostbackMutation } from './hooks/useDeletePostbackMutation';
import { PostbackForm } from '../PostbackForm';

const defaultValues: PostbackValues = {
  [FieldName.Event]: undefined,
  [FieldName.Method]: undefined,
  [FieldName.Url]: '',
};

interface PostbackFormProps {
  shouldShowRemoveButton: boolean;
  isConnected?: boolean;
  mediaItemId: string;
  id: string;
  events: Array<PostbackEvent>;
  initialValues?: PostbackValues;
  creationState?: CreationState;
  onRemoveButtonClick?: (cardId: string) => void;
  onCreationCompleted?: (cardId: string) => void;
}

export const Postback: FC<PostbackFormProps> = ({
  shouldShowRemoveButton,
  isConnected,
  mediaItemId,
  id,
  onRemoveButtonClick,
  events,
  creationState,
  initialValues,
  onCreationCompleted,
}) => {
  const { createPostback } = useCreatePostbackMutation({
    mediaItemId,
    onSendRequest: () => creationState?.setIsCreationInProcess?.(true),
    onCompleted: () => {
      onCreationCompleted?.(id);
      creationState?.setIsCreationInProcess?.(false);
    },
    onError: () => {
      creationState?.setIsCreationInProcess?.(false);
    },
  });

  const { updatePostback } = useUpdatePostbackMutation();

  const { deletePostback, loading: isDeletePostbackLoading } =
    useDeletePostbackMutation({
      id,
    });

  const handleRemoveButtonClick = () => {
    onRemoveButtonClick?.(id);

    if (!creationState) {
      deletePostback();
    }
  };

  const handleSubmit = async (
    values: PostbackValues,
    formikHelpers: FormikHelpers<PostbackValues>
  ) => {
    if (creationState) {
      return createPostback(values);
    }

    return updatePostback({
      resetForm: formikHelpers.resetForm,
      values: {
        postbackId: id,
        method: values.method,
        url: values.url,
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues || defaultValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validatePostbackForm}
      enableReinitialize
    >
      <PostbackForm
        id={id}
        events={events}
        creationState={creationState}
        isDeletePostbackLoading={isDeletePostbackLoading}
        onRemoveButtonClick={handleRemoveButtonClick}
        shouldShowRemoveButton={shouldShowRemoveButton}
        initialValues={initialValues || defaultValues}
        isConnected={isConnected}
      />
    </Formik>
  );
};
