import { FC, InputHTMLAttributes, useId, useRef } from 'react';
import classNames from 'classnames';
import styles from './input.module.scss';
import { useInputManagement } from '../../hooks/useInputManagement';
import { CommonTextFieldProps, inputIconConfig } from '../../types';
import { FormValidationWrapper } from '../FormValidationWrapper/FormValidationWrapper';
import { InputIcon } from '../InputIcon/InputIcon';
import { FormLabelWrapper } from '../FormLabelWrapper/FormLabelWrapper';

type OriginalInputAttributes = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'name'
>;

export interface InputProps
  extends CommonTextFieldProps,
    OriginalInputAttributes {
  invalidChars?: Array<string>;
  maxLength?: number;
  leftIconConfig?: inputIconConfig;
  rightIconConfig?: inputIconConfig;
}

export const Input: FC<InputProps> = ({
  name,
  label,
  wrapperClassName,
  inputClassName,
  maxLength,
  autoFocus,
  invalidChars,
  acceptedCharsRegExp,
  disabled,
  onChange,
  placeholder,
  leftIconConfig,
  rightIconConfig,
  formValidationProps,
  // need to use this props if you use Input without formik
  value,
  ...rest
}) => {
  const id = useId();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { eventProps, field, touched, error } = useInputManagement({
    name,
    inputRef,
    autoFocus,
    invalidChars,
    acceptedCharsRegExp,
    onChange,
    maxLength,
  });

  const isError = error && (touched || field.value);
  const isEmpty = !value?.toString() && !field.value?.toString();

  return (
    <FormValidationWrapper name={name} {...formValidationProps}>
      <FormLabelWrapper
        isEmpty={isEmpty}
        idForLabel={id}
        label={label}
        wrapperClassName={wrapperClassName}
        disabled={disabled}
        isError={isError}
        hasLeftIcon={Boolean(leftIconConfig?.icon)}
        hasRightIcon={Boolean(rightIconConfig?.icon)}
      >
        <InputIcon
          className={classNames(isError && styles.iconWithColor)}
          iconConfig={leftIconConfig}
        />
        {/**
         * The default HTML input field has a minimum width set by the browser, not by CSS styles.
         * If you try to set a min-width in CSS that's smaller than this browser default, it won't work.
         *
         * However, you can change the input's minimum width using the size attribute.
         * Size is a number that tells how many characters the input can show. The browser then adjusts the input's width based on this number.
         *
         * For example, if you set size=1, the input's width will be just enough for one character.
         * This way, you can make the input's width smaller than the browser's default setting.
         */}
        <input
          size={1}
          id={id}
          className={classNames(inputClassName, styles.input)}
          value={value || (field.value !== undefined ? field.value : '')}
          name={field.name}
          disabled={disabled}
          ref={inputRef}
          placeholder={placeholder || label}
          {...eventProps}
          {...rest}
        />
        <InputIcon
          className={classNames(isError && styles.iconWithColor)}
          iconConfig={rightIconConfig}
        />
      </FormLabelWrapper>
    </FormValidationWrapper>
  );
};
