import { useAuth } from 'components/auth';
import { useGetWithdrawalMethods } from '../queries/generated/GetWithdrawalMethods';

export const useGetWithdrawalMethodsQuery = () => {
  const { user } = useAuth();

  const { data, error, loading } = useGetWithdrawalMethods({
    variables: {
      userId: user.id,
    },
  });

  return {
    withdrawalMethods: data?.user.withdrawalMethods,
    error,
    loading,
  };
};
