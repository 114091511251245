import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CpaReferralLinkStatisticConnectionData } from './CpaReferralLinkStatisticConnectionData';
import { CpaReferralLinkStatisticItemData } from './CpaReferralLinkStatisticItemData';
import { CpaReferralLinkStatisticInfoData } from './CpaReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../queries/fragments/generated/PageInfoData';
export type CpaReferralLinkStatisticData_PromoCodeMediaItem = {
  __typename: 'PromoCodeMediaItem';
  id: string;
};

export type CpaReferralLinkStatisticData_ReferralLinkMediaItem = {
  __typename: 'ReferralLinkMediaItem';
  link: string;
  id: string;
  cpaStatistic: {
    __typename: 'CpaReferralLinkStatistic';
    statisticItems: {
      __typename: 'CpaReferralLinkStatisticConnection';
      edges: Array<{
        __typename: 'CpaReferralLinkStatisticEdge';
        cursor: string;
        node: {
          __typename: 'CpaReferralLinkStatisticItem';
          id: string;
          playerName?: string | null;
          referralStatus: SchemaTypes.ReferralStatus;
          statisticInfo: {
            __typename: 'CpaReferralLinkStatisticInfo';
            numberOfRedeposits: number;
            wasFD: boolean;
            averageDeposit: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            redepositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
          };
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        endCursor?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  };
};

export type CpaReferralLinkStatisticData =
  | CpaReferralLinkStatisticData_PromoCodeMediaItem
  | CpaReferralLinkStatisticData_ReferralLinkMediaItem;

export const CpaReferralLinkStatisticData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CpaReferralLinkStatisticData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MediaItemInterface' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReferralLinkMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cpaStatistic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statisticItems' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'end' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'end' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'first' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'start' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'start' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CpaReferralLinkStatisticConnectionData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
