import { useMemo } from 'react';
import { Currency } from 'types';
import { FlagIcon } from 'ui/FlagIcon';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { useGetAvailableCurrenciesQuery } from './queryHooks/useGetAvailableCurrenciesQuery';
import { Language } from '../types/generated/gql';
import { useLanguage } from '../contexts/LanguageContext';

interface MapCurrencyToSelectOptionsParams {
  currencies: Array<Currency>;
  language: Language;
}

export const mapCurrencyToSelectOptions = ({
  currencies,
  language,
}: MapCurrencyToSelectOptionsParams) =>
  currencies.map((currency) => ({
    label: getCurrencyDisplayName({ currencyCode: currency, language }),
    value: currency,
    leftElement: <FlagIcon code={currency} />,
    labelRight: getCurrencyNameWithSymbol(currency),
  }));

export const useCurrencySelectOptions = () => {
  const { currentLanguage } = useLanguage();

  const { availableCurrencies, loading, error } =
    useGetAvailableCurrenciesQuery();

  const currencySelectOptions = useMemo(
    () =>
      availableCurrencies
        ? mapCurrencyToSelectOptions({
            currencies: availableCurrencies,
            language: currentLanguage,
          })
        : [],
    [availableCurrencies, currentLanguage]
  );

  return { currencySelectOptions, loading, error };
};
