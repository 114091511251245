import { useAuth } from 'components/auth';
import { NonNullableDateRange, UserTypeName } from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useGetPartnerCpaStatisticTotal } from '../queries/generated/GetPartnerCpaStatisticTotal';

const { DateScalar } = DateFormat;

export const useGetPartnerCpaStatisticTotalQuery = ([
  start,
  end,
]: NonNullableDateRange) => {
  const { user } = useAuth();

  const { data, loading, error } = useGetPartnerCpaStatisticTotal({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      userId: user.id,
    },
    cacheFirstOnPop: true,
  });

  const totalStatistic =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.cpaStatistic.total
      : undefined;

  return { totalStatistic, loading, error };
};
