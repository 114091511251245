import { Language } from 'types/generated/gql';
import { FlagIcon } from 'ui/FlagIcon';
import { ListItem } from 'ui/List';
import { mapLanguageToCountryCode } from 'utils/language/mapLanguageToCountryCode';
import { mapLanguageToDisplayName } from 'utils/language/mapLanguageToDisplayName';
import { DropdownValue } from './types';

export const getDropdownValueByLanguage = (code: Language): DropdownValue => ({
  value: code,
  label: mapLanguageToDisplayName(code),
  icon: <FlagIcon code={mapLanguageToCountryCode[code]} />,
});

export const getListItemsByLanguageCodes = (
  onClick: (value?: Language) => void,
  languageList: Array<DropdownValue>
): Array<ListItem<Language>> => {
  if (!languageList?.length) {
    return [];
  }

  return languageList.map(({ value }) => ({
    label: mapLanguageToDisplayName(value),
    leftElement: <FlagIcon code={mapLanguageToCountryCode[value]} />,
    value,
    onClick,
  }));
};
