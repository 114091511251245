import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { REWARD_HISTORY_ITEMS_PER_PAGE } from '../const';
import { useGetRewardHistory } from '../queries/generated/GetRewardHistory';
import { RewardHistoryItemData } from '../queries/fragments/generated/RewardHistoryItemData';

export const useGetRewardHistoryQuery = () => {
  const { user } = useAuth();

  const { data, fetchMore, loading, error } = useGetRewardHistory({
    variables: {
      first: REWARD_HISTORY_ITEMS_PER_PAGE,
      userId: user.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const rewardHistoryItemsConnection = data?.user.rewardHistory;

  const rewardHistoryItems: Array<RewardHistoryItemData> | undefined = useMemo(
    () => rewardHistoryItemsConnection?.edges.map((item) => item.node),
    [rewardHistoryItemsConnection?.edges]
  );

  const pageInfo = rewardHistoryItemsConnection?.pageInfo;

  return { rewardHistoryItems, fetchMore, pageInfo, loading, error };
};
