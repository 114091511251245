import { FC } from 'react';
import { UserTypeName, NonNullableDateRange } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { CompanyRevShareStatisticTable } from './components/CompanyRevShareStatisticTable';
import { PartnerRevShareStatisticTable } from './components/PartnerRevShareStatisticTable';

interface RevShareStatisticTableProps {
  nonNullableDateRange: NonNullableDateRange;
}

export const RevShareStatisticTable: FC<RevShareStatisticTableProps> = ({
  nonNullableDateRange,
}) => {
  const { userTypeName } = useGetUserTypeNameQuery();

  return userTypeName === UserTypeName.Company ? (
    <CompanyRevShareStatisticTable
      nonNullableDateRange={nonNullableDateRange}
    />
  ) : (
    <PartnerRevShareStatisticTable
      nonNullableDateRange={nonNullableDateRange}
    />
  );
};
