import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router';
import { useConfirmModalContext } from 'ui/ConfirmModal';

export const useRouteChangeConfirmationModal = () => {
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] =
    useState(false);

  const { showConfirmation } = useConfirmModalContext();

  const blocker = useBlocker(() => shouldShowConfirmationModal);

  useEffect(() => {
    const confirmClose = async () => {
      if (blocker.state !== 'blocked') {
        return;
      }

      const shouldProceed = await showConfirmation();

      if (shouldProceed) {
        blocker.proceed?.();
      }
    };

    confirmClose();
  }, [blocker, showConfirmation]);

  return { setShouldShowConfirmationModal };
};
