import { useState } from 'react';

export const useLanguageDropdownButton = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleOpenChange = (isOpen?: boolean) => {
    if (isOpen !== undefined) {
      setIsActive(isOpen);
    }
  };

  return {
    isActive,
    onOpenChange: handleOpenChange,
  };
};
