import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreatePartnerReferralLinkMediaItemVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreatePartnerReferralLinkMediaItemInput;
}>;

export type CreatePartnerReferralLinkMediaItem = {
  __typename: 'Mutation';
  createPartnerReferralLinkMediaItem: {
    __typename: 'CreatePartnerReferralLinkMediaItemPayload';
    referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem'; id: string };
  };
};

export const CreatePartnerReferralLinkMediaItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePartnerReferralLinkMediaItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePartnerReferralLinkMediaItemInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPartnerReferralLinkMediaItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLinkMediaItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreatePartnerReferralLinkMediaItemMutationFn =
  Apollo.MutationFunction<
    CreatePartnerReferralLinkMediaItem,
    CreatePartnerReferralLinkMediaItemVariables
  >;

/**
 * __useCreatePartnerReferralLinkMediaItem__
 *
 * To run a mutation, you first call `useCreatePartnerReferralLinkMediaItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerReferralLinkMediaItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerReferralLinkMediaItem, { data, loading, error }] = useCreatePartnerReferralLinkMediaItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerReferralLinkMediaItem(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePartnerReferralLinkMediaItem,
    CreatePartnerReferralLinkMediaItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePartnerReferralLinkMediaItem,
    CreatePartnerReferralLinkMediaItemVariables
  >(CreatePartnerReferralLinkMediaItemDocument, options);
}
export type CreatePartnerReferralLinkMediaItemHookResult = ReturnType<
  typeof useCreatePartnerReferralLinkMediaItem
>;
export type CreatePartnerReferralLinkMediaItemMutationResult =
  Apollo.MutationResult<CreatePartnerReferralLinkMediaItem>;
export type CreatePartnerReferralLinkMediaItemMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePartnerReferralLinkMediaItem,
    CreatePartnerReferralLinkMediaItemVariables
  >;
