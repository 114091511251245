import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useAuth } from 'components/auth';
import { Contact } from 'types/generated/gql';
import { FieldName } from './const';
import { validateProfileIdentities } from './validation';
import { PartnerIdentitiesValues } from './types';
import { useUpdatePartnerIdentitiesMutation } from './hooks/useUpdatePartnerIdentitiesMutation';
import { PartnerProfileFormContent } from './components/PartnerProfileFormContent';

interface PartnerProfileFormProps {
  contact: Contact;
  firstName?: string | null;
  lastName?: string | null;
  birthday?: string | null;
  onDirtyChange: (dirty: boolean) => void;
}

export const PartnerProfileForm: FC<PartnerProfileFormProps> = ({
  contact,
  firstName,
  lastName,
  birthday,
  onDirtyChange,
}) => {
  const auth = useAuth();
  const partnerId = auth.user.id;

  const convertedBirthdayDate = birthday ? new Date(birthday) : null;

  const { updatePartnerIdentities, loading } =
    useUpdatePartnerIdentitiesMutation({
      partnerId,
    });

  const initialValues: PartnerIdentitiesValues = {
    [FieldName.FirstName]: firstName || '',
    [FieldName.LastName]: lastName || '',
    [FieldName.Birthday]: convertedBirthdayDate,
    [FieldName.ContactType]: contact.type,
    [FieldName.ContactValue]: contact.value,
  };

  const handleSubmit = (
    formValues: PartnerIdentitiesValues,
    formikHelpers: FormikHelpers<PartnerIdentitiesValues>
  ) => {
    updatePartnerIdentities(formValues, () => {
      formikHelpers.resetForm({
        values: formValues,
      });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validateProfileIdentities}
    >
      <PartnerProfileFormContent
        loading={loading}
        onDirtyChange={onDirtyChange}
      />
    </Formik>
  );
};
