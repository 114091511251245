import { useCallback } from 'react';
import { ListAllOperations } from 'types/generated/gql';
import { toast } from 'ui/toast';
import { useDeletePostback } from '../../../queries/generated/DeletePostback';

interface UseDeletePostbackMutationParams {
  id: string;
}

export const useDeletePostbackMutation = ({
  id,
}: UseDeletePostbackMutationParams) => {
  const [deletePostbackMutation, { loading }] = useDeletePostback({
    variables: {
      input: {
        id,
      },
    },
    refetchQueries: [ListAllOperations.Query.GetPostbackInfo],
    awaitRefetchQueries: true,
  });

  const deletePostback = useCallback(() => {
    deletePostbackMutation({
      onError: (error) => {
        toast.error({
          header: 'Error',
          text: error.message,
        });
      },
    });
  }, [deletePostbackMutation]);

  return {
    deletePostback,
    loading,
  };
};
