import { CompanyRevShareStatisticItemData } from '../queries/fragments/generated/CompanyRevShareStatisticItemData';

export const getStatisticRevShareItemOwnerToShow = (
  CompanyRevShareStatisticItemOwner: CompanyRevShareStatisticItemData['owner']
): string => {
  if (CompanyRevShareStatisticItemOwner.__typename === 'CompanyPartner') {
    return CompanyRevShareStatisticItemOwner.username;
  }

  if (CompanyRevShareStatisticItemOwner.__typename === 'Company') {
    return CompanyRevShareStatisticItemOwner.name;
  }

  throw new Error('Unknown owner type');
};
