import { Currency } from 'types/common';
import { defaultLanguageCode } from 'const';
import { Language } from 'types/generated/gql';
import { capitalize } from '../capitalize';

interface GetCurrencyDisplayNameParams {
  currencyCode: Currency;
  language?: Language;
  shouldCapitalize?: boolean;
}

export const getCurrencyDisplayName = ({
  currencyCode,
  language = defaultLanguageCode,
  shouldCapitalize = true,
}: GetCurrencyDisplayNameParams): string => {
  const currencyNames = new Intl.DisplayNames(language, {
    type: 'currency',
  });

  let currencyName: string;

  try {
    currencyName = currencyNames.of(currencyCode) || currencyCode;
  } catch {
    currencyName = currencyCode;
  }

  return shouldCapitalize ? capitalize(currencyName) : currencyName;
};
