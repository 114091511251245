import { useField } from 'formik';
import { FC } from 'react';
import { PostbackEvent } from 'types/generated/gql';
import { List } from 'ui/List';
import { Dropdown } from 'ui/Dropdown';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { FieldName } from '../../../../../Postback/const';
import { useGetMacrosByEventQuery } from './hooks/useGetMacrosByEventQuery';
import { getListItemByMacros } from './helpers';
import styles from './MacrosDropdown.module.scss';

interface MacrosDropdownProps {
  isOpen: boolean;
  selectedPostback: PostbackEvent;
}

export const MacrosDropdown: FC<MacrosDropdownProps> = ({
  isOpen,
  selectedPostback,
}) => {
  const [field, , helpers] = useField<string>(FieldName.Url);
  const translationUserType = useTranslationUserType();

  const macros = useGetMacrosByEventQuery({ postbackEvent: selectedPostback });

  const handleListItemItemClick = (value?: string) => {
    if (!value) {
      return;
    }

    helpers.setValue(field.value + value);
  };

  if (!macros?.length) {
    return null;
  }

  /**
   * TODO: replace `Dropdown` when URL input component is implemented
   * Issue https://eyeconweb.atlassian.net/browse/AMS-1859
   */
  return (
    <Dropdown
      isOpenForced={isOpen}
      withStyleMaxContent={false}
      title=""
      withBottomSheet={false}
    >
      <List
        listContainerClassName={styles.listContainer}
        highlightOnHover
        items={getListItemByMacros(
          macros,
          handleListItemItemClick,
          translationUserType
        )}
      />
    </Dropdown>
  );
};
