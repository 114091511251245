import { t } from 'i18next';
import { FormikErrors } from 'formik';
import { checkIsValidUrl } from 'utils/checkIsValidUrl';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { CompanyIdentitiesValues } from './types';
import { FieldName } from './const';

export const validateProfileIdentities = (
  values: CompanyIdentitiesValues
): FormikErrors<CompanyIdentitiesValues> => {
  const errors: FormikErrors<CompanyIdentitiesValues> = {};

  if (values.website && !checkIsValidUrl(values.website)) {
    errors.website = t('errors.incorrectFormat');
  }

  const requiredErrors = getRequiredErrors(values, {
    [FieldName.ContactType]: true,
    [FieldName.ContactValue]: true,
  });

  return {
    ...errors,
    ...requiredErrors,
  };
};
