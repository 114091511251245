import type { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button, ButtonTextColor } from 'ui/Button';

interface TextLinkProps extends LinkProps {
  isImportant?: boolean;
}

export const TextLink: FC<TextLinkProps> = ({
  children,
  isImportant,
  ...restProps
}) => (
  <Link {...restProps}>
    <Button
      linkStyled={{
        inline: true,
        color: isImportant ? ButtonTextColor.Red : undefined,
      }}
    >
      {children}
    </Button>
  </Link>
);
