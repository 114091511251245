import { OperationVariables } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { NavigationType, useNavigationType } from 'react-router';
import { QueryHookOptions, UseQueryReturn } from '../types';
import { usePaginationData } from './usePaginationData';
import { checkHasFirstInVariables } from '../helpers';

export const useQuery = <
  TData,
  TVariables extends OperationVariables = Apollo.OperationVariables,
>(
  query: Apollo.DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): UseQueryReturn<TData, TVariables> => {
  /**
   * NavigationType is === 'pop' if user use back and forward browser buttons.
   * If this so we need to show data from cache firstly
   */
  const navigationType = useNavigationType();

  const shouldUseCacheFirst =
    options?.cacheFirstOnPop && navigationType === NavigationType.Pop;

  const updatedFetchPolicy = shouldUseCacheFirst
    ? 'cache-first'
    : options?.fetchPolicy;

  const {
    fetchMore: originalFetchMore,
    data: originalData,
    ...rest
  } = Apollo.useQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: updatedFetchPolicy,
  });

  const perPage = checkHasFirstInVariables(options?.variables)
    ? options?.variables.first
    : undefined;

  // is fetchPolicy is not defined, it is cache-and-network by default
  // see in the file useApolloClient.ts
  const shouldSlice =
    !updatedFetchPolicy || updatedFetchPolicy === 'cache-and-network';

  const { data, fetchMore, isSliced } = usePaginationData({
    shouldSlice,
    originalData,
    originalFetchMore,
    perPage,
  });

  return {
    ...rest,
    data,
    fetchMore,
    isSliced,
  };
};
