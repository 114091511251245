import type { FC, ReactElement } from 'react';
import styles from './MayBeNullCell.module.scss';

interface MayBeNullCellProps {
  value?: ReactElement | string | number | null;
  emptyText?: string;
}

export const MayBeNullCell: FC<MayBeNullCellProps> = ({
  value,
  emptyText = '—',
}) => {
  if (value) {
    return <div>{value}</div>;
  }

  return <span className={styles.noValue}>{emptyText}</span>;
};
