import { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { setDefaultOptions } from 'date-fns';
import { addBasePath } from 'utils/routing/addBasePath';
import { checkIsLanguagePath } from 'utils/language/checkIsLanguagePath';
import { AppRouteParams } from 'utils/tsUtils/extractParamsFromAppRoute';
import { mapLanguageToPath } from 'utils/language/mapLanguageToPath';
import { mapLanguagePathToLanguage } from 'utils/language/mapLanguagePathToLanguage';
import { LanguageContextProvider } from 'contexts/LanguageContext';
import { Loader } from 'ui/Loader';
import { defaultLanguageCode } from 'const';
import { mapLanguageToDateFnsLocale } from './helpers';
import { useGetPreferredLanguageQuery } from './hooks/useGetPreferredLanguageQuery';

export const LanguageWrapper: FC = () => {
  const { i18n } = useTranslation();
  const { lang: languagePath } = useParams<AppRouteParams['Lang']['root']>();
  const { pathname } = useLocation();

  const { preferredLanguage, error, loading } = useGetPreferredLanguageQuery();

  useEffect(() => {
    if (!checkIsLanguagePath(languagePath)) {
      return;
    }

    i18n.changeLanguage(mapLanguagePathToLanguage(languagePath));

    setDefaultOptions({
      locale:
        mapLanguageToDateFnsLocale[mapLanguagePathToLanguage(languagePath)],
    });
  }, [i18n, languagePath]);

  if (loading) {
    return <Loader />;
  }

  const resolvedPreferredLanguage =
    error || !preferredLanguage ? defaultLanguageCode : preferredLanguage;

  if (!languagePath) {
    return <Navigate to={`/${mapLanguageToPath(resolvedPreferredLanguage)}`} />;
  }

  if (!checkIsLanguagePath(languagePath)) {
    return <Navigate to={addBasePath(pathname, resolvedPreferredLanguage)} />;
  }

  return (
    <LanguageContextProvider
      preferredLanguage={resolvedPreferredLanguage}
      currentLanguage={mapLanguagePathToLanguage(languagePath)}
    >
      <Outlet />
    </LanguageContextProvider>
  );
};
