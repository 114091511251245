import { useEffect, useRef, FC, PropsWithChildren } from 'react';

interface Props {
  className?: string;
  callback: (isClickedOutside: boolean) => void;
}

const ClickOutside: FC<PropsWithChildren<Props>> = ({
  className,
  children,
  callback,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // TODO: add proper types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      callback(!ref.current?.contains(e.target));
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [callback]);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};

export default ClickOutside;
