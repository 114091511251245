import { FC, useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AppRoute } from 'const';
import {
  FormBlockMarginSize,
  FormBlockWrapper,
  Input,
  InputPassword,
} from 'ui/formItems';
import { Button } from 'ui/Button';
import { TextWithLink } from 'ui/TextWithLink';
import { useAuth } from 'components/auth';
import { checkIsAuthError } from 'components/auth/helpers';
import { TextLink } from 'ui/TextLink';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { LoginValues } from './types';
import { validateLogin } from './validation';
import styles from './LoginForm.module.scss';

export const LoginForm: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const { createLocalizedPath } = useCreateLocalizedPath();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (values: LoginValues, actions: FormikHelpers<LoginValues>) => {
      try {
        setIsLoading(true);
        await auth.logIn(values.email, values.password);
      } catch (error) {
        if (checkIsAuthError(error) && error.status === 401) {
          actions.setErrors({
            email: t('auth:login.input.login.validation.invalidEmail'),
            password: t('auth:login.input.login.validation.invalidPassword'),
          });

          return;
        }

        toast.error(t('errors.commonError'));
      } finally {
        setIsLoading(false);
      }
    },
    [auth, t]
  );

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validateOnChange={false}
        /**
         * We cannot validate on blur because the errors from server will be reset
         * see: https://github.com/jaredpalmer/formik/issues/834
         */
        validateOnBlur={false}
        validate={validateLogin}
      >
        <Form className={styles.form} data-testid="login-form" noValidate>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Small}>
            <Input
              type="email"
              name="email"
              label={t('auth:login.input.login.label.email')}
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              autoComplete="email"
            />
            <InputPassword
              name="password"
              label={t('auth:login.input.login.label.password')}
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              autoComplete="current-password"
            />
          </FormBlockWrapper>
          <div className={styles.passwordSetting}>
            <TextLink
              to={createLocalizedPath(AppRoute.Lang.PasswordReset)}
              className={styles.forgetPasswordLink}
            >
              {t('auth:login.action.login.button.forgotPassword')}
            </TextLink>
          </div>
          <Button isFullWidth isLoading={isLoading} type="submit">
            {t('auth:login.action.login.button.login')}
          </Button>
        </Form>
      </Formik>
      <TextWithLink
        text={t('auth:login.action.accountType.button.label.noAccount')}
        linkText={t('auth:login.action.accountType.button.button.signUp')}
        to={createLocalizedPath(AppRoute.Lang.Registration)}
      />
    </div>
  );
};
