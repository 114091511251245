import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCurrentRewardPeriodVariables = SchemaTypes.Exact<{
  [key: string]: never;
}>;

export type GetCurrentRewardPeriod = {
  __typename: 'Query';
  currentRewardPeriod: { __typename: 'DateRange'; end: string; start: string };
};

export const GetCurrentRewardPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentRewardPeriod' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentRewardPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCurrentRewardPeriod__
 *
 * To run a query within a React component, call `useGetCurrentRewardPeriod` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRewardPeriod` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRewardPeriod({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentRewardPeriod(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCurrentRewardPeriod,
    GetCurrentRewardPeriodVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCurrentRewardPeriod,
    GetCurrentRewardPeriodVariables
  >(GetCurrentRewardPeriodDocument, options);
}
export function useGetCurrentRewardPeriodLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCurrentRewardPeriod,
    GetCurrentRewardPeriodVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCurrentRewardPeriod,
    GetCurrentRewardPeriodVariables
  >(GetCurrentRewardPeriodDocument, options);
}
export function useGetCurrentRewardPeriodSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetCurrentRewardPeriod,
    GetCurrentRewardPeriodVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetCurrentRewardPeriod,
    GetCurrentRewardPeriodVariables
  >(GetCurrentRewardPeriodDocument, options);
}
export type GetCurrentRewardPeriodHookResult = ReturnType<
  typeof useGetCurrentRewardPeriod
>;
export type GetCurrentRewardPeriodLazyQueryHookResult = ReturnType<
  typeof useGetCurrentRewardPeriodLazyQuery
>;
export type GetCurrentRewardPeriodSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentRewardPeriodSuspenseQuery
>;
export type GetCurrentRewardPeriodQueryResult = Apollo.QueryResult<
  GetCurrentRewardPeriod,
  GetCurrentRewardPeriodVariables
>;
