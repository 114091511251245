import { FC, useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import { useGetCompanyNameQuery } from 'hooks/useGetCompanyNameQuery';
import { SearchMode, Select } from 'ui/formItems';
import { useTranslation } from 'react-i18next';
import { useGetCompanyPartnersForMediaQuery } from './hooks/useGetCompanyPartnersForMediaQuery';
import { FieldName } from '../../const';
import { getCompanyOption } from './helpers';
import { getCompanyPartnersOptions } from './helpers/getCompanyPartnersOptions';

export const CompanyPartnersSelect: FC = () => {
  const [search, setSearch] = useState<string | undefined>();
  const debouncedSetSearch = useDebounce(setSearch);

  const { t } = useTranslation('media');

  const {
    companyPartners,
    pageInfo,
    fetchMore,
    loading: companyPartnersLoading,
    error: companyPartnersError,
    refetch,
  } = useGetCompanyPartnersForMediaQuery(search);

  const {
    companyName,
    error: companyNameError,
    loading: companyNameLoading,
  } = useGetCompanyNameQuery();

  const handleScrollToBottom = useCallback(() => {
    if (
      !pageInfo?.hasNextPage ||
      companyPartnersError ||
      companyPartnersLoading
    ) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    });
  }, [
    companyPartnersError,
    fetchMore,
    companyPartnersLoading,
    pageInfo?.endCursor,
    pageInfo?.hasNextPage,
  ]);

  const handleInputChange = (value: string) => {
    debouncedSetSearch(value);
  };

  const options = useMemo(() => {
    const companyOption = getCompanyOption(companyName, search);
    const companyPartnersOptions = getCompanyPartnersOptions(companyPartners);

    return [
      ...(companyOption ? [companyOption] : []),
      ...companyPartnersOptions,
    ];
  }, [companyName, companyPartners, search]);

  const isLoadingError =
    !!companyNameError ||
    !!companyPartnersError ||
    (!options?.length && !companyPartnersLoading);

  return (
    <Select
      onInputChange={handleInputChange}
      label={t('company.content.drawer.createMedia.input.label.owner')}
      name={FieldName.OwnerId}
      searchMode={SearchMode.Custom}
      options={options}
      isLoading={companyPartnersLoading || companyNameLoading}
      isLoadingError={isLoadingError}
      loadingErrorCallback={() => {
        refetch();
      }}
      onScrollToBottom={handleScrollToBottom}
    />
  );
};
