import { Dispatch, SetStateAction, useMemo } from 'react';
import { debounce } from 'utils/debounce';

const DEBOUNCE_TIMEOUT = 300;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends Dispatch<SetStateAction<any>>>(
  callback: T
): T => {
  const debouncedSetSearch = useMemo(
    () => debounce(callback, DEBOUNCE_TIMEOUT),
    [callback]
  );

  return debouncedSetSearch;
};
