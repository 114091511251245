import { useTranslation } from 'react-i18next';
import { Input } from 'ui/formItems';
import { FC } from 'react';
import { Form, Formik } from 'formik';
import { FieldName } from './const';
import styles from './DeletedGameAccountModal.module.scss';
import { ACCOUNT_NUMBER_MAX_LENGTH } from '../WithdrawRewardModal/const';

interface PendingGameAccountModalProps {
  accountNumber: string;
}

export const DeletedGameAccountModal: FC<PendingGameAccountModalProps> = ({
  accountNumber,
}) => {
  const { t } = useTranslation('payments');

  return (
    <>
      <div className={styles.description}>
        <p>
          {t('partnerAndCompany.content.drawer.deleteMethod.header.subtitle')}
        </p>
      </div>
      <Formik
        initialValues={{
          [FieldName.AccountNumber]: accountNumber,
        }}
        onSubmit={() => undefined}
      >
        <Form>
          <Input
            name={FieldName.AccountNumber}
            label={t(
              'partnerAndCompany.content.drawer.deleteMethod.input.label.accountNumber'
            )}
            maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
            disabled
          />
        </Form>
      </Formik>
    </>
  );
};
