import { Country } from 'types';
import { defaultLanguageCode } from 'const';
import { Language } from 'types/generated/gql';

interface GetCountryNameByCodeParams {
  countryCode: Country | string;
  language?: Language;
}

export const getCountryNameByCode = ({
  countryCode,
  language = defaultLanguageCode,
}: GetCountryNameByCodeParams) => {
  try {
    return new Intl.DisplayNames(language, { type: 'region' }).of(countryCode);
  } catch {
    return countryCode;
  }
};
