import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TypewriterComponent from 'typewriter-effect';
import { Logo } from 'components/Logo';
import { DELAY, DELETE_SPEED, TYPING_PAUSE_MS } from './const';
import styles from './GreetingBlock.module.scss';

export const GreetingBlock: FC = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <Logo className={styles.logo} />
      <div className={styles.textContainer}>
        <h2 className={styles.greetingHeading} data-testid="greeting-heading">
          {t('pageBanner.youArePartOf')}
          {'\u00a0'}
          <TypewriterComponent
            onInit={(typewriter) => {
              typewriter
                .typeString(t('pageBanner.us'))
                .pauseFor(TYPING_PAUSE_MS)
                .deleteAll()
                .typeString(t('pageBanner.vavada'))
                .pauseFor(TYPING_PAUSE_MS)
                .start();
            }}
            options={{
              delay: DELAY,
              deleteSpeed: DELETE_SPEED,
              loop: true,
              wrapperClassName: styles.typingContainer,
              cursorClassName: styles.cursor,
            }}
          />
        </h2>
      </div>
      <div className={styles.imgContainer} data-testid="vavada-img-container" />
    </>
  );
};
