import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { ConfirmModalContext } from './ConfirmModalContext';
import { ConfirmPopupContextValue } from '../types';
import { useModalControl } from '../hooks/useModalControl';

export const ConfirmModalContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { closeModal, isOpen, confirmResolver, showConfirmation } =
    useModalControl();

  const handleConfirm = useCallback(() => {
    confirmResolver?.(true);
    closeModal();
  }, [closeModal, confirmResolver]);

  const handleCancel = useCallback(() => {
    confirmResolver?.(false);
    closeModal();
  }, [closeModal, confirmResolver]);

  const value: ConfirmPopupContextValue = useMemo(
    (): ConfirmPopupContextValue => ({
      isOpen,
      showConfirmation,
      closeModal,
      handleConfirm,
      handleCancel,
    }),
    [closeModal, handleCancel, handleConfirm, isOpen, showConfirmation]
  );

  return (
    <ConfirmModalContext.Provider value={value}>
      {children}
    </ConfirmModalContext.Provider>
  );
};
