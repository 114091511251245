import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonTheme } from 'ui/Button';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import styles from './ChangePassword.module.scss';

export const ChangePassword: FC = () => {
  const { t } = useTranslation('profile');
  const translationUserType = useTranslationUserType();

  return (
    <div>
      <div className={styles.text}>
        {t(
          `${translationUserType}.content.drawer.resetPassword.header.subtitle1`
        )}
      </div>
      <div className={styles.text}>
        {t(
          `${translationUserType}.content.drawer.resetPassword.header.subtitle2`
        )}
      </div>
      <Button theme={ButtonTheme.Grey}>
        {t(
          `${translationUserType}.content.drawer.resetPassword.action.button.secondary`
        )}
      </Button>
    </div>
  );
};
