import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListSize } from 'ui/List';
import { Dropdown, DropdownPosition } from 'ui/Dropdown';
import { Language } from 'types/generated/gql';
import { blurActiveElement } from 'helpers/blurActiveElement';
import { useLanguage } from 'contexts/LanguageContext';
import { DropdownValue } from './types';
import { languageList } from './const';
import {
  getDropdownValueByLanguage,
  getListItemsByLanguageCodes,
} from './helpers';
import styles from './LanguageDropdown.module.scss';

interface LanguageDropdownProps {
  trigger?: ReactNode;
  position?: DropdownPosition;
  onOpenChange?: (isOpen?: boolean) => void;
  onLanguageChange?: (languageCode?: Language) => void;
  isOpenForced?: boolean;
  withStyleMaxContent?: boolean;
}

export const LanguageDropdown: FC<LanguageDropdownProps> = ({
  trigger,
  onOpenChange,
  onLanguageChange,
  position = DropdownPosition.LeftTop,
  isOpenForced,
  withStyleMaxContent,
}) => {
  const { t } = useTranslation('common');
  const { currentLanguage, changeLanguage } = useLanguage();
  const [pickedLanguage, setPickedLanguage] = useState<DropdownValue>(
    getDropdownValueByLanguage(currentLanguage)
  );

  const handleItemClick = (languageCode?: Language) => {
    if (languageCode === pickedLanguage?.value) {
      blurActiveElement();

      return;
    }

    if (languageCode) {
      setPickedLanguage(getDropdownValueByLanguage(languageCode));
      changeLanguage(languageCode);
      blurActiveElement();

      onLanguageChange?.(languageCode);
    }
  };

  return (
    <Dropdown
      onOpenChange={onOpenChange}
      position={position}
      trigger={trigger}
      withStyleMaxContent={withStyleMaxContent}
      isOpenForced={isOpenForced}
      hasGrayOutline
      title={t('input.label.language')}
    >
      <div className={styles.listWrapper}>
        <List
          items={getListItemsByLanguageCodes(handleItemClick, languageList)}
          size={ListSize.Large}
          highlightOnHover
          resetLabelWidth
        />
      </div>
    </Dropdown>
  );
};
