import { Language } from 'types/generated/gql';
import { mapLanguageToPath } from './mapLanguageToPath';

export const checkIsLanguagePath = (
  lang?: string
): lang is Lowercase<Language> =>
  !!lang &&
  Object.values(Language)
    .map(mapLanguageToPath)
    .includes(lang as Lowercase<Language>);
