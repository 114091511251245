import { FC, useCallback } from 'react';
import { useModalContext, closeModal, updateConfirmClose } from 'ui/Modal';
import { UserTypeName } from 'types';
import { useConfirmModalContext } from 'ui/ConfirmModal';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { CreatePartnerMediaForm } from './components/CreatePartnerMediaForm';
import { CreateCompanyMediaForm } from './components/CreateCompanyMediaForm';

export const CreateMediaModal: FC = () => {
  const { dispatch } = useModalContext();
  const { showConfirmation } = useConfirmModalContext();

  const { userTypeName, loading, error } = useGetUserTypeNameQuery();

  const handleDirtyChange = useCallback(
    (dirty: boolean) => {
      dispatch(
        updateConfirmClose({
          confirmClose: dirty ? showConfirmation : null,
        })
      );
    },
    [dispatch, showConfirmation]
  );

  const handleCreated = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  if (!userTypeName || error) {
    return <Error error={error} />;
  }

  const FormComponent =
    userTypeName === UserTypeName.Partner
      ? CreatePartnerMediaForm
      : CreateCompanyMediaForm;

  return (
    <FormComponent
      onDirtyChange={handleDirtyChange}
      onCreated={handleCreated}
    />
  );
};
