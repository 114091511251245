import { FC } from 'react';
import { Country } from 'types';
import { CountryWithFlag } from 'components/CountryWithFlag';
import styles from './CountriesList.module.scss';

interface CountriesListProps {
  countries: Array<Country>;
}

export const CountriesList: FC<CountriesListProps> = ({ countries }) => (
  <div className={styles.container}>
    {countries.map((country: Country) => (
      <CountryWithFlag country={country} key={country} />
    ))}
  </div>
);
