import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetAvailableCurrenciesVariables = SchemaTypes.Exact<{
  [key: string]: never;
}>;

export type GetAvailableCurrencies = {
  __typename: 'Query';
  availableCurrencies: Array<CommonType.Currency>;
};

export const GetAvailableCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableCurrencies' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableCurrencies' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAvailableCurrencies__
 *
 * To run a query within a React component, call `useGetAvailableCurrencies` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableCurrencies` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableCurrencies({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableCurrencies(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAvailableCurrencies,
    GetAvailableCurrenciesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAvailableCurrencies,
    GetAvailableCurrenciesVariables
  >(GetAvailableCurrenciesDocument, options);
}
export function useGetAvailableCurrenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAvailableCurrencies,
    GetAvailableCurrenciesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAvailableCurrencies,
    GetAvailableCurrenciesVariables
  >(GetAvailableCurrenciesDocument, options);
}
export function useGetAvailableCurrenciesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetAvailableCurrencies,
    GetAvailableCurrenciesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetAvailableCurrencies,
    GetAvailableCurrenciesVariables
  >(GetAvailableCurrenciesDocument, options);
}
export type GetAvailableCurrenciesHookResult = ReturnType<
  typeof useGetAvailableCurrencies
>;
export type GetAvailableCurrenciesLazyQueryHookResult = ReturnType<
  typeof useGetAvailableCurrenciesLazyQuery
>;
export type GetAvailableCurrenciesSuspenseQueryHookResult = ReturnType<
  typeof useGetAvailableCurrenciesSuspenseQuery
>;
export type GetAvailableCurrenciesQueryResult = Apollo.QueryResult<
  GetAvailableCurrencies,
  GetAvailableCurrenciesVariables
>;
