import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { UserTypeName } from 'types';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { MayBeNullCell } from 'ui/Table/components/MayBeNullCell';
import { CopyLink } from 'ui/CopyLink';
import { CopyText } from 'ui/CopyText';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { COLUMNS_SIZES } from '../const';
import { PromoCodeMediaItemData } from '../queries/fragments/generated/PromoCodeMediaItemData';
import { mapPromoCodeTypeToText } from '../helpers';

interface UsePromoCodeMediaItemsColumns {
  isCompany: boolean;
}

export const usePromoCodeMediaItemsColumns = ({
  isCompany,
}: UsePromoCodeMediaItemsColumns): Array<ColumnDef<PromoCodeMediaItemData>> => {
  const { t } = useTranslation(['common', 'media']);
  const columnHelper = createColumnHelper<PromoCodeMediaItemData>();
  const translationUserType = useTranslationUserType();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<PromoCodeMediaItemData, any>> = [
    columnHelper.accessor('type', {
      header: t(
        `media:${translationUserType}.content.dataTable.promoCodes.column.typeOfPromo`
      ),
      cell: ({ row }) =>
        mapPromoCodeTypeToText(row.original.type, translationUserType),
      size: COLUMNS_SIZES.typeSize,
      enablePinning: true,
    }),
    ...(isCompany
      ? [
          columnHelper.accessor('owner', {
            header: t(
              'media:company.content.dataTable.promoCodes.column.owner'
            ),
            cell: ({ row }) => {
              if (row.original.owner.__typename === UserTypeName.Company) {
                return (
                  <MultilineCellWithIcon
                    title={row.original.owner.name}
                    subTitle={t('company')}
                  />
                );
              }

              if (row.original.owner.__typename === 'CompanyPartner') {
                return row.original.owner.username;
              }

              return <MayBeNullCell />;
            },
            size: COLUMNS_SIZES.ownerSize,
          }),
        ]
      : []),
    columnHelper.accessor('tariffName', {
      header: t(
        `media:${translationUserType}.content.dataTable.promoCodes.column.tariff`
      ),
      cell: ({ row }) => row.original.tariffName,
      size: COLUMNS_SIZES.tariffSize,
    }),
    columnHelper.accessor('token', {
      header: t(
        `media:${translationUserType}.content.dataTable.promoCodes.column.token`
      ),
      cell: (info) => (
        <CopyText tooltipText={t('linkCopied')} justifyBetween>
          {info.getValue()}
        </CopyText>
      ),
      size: COLUMNS_SIZES.tokenSize,
    }),
    columnHelper.accessor('referralLink', {
      header: t(
        `media:${translationUserType}.content.dataTable.promoCodes.column.link`
      ),
      cell: (info) => (
        <CopyLink
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          to={info.getValue()}
          tooltipText={t('tokenCopied')}
        >
          {info.getValue()}
        </CopyLink>
      ),
      size: COLUMNS_SIZES.tokenSize,
    }),
  ];

  return columns;
};
