import { FC } from 'react';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { VerticalLayout } from 'ui/VerticalLayout';
import { useGetUserLastApplicationQuery } from 'hooks/queryHooks/useGetUserLastApplicationQuery';
import { Footer } from 'components/Footer';
import { LogoutButton } from '../../components/LogoutButton';
import { getComponentByApplicationInfo } from './helpers';

export const Application: FC = () => {
  const { lastApplication, loading, error } = useGetUserLastApplicationQuery();

  if (loading) {
    return <Loader />;
  }

  if (error || !lastApplication) {
    return <Error error={error} />;
  }

  return (
    <VerticalLayout
      topBlock={<LogoutButton />}
      centralBlock={getComponentByApplicationInfo(lastApplication)}
      bottomBlock={<Footer showManagerSupportLink />}
    />
  );
};
