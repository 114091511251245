import { DateFormat, formatDate } from '../../../utils/formatDate';

interface Params {
  startDate: Date | null;
  endDate: Date | null;
}

export const getDateTitle = ({ startDate, endDate }: Params) => {
  const startDateTitle = startDate
    ? formatDate(startDate, DateFormat.DefaultDate)
    : '';

  const endDateTitle = endDate
    ? `${formatDate(endDate, DateFormat.DefaultDate)}`
    : '';

  return `${startDateTitle}${endDateTitle && ' - '}${endDateTitle}`;
};
