import { Dispatch, FC, SetStateAction } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ReactComponent as AltArrowLeft } from 'assets/icons/line/AltArrowLeft.svg';
import { ReactComponent as AltArrowRight } from 'assets/icons/line/AltArrowRight.svg';

import classnames from 'classnames';
import { useHeaderData } from './hooks/useHeaderData';
import styles from './Header.module.scss';
import { DateFormat, formatDate } from '../../../../utils/formatDate';
import { DatePickerStates } from '../../types';
import { Button, ButtonSize, ButtonTheme } from '../../../Button';
import { capitalize } from '../../../../utils/capitalize';

interface HeaderProps {
  setSelectsRange: Dispatch<SetStateAction<boolean>>;
  datePickerStates: DatePickerStates;
  setDatePickerStates: Dispatch<SetStateAction<DatePickerStates>>;
  datePickerCustomHeaderProps: ReactDatePickerCustomHeaderProps;
}

export const Header: FC<HeaderProps> = ({
  setSelectsRange,
  datePickerStates,
  setDatePickerStates,
  datePickerCustomHeaderProps,
}) => {
  const { date } = datePickerCustomHeaderProps;

  const {
    isRenderMonth,
    handlePrevClick,
    handleNextClick,
    isDisableNextButton,
    isDisablePrevButton,
    handleMonthClick,
    handleYearClick,
  } = useHeaderData({
    datePickerStates,
    setDatePickerStates,
    setSelectsRange,
    datePickerCustomHeaderProps,
  });

  if (datePickerStates.showYearPicker) {
    return null;
  }

  return (
    <div className={styles.header}>
      <Button
        data-testid="date-picker-button-prev"
        type="button"
        theme={ButtonTheme.BlueGrey}
        className={styles.button}
        onClick={handlePrevClick}
        disabled={isDisablePrevButton}
        iconConfig={{ iconComponent: AltArrowLeft }}
        size={ButtonSize.Small}
      />

      <div className={styles.dateContainer}>
        {isRenderMonth && (
          <div onClick={handleMonthClick} className={styles.month}>
            {capitalize(formatDate(date, DateFormat.Month))}
          </div>
        )}
        <div onClick={handleYearClick} className={classnames(styles.year)}>
          {formatDate(date, DateFormat.Year)}
        </div>
      </div>

      <Button
        data-testid="date-picker-button-next"
        type="button"
        theme={ButtonTheme.BlueGrey}
        className={styles.button}
        onClick={handleNextClick}
        disabled={isDisableNextButton}
        iconConfig={{ iconComponent: AltArrowRight }}
        size={ButtonSize.Small}
      />
    </div>
  );
};
