import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type RegisterPartnerVariables = SchemaTypes.Exact<{
  input: SchemaTypes.RegisterPartnerInput;
}>;

export type RegisterPartner = {
  __typename: 'Mutation';
  registerPartner: {
    __typename: 'RegisterPartnerPayload';
    partner: { __typename: 'Partner'; id: string };
  };
};

export const RegisterPartnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterPartner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterPartnerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerPartner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegisterPartnerMutationFn = Apollo.MutationFunction<
  RegisterPartner,
  RegisterPartnerVariables
>;

/**
 * __useRegisterPartner__
 *
 * To run a mutation, you first call `useRegisterPartner` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPartner` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPartner, { data, loading, error }] = useRegisterPartner({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPartner(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterPartner,
    RegisterPartnerVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RegisterPartner,
    RegisterPartnerVariables
  >(RegisterPartnerDocument, options);
}
export type RegisterPartnerHookResult = ReturnType<typeof useRegisterPartner>;
export type RegisterPartnerMutationResult =
  Apollo.MutationResult<RegisterPartner>;
export type RegisterPartnerMutationOptions = Apollo.BaseMutationOptions<
  RegisterPartner,
  RegisterPartnerVariables
>;
