import { AuthStatusesBackend } from './api/types';
import type { User } from './types';

export const SECONDS_BEFORE_EXPIRATION = 60;

export enum AuthStatusesLocal {
  Unauthorized = 'unathorized',
}

export const AuthStatuses = {
  ...AuthStatusesLocal,
  ...AuthStatusesBackend,
};
export type AuthStatusesType = (typeof AuthStatuses)[keyof typeof AuthStatuses];

export const defaultUser: User = {
  id: '',
  name: '',
  authStatus: AuthStatuses.Unauthorized,
  privileges: [],
};

// only for development mode and for tests
export const USER_ID_MOCK = 'userId';
