import { useTranslation } from 'react-i18next';
import { FormBlockMarginSize, FormBlockWrapper, Input } from 'ui/formItems';
import { Button, ButtonTheme } from 'ui/Button';
import { FC } from 'react';
import { Form, Formik } from 'formik';
import { FieldName } from './const';
import styles from './PendingGameAccountModal.module.scss';
import { ACCOUNT_NUMBER_MAX_LENGTH } from '../WithdrawRewardModal/const';

interface PendingGameAccountModalProps {
  accountNumber: string;
}

export const PendingGameAccountModal: FC<PendingGameAccountModalProps> = ({
  accountNumber,
}) => {
  const { t } = useTranslation('payments');

  return (
    <>
      <div className={styles.description}>
        <p>
          {t(
            'partnerAndCompany.content.drawer.addVavadaAccount.header.subtitle3'
          )}
        </p>
      </div>
      <Formik
        initialValues={{
          [FieldName.AccountNumber]: accountNumber,
        }}
        onSubmit={() => undefined}
      >
        <Form>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Medium}>
            <Input
              name={FieldName.AccountNumber}
              label={t(
                'partnerAndCompany.content.drawer.addVavadaAccount.input.label.accountNumber'
              )}
              maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
              disabled
            />
          </FormBlockWrapper>
          <Button theme={ButtonTheme.Grey}>
            {t(
              'partnerAndCompany.content.drawer.addVavadaAccount.actions.button.contactUs'
            )}
          </Button>
        </Form>
      </Formik>
    </>
  );
};
