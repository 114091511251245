import { AppRoute } from 'const';
import { FC } from 'react';
import { Navigate } from 'react-router';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';

export const Main: FC = () => {
  const { createLocalizedPath } = useCreateLocalizedPath();

  return <Navigate to={createLocalizedPath(AppRoute.Lang.Statistic.root)} />;
};
