import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { scrollModalToBottom } from 'utils/scrollModalToBottom';
import { useIsStickyElemPinned } from 'hooks/useIsStickyElemPinned';
import styles from './ActionButtons.module.scss';

interface ActionButtonsProps {
  onAddButtonClick: () => void;
  isSubmitButtonDisabled: boolean;
  isAddCardButtonDisabled: boolean;
  isSubmitting?: boolean;
}

export const ActionButtons: FC<ActionButtonsProps> = ({
  onAddButtonClick,
  isSubmitButtonDisabled,
  isAddCardButtonDisabled,
  isSubmitting = false,
}) => {
  const { t } = useTranslation('companyPartners');
  const ref = useRef<HTMLDivElement>(null);
  const isPinned = useIsStickyElemPinned(ref.current);

  const handleAddButtonClick = () => {
    onAddButtonClick();
    scrollModalToBottom();
  };

  return (
    <div
      data-testid="action-buttons-wrapper"
      ref={ref}
      className={classnames(
        styles.buttonsWrapper,
        styles.sticky,
        isPinned && styles.pinned
      )}
    >
      <Button
        data-testid="add-partner-button"
        className={styles.addCardButton}
        onClick={handleAddButtonClick}
        theme={ButtonTheme.BlueLight}
        size={ButtonSize.Large}
        disabled={isAddCardButtonDisabled}
      >
        <span className={styles.addButtonMobileText}>
          {t('content.drawer.addPartner.action.button.drawer.addAnother')}
        </span>
        <span className={styles.addButtonDesktopText}>
          {t(
            'content.drawer.addPartner.action.button.drawer.addAnotherPartner'
          )}
        </span>
      </Button>
      <Tooltip
        tooltipTriggerClassName={styles.tooltip}
        placement="top"
        tooltipTheme={TooltipTheme.Dark}
        tooltipContent={
          isSubmitButtonDisabled
            ? t('content.drawer.addPartner.hint.buttonHint')
            : undefined
        }
        shouldSetWidthFitContent={false}
      >
        <Button
          type="submit"
          theme={ButtonTheme.Blue}
          size={ButtonSize.Large}
          isLoading={isSubmitting}
          disabled={isSubmitButtonDisabled}
          isFullWidth
        >
          {t('content.drawer.addPartner.action.button.card.send')}
        </Button>
      </Tooltip>
    </div>
  );
};
