import { FC, PropsWithChildren } from 'react';
import { useEditingIds } from '../../hooks/useEditingIds';
import { EditingIdsContext } from './EditingIdsContext';

export const EditingIdsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const value = useEditingIds();

  return (
    <EditingIdsContext.Provider value={value}>
      {children}
    </EditingIdsContext.Provider>
  );
};
